import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable } from '@vs/vsf-kit';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

const ManageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const HelpingManageTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.AidManagementController_queryPagedByAidInfoQto_31e6a6?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const rederIndex = (_, __, index) => {
    return (
      <ManageContainer>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </ManageContainer>
    );
  };
  return (
    <Section title="援助管理">
      <VSPromiseTable
        editable={{ editType: 'single', onCanDelete: (record) => false }}
        vsid="17112"
        onFetch={onFetch}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        onUpdate={async (params) => {
          const res =
            await vsf.services?.AidManagementController_updateAid_7ae610?.({
              btoParam: params,
              extApiParam: params?.extApiParam,
            });
          return res?.data;
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          editable={false}
          render={rederIndex}
        />

        <VSTableColumn
          dataIndex={['userName']}
          title="用户姓名"
          valueType="text"
          preview
          formItemProps={{
            rules: [
              {
                message: '用户姓名长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['userPhone']}
          title="用户手机号"
          valueType="text"
          preview
          formItemProps={{
            rules: [
              {
                message: '用户手机号长度不合法',
                type: 'string',
                min: 0,
                max: 11,
              },
            ],
          }}
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['aidRecord']}
          title="事件记录"
          valueType="text"
          ellipsis
          formItemProps={{
            rules: [
              {
                message: '援助时间记录长度不合法',
                type: 'string',
                min: 0,
                max: 250,
              },
            ],
          }}
          fieldProps={{
            placeholder: '请填写',
          }}
        /> */}

        <VSTableColumn
          dataIndex={['aidTime']}
          title="求助时间"
          preview
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['aidStatus']}
          title="状态"
          valueType="select"
          dataSource={[
            { label: '已解决', value: 'SOLVE' },
            { label: '未解决', value: 'NO_SOLVE' },
          ]}
          fieldProps={{
            placeholder: '请选择',
          }}
        />

        <VSTableColumn
          dataIndex={['remark']}
          title="备注"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '备注长度不合法', type: 'string', min: 0, max: 250 },
            ],
          }}
          fieldProps={{
            placeholder: '请填写',
          }}
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(HelpingManageTable);
