import { createServices } from '@vs/vsf-boot';
export default createServices({
  MallCommodityController_queryPagedByCommodityInfoIdxQto_f7f997: {
    method: 'post',
    url: '/api/mall-commodity/query-paged-by-commodity-info-idx-qto',
    parameterFomatter: (data?: {
      qto: CommodityInfoIdxQtoMallCommodityPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CommodityInfoDtoMallCommodityManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  MallCommodityController_createCommodityInfo_150bf5: {
    method: 'post',
    url: '/api/mall-commodity/create-commodity-info',
    parameterFomatter: (data?: {
      btoParam: CreateCommodityInfoBtoMallCommodityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_deleteCommodity_934715: {
    method: 'post',
    url: '/api/mall-commodity/delete-commodity',
    parameterFomatter: (data?: {
      btoParam: DeleteCommodityBtoMallCommodityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_updateCommodityInfo_47f011: {
    method: 'post',
    url: '/api/mall-commodity/update-commodity-info',
    parameterFomatter: (data?: {
      btoParam: UpdateCommodityInfoBtoMallCommodityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_batchDeleteCommodity_37fe36: {
    method: 'post',
    url: '/api/mall-commodity/batch-delete-commodity',
    parameterFomatter: (data?: {
      btoParamList: DeleteCommodityBtoMallCommodityServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  MallCommodityController_batchCreateCommodityInfo_b48631: {
    method: 'post',
    url: '/api/mall-commodity/batch-create-commodity-info',
    parameterFomatter: (data?: {
      btoParamList: CreateCommodityInfoBtoMallCommodityServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
});
