import './index.less';

import { BellOutlined, CaretDownOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import { renderRoutes } from '@vs/vsf-boot';
import { Badge, Dropdown, Image, Layout, message } from '@vs/vsf-kit';
import React, { useEffect, useRef, useState } from 'react';

import defaultPhoto from '@/assets/images/Cockpit/defaultPhoto.png';
import logo from '@/assets/images/logo.png';
import wyzlogo from '@/assets/images/wyzLogo.jpg';
import storage from '@/utils/storage';

import Left from './Left';
const { Sider } = Layout as any;

type containerProps = {
  route: any;
};

const RouteRender = React.memo((props: any) => {
  const { route } = props;
  return renderRoutes(route.routes, route.redirect);
});
RouteRender.displayName = 'RouteRender';
const Container: any = (props: containerProps) => {
  const { route } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [changeSystem, setChangeSystem] = useState(false);
  const [userData, setUserData] =
    useState<UserProfileDtoComVsUserAccountManagerDto>();
  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    timer.current = setInterval(() => {
      const res = vsf.services
        ?.ReserveMessageController_queryPagedByMessageQto_57e38a?.({
          qto: {
            from: 1,
            size: 1000,
          },
          ext: {},
        })
        .then((response) => {
          if (response && response?.code === 200) {
            const DataList = response?.data?.result ?? [];
            vsf.stores.message?.setIsHasReadList(
              DataList?.filter((item) => item?.readStatus !== 'READ'),
            );
          }
        });
    }, 30000);
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    const data = JSON.parse(storage.get('userData') ?? '{}');

    setUserData(data);
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      message.warn('请先登陆');
      setTimeout(() => {
        vsf?.navigateTo('/Login');
      }, 1500);
    }
  }, []);

  return (
    <div className="vsf-layout">
      <div className="vsf-layout-header vsf-text-20">
        <div className="vsf-layout-header-left">
          <div>
            <img
              src={
                storage.get('platform') === 'camp' ||
                storage.get('platform') === 'CAMP_ADMIN' ||
                storage.get('platform') === 'CAMP_STORE'
                  ? logo
                  : wyzlogo
              }
              className="vsf-layout-header-left-icon"
            ></img>
          </div>
          <div className="vsf-layout-header-left-text"></div>
        </div>

        <div className="vsf-layout-header-right">
          {userData?.userType === 'ADMIN' && (
            <>
              <Dropdown
                open={changeSystem}
                menu={{
                  items: [
                    {
                      label: '切换系统',
                      key: 'exit',
                      onClick: () => {
                        try {
                          setChangeSystem(!changeSystem);
                          message.success('切换成功');
                          vsf.stores.system.setPlatform(
                            vsf.stores.system.platform === 'learning'
                              ? 'camp'
                              : 'learning',
                          );
                          storage.set(
                            'platform',
                            storage.get('platform') === 'learning'
                              ? 'camp'
                              : 'learning',
                          );
                          if (storage.get('platform') === 'learning') {
                            vsf?.navigateTo('/BannerManagement');
                          } else {
                            vsf?.navigateTo('/bannerManage');
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      },
                    },
                  ],
                }}
              >
                <div
                  className="vsf-layout-header-right-dropDown"
                  onClick={() => {
                    setChangeSystem(!changeSystem);
                  }}
                >
                  {storage.get('platform') === 'learning'
                    ? '王营庄数字化'
                    : '露营地数字化'}
                  <CaretDownOutlined />
                </div>
              </Dropdown>
            </>
          )}
          {userData?.userType === 'ADMIN' &&
            storage.get('platform') === 'camp' && (
              <>
                <div
                  className="vsf-layout-header-right-message"
                  onClick={() => {
                    vsf?.navigateTo('/messageManage');
                  }}
                >
                  <BellOutlined width={50} height={50} />
                  {vsf.stores?.message?.isHasReadList?.length > 0 ? (
                    <>
                      {' '}
                      <Badge dot style={{ marginTop: '-15px' }}>
                        {' '}
                      </Badge>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}

          <img
            src={userData?.avatar ?? defaultPhoto}
            className="vsf-layout-header-right-avatar"
          />
          <Dropdown
            open={isOpen}
            menu={{
              items: [
                {
                  label: '退出登录',
                  key: 'exit',
                  onClick: () => {
                    try {
                      setIsOpen(!isOpen);
                      message.success('退出成功，请重新登录');
                      localStorage.clear();
                      storage.remove('userData');
                      storage.remove('platform');
                      vsf?.navigateTo('/Login');
                    } catch (error) {
                      console.error(error);
                    }
                  },
                },
              ],
            }}
          >
            <div
              className="vsf-layout-header-right-dropDown"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {userData?.nickname}
              <CaretDownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="vsf-layout-content">
        <Sider
          className="vsf-layout-sider"
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Left />
        </Sider>
        <div className="vsf-layout-main">
          <RouteRender route={route} />
        </div>
      </div>
    </div>
  );
};

export default definePage(Container);
