import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  Button,
  message,
  Image,
  Modal,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import ShopListForm from '@pages/ShopListForm';
import styled from 'styled-components';

const ManageContainer = styled.div`
  overflow: hidden;
  width: 70px;
  height: 70px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const ShopListTable = (props) => {
  const [refresh, setRefresh] = useState();
  const [rows, setRows] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [richText, SetRichText] = useState(String);

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.MallCommodityController_queryPagedByCommodityInfoIdxQto_f7f997?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    setRows([]);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderManageImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.commodityImg ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '5px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAllDelete = async () => {
    try {
      if (rows.length <= 0) {
        return message.error('请先选择要删除的商品');
      }
      Modal.confirm({
        content: '商品删除后不可恢复，是否确认删除？',
        icon: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          vsf.services
            .MallCommodityController_batchDeleteCommodity_37fe36({
              btoParamList: rows?.map((item) => ({
                id: item.id,
                commodityType: item?.commodityType,
              })),
            })
            .then((res) => {
              if (res && res.code === 200) {
                message.success('删除成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 500);
              }
            });
        },
      });
    } catch (error) {}
  };

  const handleImport = async () => {
    try {
      // const res =
      //   await vsf.services?.MallCommodityController_batchCreateCommodityInfo_b48631?.(
      //     {
      //       btoParam: selectedRows.current?.map((item) => ({
      //         id: item.id,
      //         commodityType: item?.commodityType,
      //       })),
      //     },
      //   );
      // if (res && res.code === 200) {
      //   message.success('操作成功');
      // }
    } catch (error) {}
  };

  const renderCommodityIntroductionImg = (_, record) => {
    try {
      return (
        <Container>
          <div
            className="watchBtn"
            onClick={() => {
              vsf.openModal('preview');
              SetRichText(record?.commodityIntroduction);
            }}
          >
            预览内容
          </div>
        </Container>
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Section title="商品管理">
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '商品删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
        }}
        vsid="83203"
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
          getCheckboxProps: (row) => ({
            disabled: row?.readStatus === 'READ',
          }),
        }}
        extraParams={{ refresh }}
        onFetch={onFetch}
        onRemove={async (params) => {
          const res =
            await vsf.services?.MallCommodityController_deleteCommodity_934715?.(
              {
                btoParam: params,
                extApiParam: params?.extApiParam,
              },
            );
          if (res && res?.code === 200) {
            message.success('删除成功');
          }
          return res?.code === 200;
        }}
        addLinkPage={{
          linkPage: () => <ShopListForm />,
          modalProps: { width: 650, title: '新增商品' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.ShopListForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.MallCommodityController_createCommodityInfo_150bf5?.(
                  {
                    btoParam: fromParam,
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('添加成功');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <ShopListForm />,
          modalProps: { width: 650, title: '编辑商品' },
          onLinkPageValidate: async (v, vv, params) => {
            try {
              const fromParam =
                await vsf?.refs?.ShopListForm?.validateFieldsReturnFormatValue();
              const values = {
                ...fromParam,
                id: params?.id,
              };
              const res =
                await vsf.services?.MallCommodityController_updateCommodityInfo_47f011?.(
                  {
                    btoParam: {
                      ...fromParam,
                      id: params?.id,
                    },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('编辑成功');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['commodityNum']}
          title="商品编号"
          valueType="text"
          search
          order={-58}
          columnKey={'commodityNumLike'}
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commodityName']}
          title="商品名称"
          valueType="text"
          search
          order={-59}
          columnKey={'commodityNameLike'}
          formItemProps={{
            rules: [
              {
                message: '商品名称长度不合法',
                type: 'string',
                min: 0,
                max: 50,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commodityType']}
          title="商品类型"
          valueType="select"
          dataSource={[
            { label: '露营装备', value: 'OUTDOOR' },
            { label: '餐饮食材', value: 'RESTAURANT' },
            { label: '名优特产', value: 'SPECIALITY' },
            { label: '联农优品', value: 'FARM' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commodityKind']}
          title="商品种类"
          valueType="select"
          dataSource={[
            { label: '租赁', value: 'LEASE' },
            { label: '购买', value: 'PURCHASE' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['salesPrice']}
          title="价格"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commodityImg']}
          title="商品封面"
          valueType="text"
          render={renderManageImg}
        />

        <VSTableColumn
          dataIndex={['commodityModel']}
          title="商品型号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '商品型号长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commoditySpecifications']}
          title="商品规格"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '商品规格长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commodityIntroduction']}
          title="商品介绍"
          valueType="text"
          render={renderCommodityIntroductionImg}
        />

        <VSTableColumn
          dataIndex={['notice']}
          title="购买须知"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '注意事项长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['commodityStock']}
          title="库存量"
          valueType="digit"
          formItemProps={{
            rules: [
              { message: '库存的值不合法', type: 'number', min: 0, max: null },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['publishStatus']}
          title="商品状态"
          valueType="select"
          search
          order={-61}
          columnKey={'publishStatusIs'}
          dataSource={[
            { label: '上架', value: 'PUBLISH_ON' },
            { label: '下架', value: 'PUBLISH_DOWN' },
            { label: '已售罄', value: 'SELL_OUT' },
          ]}
          fieldProps={{}}
        />

        <VSTableToolbar title="">
          {/* <Button
            children="导入"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            onClick={() => {
              handleImport();
            }}
          /> */}
          <Button
            children="批量删除"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            disabled={rows?.length <= 0}
            onClick={() => {
              handleAllDelete();
            }}
          />
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>

      <Modal id="preview" width={600} okText="" cancelText="" footer={null}>
        {richText && (
          <>
            <div className="modalWatch_container__text">
              <div
                dangerouslySetInnerHTML={{ __html: richText }}
                className="bannerText"
              ></div>
            </div>
          </>
        )}
      </Modal>
    </Section>
  );
};
export default definePage(ShopListTable);
