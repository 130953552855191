import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveDataManagementController_createLearningInformation_3c3d69: {
    method: 'post',
    url: '/api/reserve-data-management/create-learning-information',
    parameterFomatter: (data?: {
      btoParam: CreateLearningInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_getAllByLearningInformationQto_fe4ccf: {
    method: 'post',
    url: '/api/reserve-data-management/get-all-by-learning-information-qto',
    parameterFomatter: (data?: {
      qto: LearningInformationQtoReserveDataManagementPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningInformationVoReserveDataManagementEntranceWebVo[],
    ) => data,
  },
  ReserveDataManagementController_updateLearningInformation_7c5300: {
    method: 'post',
    url: '/api/reserve-data-management/update-learning-information',
    parameterFomatter: (data?: {
      btoParam: UpdateLearningInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
});
