import vsf from '@vs/vsf-boot';

let ossPolicy = null;
let ossPolicyObs = null;

const getOssPolicy = async () => {
  if (ossPolicy) return ossPolicy;
  const { data } =
    await vsf.services.OssPolicyController_getOssPolicyForObs_740042();
  ossPolicy = data;
  return data;
};

export const getOssPolicyForObs = async () => {
  if (ossPolicyObs) return ossPolicyObs;
  const { data } =
    await vsf.services.OssPolicyController_getOssPolicyForObs_740042();
  ossPolicyObs = data;
  return data;
};

export default getOssPolicy;
