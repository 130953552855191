import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
  Button,
  Modal,
  Image,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import MerchantManageForm from '@/pages/MerchantManageForm';
import styled from 'styled-components';
import { QRCodeCanvas } from 'qrcode.react';

const ManageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const MerchantManageList = (props) => {
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };

  const handleDelete = async () => {
    try {
      if (rows?.length <= 0) {
        return message.error('请先选择要删除的商家');
      }
      Modal.confirm({
        content: '商家删除后不可恢复，是否确认删除？',
        icon: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          vsf.services
            .OrderMerchantController_deleteBatchMerchant_709dfc({
              btoParam: rows?.map((item) => ({
                id: item?.id,
              })),
            })
            .then((res) => {
              if (res && res.code === 200) {
                message.success('删除成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 500);
              }
            });
        },
      });
    } catch (error) {}
  };
  const rederIndex = (_, __, index) => {
    return (
      <ManageContainer>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </ManageContainer>
    );
  };

  const rederQrCode = (_, params, index) => {
    const codeUrl = `https://luyingdi.lsculture.cn?id=${params.id}`;
    return (
      <ManageContainer>
        <QRCodeCanvas
          style={{
            width: 70,
            height: 70,
          }}
          id={`qrcode${index + 1}`}
          value={encodeURI(codeUrl)}
        />

        <div
          className="downLoadCode"
          onClick={() => {
            const canvas = document.getElementById(`qrcode${index + 1}`);
            if (canvas) {
              const url = canvas.toDataURL();
              const a = document.createElement('a');
              a.download = '收款码.png';
              a.href = url;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }}
        >
          下载
        </div>
      </ManageContainer>
    );
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.OrderMerchantController_getPagedByMerchantQto_a88b3f?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
        },
      );
    setRows([]);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <Section title="商家管理">
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '商家删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
        }}
        vsid="77857"
        extraParams={{ refresh }}
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
        }}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        onFetch={onFetch}
        addLinkPage={{
          linkPage: () => <MerchantManageForm />,
          modalProps: { width: 500, title: '新增商家' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.MerchantManageForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.OrderMerchantController_createMerchant_3413e0?.(
                  {
                    btoParam: {
                      ...fromParam,
                      campPlanInfoBtoList: fromParam?.campPlan?.map((item) => ({
                        planId: item.id,
                      })),
                      partitionId: fromParam?.partitionName?.id,
                    },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('添加成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <MerchantManageForm />,
          modalProps: { width: 500, title: '编辑商家' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.MerchantManageForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.OrderMerchantController_updateMerchant_186dfc?.(
                  {
                    btoParam: { ...(fromParam ?? {}) },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('编辑成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        onRemove={async (params) => {
          const res =
            await vsf.services?.OrderMerchantController_deleteMerchant_a447e3?.(
              {
                btoParam: params,
                extApiParam: params?.extApiParam,
              },
            );
          if (res && res?.code === 200) {
            message.success('删除成功');
          }
          return res?.code === 200;
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />
        <VSTableColumn
          dataIndex={['merchantName']}
          title="商家名称"
          valueType="text"
          search
          order={-1}
          columnKey={'merchantNameLike'}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['linkman']}
          title="联系人"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['phone']}
          title="手机号"
          valueType="text"
          search
          order={-2}
          columnKey={'phoneIs'}
          fieldProps={{}}
        />

        <VSTableColumn
          title="收款码"
          // valueType="text"
          fieldProps={{}}
          render={rederQrCode}
        />

        <VSTableColumn
          dataIndex={['status']}
          title="状态"
          valueType="select"
          search
          order={-3}
          columnKey={'statusIs'}
          dataSource={[
            { label: '在用', value: 'ENABLE' },
            { label: '停用', value: 'DISABLE' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableToolbar title="">
          <Button
            children="批量删除"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            disabled={rows?.length <= 0}
            onClick={() => {
              handleDelete();
            }}
          />
          <VSTableAddition children="新增" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(MerchantManageList);
