import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveResearchLearningController_createResearchLearning_b668e9: {
    method: 'post',
    url: '/api/reserve-research-learning/create-research-learning',
    parameterFomatter: (data?: {
      btoParam: CreateResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_deleteResearchLearning_c2db63: {
    method: 'post',
    url: '/api/reserve-research-learning/delete-research-learning',
    parameterFomatter: (data?: {
      btoParam: DeleteResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_updateResearchLearning_ade80c: {
    method: 'post',
    url: '/api/reserve-research-learning/update-research-learning',
    parameterFomatter: (data?: {
      btoParam: UpdateResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_queryPagedByQueryResearchLearningByManageQto_2f861a:
    {
      method: 'post',
      url: '/api/reserve-research-learning/query-paged-by-query-research-learning-by-manage-qto',
      parameterFomatter: (data?: {
        qto: QueryResearchLearningByManageQtoReserveResearchLearningPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ReserveResearchLearningController_batchDelete_5fb534: {
    method: 'post',
    url: '/api/reserve-research-learning/batch-delete',
    parameterFomatter: (data?: {
      btos: DeleteResearchLearningBtoReserveResearchLearningServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo[],
    ) => data,
  },
  ReserveTicketsController_createTicketPriceList_d4e5b4: {
    method: 'post',
    url: '/api/reserve-tickets/create-reserve-price-list',
    parameterFomatter: (data?: {
      btoParamList: CreateDateTicketsBtoReserveTicketsServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  ReserveTicketsController_updateTicketPriceList_07231b: {
    method: 'post',
    url: '/api/reserve-tickets/update-price-list',
    parameterFomatter: (data?: {
      priceBtoParam: UpdateDateTicketsBtoReserveTicketsServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  OrderResearchLearningController_auditRefundOrder_9168c8: {
    method: 'post',
    url: '/api/order-research-learning/audit-refund-order',
    parameterFomatter: (data?: {
      auditRefundVo: AuditRefundVoOrderResearchLearningEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
