import '@vs/vsf-kit/dist/umd/index.css';
import './style/global.less';
import './style/custom_antd.less';
import './style/reset.less';
import './style/vars.less';
import './api';

import { runApp } from '@vs/vsf-boot';
import { vsfKitGlobal } from '@vs/vsf-kit';

import routes from '@/router';
import { loadEnums } from '@/stores/dbenums';
import * as stores from '@/stores/index';

import services from './services';

import('./components').then(() => {
  loadEnums();
  runApp(
    {
      stores,
      services,
      router: {
        config: routes,
      },
    },
    vsfKitGlobal,
  );
});
