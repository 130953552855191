import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveCampInfoController_createCamp_9d1756: {
    method: 'post',
    url: '/api/reserve-camp-info/create-camp',
    parameterFomatter: (data?: {
      btoParam: CreateCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampInfoVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampInfoController_updateCamp_ce8a0d: {
    method: 'post',
    url: '/api/reserve-camp-info/update-camp',
    parameterFomatter: (data?: {
      btoParam: UpdateCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampInfoVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  OssPolicyController_getOssPolicy_7c7b3a: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
});
