import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  message,
  DateRangePicker,
  Modal,
  VSTableAction,
} from '@vs/vsf-kit';
import React, { useState, useCallback, useEffect } from 'react';
import { ReserveStatusEnum } from '@/config';
import dayjs from 'dayjs';
import { set } from 'lodash';

const AppointmentManageTable = (props) => {
  const [refresh, setRefresh] = useState();
  const [item, setItem] = useState();
  const [params, setParams] = useState();
  const [statisticsData, setStatisticsData] = useState();

  const onFetch = useCallback(async (params) => {
    setParams(params);
    const res =
      await vsf.services?.UserReserveController_queryPagedByUserReserveInfoQto_5c05d1?.(
        {
          ext: {
            startTime: params?.search?.reserveTimeRangeIn?.begin ?? '',
            endTime: params?.search?.reserveTimeRangeIn?.end ?? '',
          },
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
        },
      );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const queryReserveOrder = async () => {
    const res =
      await vsf.services.UserReserveController_queryReserveOrder_7c6c95({
        qto: {
          ...params.search,
        },
        ext: {
          startTime: params?.search?.reserveTimeRangeIn?.begin ?? '',
          endTime: params?.search?.reserveTimeRangeIn?.end ?? '',
        },
      });
    if (res.data && res.code === 200) {
      setStatisticsData(res?.data);
    }
  };

  useEffect(() => {
    if (params) {
      queryReserveOrder();
    }
  }, [params]);

  const handleSubmit = async () => {
    try {
      const values =
        (await vsf.refs.remarkForm?.validateFieldsReturnFormatValue()) ?? {};

      const res =
        await vsf.services.UserReserveController_updateReserveInfo_cb95f8({
          btoParam: {
            ...item,
            ...values,
            reserveStatus: ReserveStatusEnum?.CANCELED,
          },
        });
      if (res.data && res.code === 200) {
        message.success('取消成功');
        setTimeout(() => {
          setRefresh(!refresh);
        }, 1000);
        return res?.data;
      }

      return false;
    } catch (e) {
      console.log(e);
    }
  };

  const renderTime = (v, params, index) => {
    const timeList = params?.reserveTime.split(',');
    return (
      <div>
        <div>{dayjs(timeList[0]).format('YYYY-MM-DD') ?? ''}</div>
        {timeList.length > 1 && (
          <>
            <div>
              {dayjs(timeList[timeList.length - 1]).format('YYYY-MM-DD') ?? ''}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="statisticsContainer">
      <Section title="预约管理">
        <div className="statistics">
          <div className="statisticsText">
            <div className="statisticsIcon"></div>
            统计
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            预约总数： {statisticsData?.orderCount ?? '-'}
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            交易总金额： {statisticsData?.totalTranAmount ?? '-'}
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            成交总金额： {statisticsData?.completeAmount ?? '-'}
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            退款总金额： {statisticsData?.refundAmount ?? '-'}
          </div>
        </div>
        <VSPromiseTable
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          extraParams={{ refresh }}
          vsid="65188"
          onFetch={onFetch}
          onUpdate={async (params) => {
            const res =
              await vsf.services?.UserReserveController_updateReserveInfo_cb95f8?.(
                {
                  btoParam: params,
                  extApiParam: params?.extApiParam,
                },
              );
            return res?.data;
          }}
          onRemove={async (params) => {
            vsf.openModal('remark');
            setItem(params);
          }}
        >
          <VSTableColumn
            dataIndex={['reserveOrderNo']}
            title="订单编号"
            valueType="text"
            search
            order={-68}
            columnKey={'reserveOrderNoIs'}
            editable={false}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请填写',
                },
              ],
            }}
            fieldProps={{
              placeholder: '请填写',
            }}
          />

          <VSTableColumn
            dataIndex={['reserveName']}
            title="预约姓名"
            valueType="text"
            fieldProps={{
              placeholder: '请填写',
            }}
          />

          <VSTableColumn
            dataIndex={['reservePhone']}
            title="预约手机号"
            valueType="text"
            search
            order={-69}
            columnKey={'reservePhoneIs'}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请填写',
                  type: 'string',
                  min: 0,
                  max: 11,
                },
              ],
            }}
            fieldProps={{
              placeholder: '请填写',
            }}
          />

          <VSTableColumn
            dataIndex={['reserveCampName']}
            title="预约营位"
            valueType="text"
            search
            order={-79}
            columnKey={'reserveCampNameLike'}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请填写',
                },
              ],
            }}
            fieldProps={{
              placeholder: '请填写',
            }}
          />

          <VSTableColumn
            dataIndex={['orderAmount']}
            title="订单金额"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['reserveStatus']}
            title="预约状态"
            valueType="select"
            search
            order={-70}
            columnKey={'reserveStatusIs'}
            dataSource={[
              { label: '已预约', value: 'BOOKED' },
              { label: '已完成', value: 'SUCCESS' },
              { label: '已取消', value: 'CANCELED' },
            ]}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请选择',
                  type: 'string',
                },
              ],
            }}
            fieldProps={{
              placeholder: '请选择',
            }}
          />

          <VSTableColumn
            dataIndex={['reserveTime']}
            title="预约时间"
            valueType="text"
            width={200}
            hideInForm
            render={renderTime}
          />

          <VSTableColumn
            dataIndex={['reserveTime']}
            title="预约时间"
            search
            hideInTable
            order={-75}
            columnKey={'reserveTimeRangeIn'}
            valueType="dateRange"
            transform={(values) => {
              if (!Array.isArray(values)) return values;
              return ['reserveTimeRangeIn'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              );
            }}
            fieldProps={{
              format: 'YYYY-MM-DD',
              valueType: 'string',
            }}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请选择',
                  type: 'string',
                },
              ],
            }}
          />

          {/* <VSTableColumn
          dataIndex={['isOvernight']}
          title="预约时段"
          valueType="select"
          dataSource={[
            { label: '过夜', value: 'OVERNIGHT' },
            { label: '不过夜', value: 'NO_OVERNIGHT' },
          ]}
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择',
                type: 'string',
              },
            ],
          }}
          fieldProps={{
            placeholder: '请选择',
          }}
        /> */}

          <VSTableColumn
            dataIndex={['verificationTime']}
            title="核销时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            preview
          />

          <VSTableColumn
            dataIndex={['buyerMessage']}
            title="买家留言"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请填写',
                },
              ],
            }}
            fieldProps={{
              placeholder: '请填写',
            }}
          />
          <VSTableColumn dataIndex={['remark']} title="备注" valueType="text" />

          <VSTableColumn
            width={230}
            dataIndex={['action']}
            title="操作"
            valueType="option"
          >
            <VSTableAction
              show={(data) => data.reserveStatus === 'BOOKED'}
              onClick={(v, vv, params) => {
                Modal.confirm({
                  content: '是否确认核销？',
                  icon: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    vsf.services
                      .OrderInfoController_updateCampOrderInfo_017e9c({
                        orderNo: vv?.reserveOrderNo,
                      })
                      .then((res) => {
                        if (res?.code === 200) {
                          message.success(`核销成功`);
                          setTimeout(() => {
                            setRefresh(!refresh);
                          }, 500);
                        }
                      });
                  },
                });
              }}
            >
              核销
            </VSTableAction>

            <VSTableAction
              show={(data) => data.reserveStatus === ReserveStatusEnum?.BOOKED}
              onClick={(v, vv, params) => {
                Modal.confirm({
                  content: '取消预约后不可恢复，是否确认取消预约?',
                  icon: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    vsf.openModal('remark');
                    setItem(vv);
                  },
                });
              }}
            >
              取消预约
            </VSTableAction>
          </VSTableColumn>
        </VSPromiseTable>
        <Modal
          title={'填写备注'}
          id="remark"
          width={500}
          onOk={handleSubmit}
          okText="确定"
          cancelText="取消"
        >
          <VSControlledForm vsid="94767" id="remarkForm" value={props.value}>
            <VSFormLayout key="0" columnCount={1} labelWidth={80}>
              <VSFormItem
                name={['remark']}
                label="备注"
                valueType="textarea"
                fieldProps={{
                  autoSize: {
                    minRows: 3,
                    maxRows: 4,
                  },
                }}
                rules={[
                  {
                    required: true,
                    message: '请输入备注',
                  },
                ]}
              />
            </VSFormLayout>
          </VSControlledForm>
        </Modal>
      </Section>
    </div>
  );
};
export default definePage(AppointmentManageTable);
