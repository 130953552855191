import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderMerchantController_createMerchant_3413e0: {
    method: 'post',
    url: '/api/order-merchant/create-merchant',
    parameterFomatter: (data?: {
      btoParam: CreateMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_updateMerchant_186dfc: {
    method: 'post',
    url: '/api/order-merchant/update-merchant',
    parameterFomatter: (data?: {
      btoParam: UpdateMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
});
