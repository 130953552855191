import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveCampPlanController_deleteCampPlan_a9697c: {
    method: 'post',
    url: '/api/reserve-camp-info/delete-camp-plan',
    parameterFomatter: (data?: {
      btoParam: DeleteCampPlanBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampPlanVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampPlanController_queryPagedByCampPlanQto_9f3c6a: {
    method: 'post',
    url: '/api/reserve-camp-info/query-paged-by-camp-plan-qto',
    parameterFomatter: (data?: {
      qto: CampPlanQtoReserveCampInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CampPlanVoReserveCampInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveCampPlanController_createCampPlan_1cf65e: {
    method: 'post',
    url: '/api/reserve-camp-info/create-camp-plan',
    parameterFomatter: (data?: {
      btoParam: CreateCampPlanBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampPlanVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampPlanController_updateCampPlan_d8cb5e: {
    method: 'post',
    url: '/api/reserve-camp-info/update-camp-plan',
    parameterFomatter: (data?: {
      btoParam: UpdateCampPlanBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampPlanVoReserveCampInfoEntranceWebVo) =>
      data,
  },
});
