import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useRef, useState, useEffect } from 'react';
import getOssPolicy, { getOssPolicyForObs } from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const BannerManageForm = (props) => {
  const [obsParams, setObsParams] = useState({});

  const init = async () => {
    const data = await getOssPolicyForObs();
    setObsParams(data);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Section>
      <VSControlledForm
        id="BannerManageForm"
        vsid="32320"
        layout="inline"
        style={{ marginTop: 20 }}
        value={props.value}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          valueType="text"
          style={{ display: 'none' }}
        />
        <VSFormLayout key="0" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['title']}
            label="标题"
            valueType="text"
            required
            rules={[{ required: true, message: '请输入标题（20字以内）' }]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['carouselImg']}
            label="图片"
            required
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传图片' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  carouselImg: v,
                };
              }
              return {
                carouselImg: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicyForObs,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize">建议尺寸：800 * 300</div>
          {/*
          <VSFormItem
            name={['text']}
            label="文本"
            required
            valueType="textarea"
            rules={[{ required: true, message: '请输入文本（250字以内）' }]}
            fieldProps={{
              maxLength: 20,
              autoSize: {
                minRows: 3,
                maxRows: 4,
              },
            }}
          /> */}

          {/* <VSFormItem
            name={['link']}
            label="跳至页面"
            required
            valueType="text"
            rules={[{ required: true, message: '请输入跳至页面' }]}
            fieldProps={{}}
          /> */}

          <VSFormItem
            name="richText"
            label="内容"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写内容' }]}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(BannerManageForm);
