import { defineStore } from '@vs/vsf-boot';

const system = defineStore({
  platform: '',

  setPlatform: (platform) => {
    system.platform = platform;
  },
});

export default system;
