import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveTicketsController_createTickets_14e4be: {
    method: 'post',
    url: '/api/reserve-tickets/create-tickets',
    parameterFomatter: (data?: {
      btoParam: CreateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_updateTickets_923e34: {
    method: 'post',
    url: '/api/reserve-tickets/update-tickets',
    parameterFomatter: (data?: {
      btoParam: UpdateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
});
