import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReservePartitionController_queryPagedByPartitionInfoQto_4bf9a9: {
    method: 'post',
    url: '/api/reserve-camp-info/query-paged-by-partition-info-qto',
    parameterFomatter: (data?: {
      qto: PartitionInfoQtoReserveCampInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PartitionInfoVoReserveCampInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReservePartitionController_createPartition_c4b511: {
    method: 'post',
    url: '/api/reserve-camp-info/create-partition',
    parameterFomatter: (data?: {
      btoParam: CreatePartitionBtoReserveCampInfoServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PartitionInfoVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  ReservePartitionController_updatePartition_eb22ef: {
    method: 'post',
    url: '/api/reserve-camp-info/update-partition',
    parameterFomatter: (data?: {
      btoParam: UpdatePartitionBtoReserveCampInfoServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PartitionInfoVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  ReservePartitionController_deletePartition_c13a6f: {
    method: 'post',
    url: '/api/reserve-camp-info/delete-partition',
    parameterFomatter: (data?: {
      btoParam: DeletePartitionBtoReserveCampInfoServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PartitionInfoVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  ReservePartitionController_getAllByPubPartitionQto_e25a8c: {
    method: 'post',
    url: '/api/reserve-camp-info/get-all-by-pub-partition-qto',
    parameterFomatter: (data?: {
      qto: PubPartitionQtoReserveCampInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PartitionInfoVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
});
