import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Breadcrumb,
  message,
  VSTableAddition,
  VSTableToolbar,
} from '@vs/vsf-kit';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import LearningCockpitManagerFrom from '@pages/LearningCockpitManagerFrom';
import LearningActivityManagerFrom from '@pages/LearningActivityManagerFrom';
import styled from 'styled-components';
const DiscountContainer = styled.div``;
const CampCockpitManagerList = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [ticketsData, setTicketsData] = useState();

  const monthData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
        {
          qto: {
            modelIs: 'MONTH_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const campRealData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
        {
          qto: {
            modelIs: 'VENUE',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const userData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
        {
          qto: {
            modelIs: 'USER_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const orderData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
        {
          qto: {
            modelIs: 'ORDER_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const cammpInfoData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
        {
          qto: {
            modelIs: 'CURRENT_YEAR',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const ticketsInfoData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
        {
          qto: {
            modelIs: 'TICKETS',
          },
        },
      );
    setTicketsData(res?.data ?? []);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const learningData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByLearningInformationQto_fe4ccf?.(
        {
          qto: {},
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderDiscount = (_, record) => {
    try {
      return (
        <DiscountContainer>
          <div>{record?.value}%</div>
        </DiscountContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>驾驶舱管理</Breadcrumb.Item>
          <Breadcrumb.Item>驾驶舱数据管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section>
        <div className="tableTile">月度信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          extraParams={{ refresh }}
          pagination={false}
          onFetch={monthData}
          updateLinkPage={{
            linkPage: () => <LearningCockpitManagerFrom />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningCockpitManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateWyzInformation_a5e4cd?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'MONTH_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['value']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">场馆实时数据</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={campRealData}
          updateLinkPage={{
            linkPage: () => <LearningCockpitManagerFrom isReadOnly={true} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningCockpitManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateWyzInformation_a5e4cd?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'CAMP_REAL_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['value']}
            title="数值"
            render={renderDiscount}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">用户信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={userData}
          updateLinkPage={{
            linkPage: () => <LearningCockpitManagerFrom />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningCockpitManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateWyzInformation_a5e4cd?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'USER_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['value']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">订单信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={orderData}
          updateLinkPage={{
            linkPage: () => <LearningCockpitManagerFrom />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningCockpitManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateWyzInformation_a5e4cd?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'ORDER_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['value']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">本年订单信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={cammpInfoData}
          updateLinkPage={{
            linkPage: () => <LearningCockpitManagerFrom isRequired={true} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningCockpitManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateWyzInformation_a5e4cd?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'CURRENT_YEAR',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['value']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">门票信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={ticketsInfoData}
          updateLinkPage={{
            linkPage: () => <LearningCockpitManagerFrom />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningCockpitManagerFrom?.validateFieldsReturnFormatValue();

                const data = ticketsData
                  .filter((obj) => obj.id !== params?.id)
                  .reduce((sum, obj) => sum + Number(obj.value), 0);
                if (data + Number(fromParam?.value) > 100) {
                  message.warning('请填写所有门票百分比相加正确的数据');
                } else {
                  const res =
                    await vsf?.services?.ReserveDataManagementController_updateWyzInformation_a5e4cd?.(
                      {
                        btoParam: {
                          ...fromParam,
                          id: params?.id,
                          model: 'TICKETS',
                        },
                      },
                    );
                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      ticketsInfoData();
                      setRefresh(!refresh);
                    }, 1000);
                  }
                  return res?.data;
                }
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['value']}
            title="数值"
            render={renderDiscount}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">研学活动信息</div>

        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          pagination={false}
          onFetch={learningData}
          id="learningActivityCockpitManagerFrom"
          addLinkPage={{
            linkPage: () => <LearningActivityManagerFrom />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningActivityManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_createLearningInformation_3c3d69?.(
                    {
                      btoParam: fromParam,
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('新增成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '新增研学活动',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
          updateLinkPage={{
            linkPage: () => <LearningActivityManagerFrom />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.learningActivityManagerFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateLearningInformation_7c5300?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['activityTime']}
            title="活动开始时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />

          <VSTableColumn
            dataIndex={['activityEndTime']}
            title="活动结束时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />

          <VSTableColumn
            dataIndex={['applyNum']}
            title="报名人数"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableToolbar>
            <VSTableAddition children="新增" />
          </VSTableToolbar>
        </VSPromiseTable>
      </Section>
    </div>
  );
};
export default definePage(CampCockpitManagerList);
