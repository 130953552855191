import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontActivityController_createActivity_dcbef3: {
    method: 'post',
    url: '/api/front-activity/create-activity',
    parameterFomatter: (data?: {
      btoParam: CreateActivityBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoVoFrontActivityEntranceWebVo,
    ) => data,
  },
  FrontActivityController_updateActivity_1e37ce: {
    method: 'post',
    url: '/api/front-activity/update-activity',
    parameterFomatter: (data?: {
      btoParam: UpdateActivityBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoVoFrontActivityEntranceWebVo,
    ) => data,
  },
  FrontActivityController_deleteActivity_3ae619: {
    method: 'post',
    url: '/api/front-activity/delete-activity',
    parameterFomatter: (data?: {
      btoParam: DeleteActivityBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FrontActivityController_queryPagedByActivityPage_1a5f37: {
    method: 'post',
    url: '/api/front-activity/query-paged-by-activity-page',
    parameterFomatter: (data?: {
      qto: ActivityPageQtoFrontActivityPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoVoFrontActivityEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FrontPageController_deleteFront_6e766e: {
    method: 'post',
    url: '/api/front-page/delete-front',
    parameterFomatter: (data?: {
      btoParam: DeleteFrontBtoFrontPageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
