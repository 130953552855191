import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  VSTableAction,
  VSPromiseTable,
  message,
  Image,
  Button,
  Modal,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import ResearchLearnForm from '@/pages/researchLearnForm';
import styled from 'styled-components';
import BraftEditor from 'braft-editor';
import storage from '@/utils/storage';
const ManageContainer = styled.div`
  overflow: hidden;
  width: 70px;
  height: 70px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const ResearchLearningList = (props) => {
  const data = JSON.parse(storage.get('userData') ?? '{}');
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState();
  const [id, setId] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [richText, SetRichText] = useState(String);

  const [isShowPub, setIsShowPub] = useState(
    data?.userType === 'ADMIN' || data?.userType === 'LEARNING_ADMIN',
  );

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };

  const handleDelete = async () => {
    try {
      if (rows?.length <= 0) {
        return message.error('请先选择要删除的研学活动');
      }
      Modal.confirm({
        content: '研学活动删除后不可恢复，是否确认删除？',
        icon: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          vsf.services
            .ReserveResearchLearningController_batchDelete_5fb534({
              btos: rows?.map((item) => ({
                id: item?.id,
              })),
            })
            .then((res) => {
              if (res && res.code === 200) {
                message.success('删除成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 500);
              }
            });
        },
      });
    } catch (error) {}
  };

  const rederIndex = (_, __, index) => {
    return (
      <Container>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </Container>
    );
  };

  const renderCoverImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.coverImg ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '5px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderActivityDesc = (_, record) => {
    try {
      return (
        <Container>
          <div
            className="watchBtn"
            onClick={() => {
              vsf.openModal('preview');
              SetRichText(record?.activityDesc);
            }}
          >
            预览详情
          </div>
        </Container>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveResearchLearningController_queryPagedByQueryResearchLearningByManageQto_2f861a?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            rangeQueryRangeIn: {},
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    setRows([]);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <Section title="研学活动管理">
      <VSPromiseTable
        editable={{
          editType: 'single',
          // onCanDelete: () => false,
          deleteConfirmProps: {
            title: '研学活动删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
          columnProps: { hideInTable: true },
        }}
        onFetch={onFetch}
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
        }}
        extraParams={{ refresh }}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        vsid="73483"
        addLinkPage={{
          linkPage: () => <ResearchLearnForm />,
          modalProps: { width: 670, title: '新增研学活动' },
          onLinkPageValidate: async (params) => {
            try {
              const formParam =
                await vsf.refs.ResearchLearnForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.ReserveResearchLearningController_createResearchLearning_b668e9?.(
                  {
                    btoParam: formParam,
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('添加成功');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />
        <VSTableColumn
          dataIndex={['name']}
          title="活动名称"
          valueType="text"
          search
          order={-33}
          columnKey={'nameLike'}
          fieldProps={{
            maxLength: 20,
          }}
        />

        <VSTableColumn
          dataIndex={['coverImg']}
          title="活动封面"
          render={renderCoverImg}
        />

        <VSTableColumn
          dataIndex={['activityDesc']}
          title="活动详情"
          render={renderActivityDesc}
        />

        <VSTableColumn
          title="活动时间"
          search
          hideInTable
          order={-34}
          valueType="dateRange"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['rangeQueryRangeIn'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            format: 'YYYY-MM-DD',
            valueType: 'string',
          }}
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择',
                type: 'string',
              },
            ],
          }}
        />

        <VSTableColumn
          dataIndex={['activityStartTime']}
          title="活动开始时间"
          valueType="date"
          hideInForm
          order={-34}
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['activityEndTime']}
          title="活动结束时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['activityPlace']}
          title="活动场馆"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="价格"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '价格的值不合法',
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['personCount']}
          title="人数"
          valueType="digit"
          formItemProps={{
            rules: [
              { message: '人数的值不合法', type: 'number', min: 0, max: null },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['isRefundCheck']}
          title="退款需审核"
          valueType="select"
          dataSource={[
            { label: '是', value: 'IS_CHECK' },
            { label: '否', value: 'NO_CHECK' },
          ]}
          fieldProps={{}}
        />

        {isShowPub ? (
          <VSTableColumn
            dataIndex={['storeName']}
            title="发布商家"
            valueType="text"
          />
        ) : (
          ''
        )}

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['publishStatus']}
          title="状态"
          valueType="select"
          search
          order={-35}
          columnKey={'publishStatusIs'}
          dataSource={[
            { label: '已上架', value: 'PUBLISH_ON' },
            { label: '已下架', value: 'PUBLISH_DOWN' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          width={200}
          dataIndex={['action']}
          title="操作"
          valueType="option"
        >
          <VSTableAction
            children={(data) =>
              data.publishStatus === 'PUBLISH_DOWN' ? '上架' : '下架'
            }
            show={(v) => isShowPub}
            onClick={(v, vv, params) => {
              const text =
                vv.publishStatus === 'PUBLISH_DOWN'
                  ? '是否上架？'
                  : '是否下架？';
              Modal.confirm({
                content: text,
                icon: '',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  vsf.services
                    .ReserveResearchLearningController_updateResearchLearning_ade80c(
                      {
                        btoParam: {
                          id: vv?.id,
                          publishStatus:
                            vv.publishStatus === 'PUBLISH_DOWN'
                              ? 'PUBLISH_ON'
                              : 'PUBLISH_DOWN',
                        },
                        extApiParam: {
                          flag: '1',
                        },
                      },
                    )
                    .then((res) => {
                      const meg =
                        vv.publishStatus === 'PUBLISH_DOWN' ? '上架' : '下架';
                      if (res?.code === 200) {
                        message.success(`${meg}成功`);
                        setTimeout(() => {
                          setRefresh(!refresh);
                        }, 1000);
                      }
                    });
                },
              });
            }}
          ></VSTableAction>

          <VSTableAction
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: { width: 670, title: '编辑研学活动' },
              linkPage: () => <ResearchLearnForm />,
              onLinkPageValidate: async (params, __, record) => {
                try {
                  const formParam =
                    await vsf.refs.ResearchLearnForm?.validateFieldsReturnFormatValue();
                  const res =
                    await vsf?.services?.ReserveResearchLearningController_updateResearchLearning_ade80c?.(
                      {
                        btoParam: {
                          ...formParam,
                          id: params?.id,
                        },
                        extApiParam: params?.extApiParam,
                      },
                    );
                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      setRefresh(!refresh);
                    }, 1000);
                  }
                  return res?.data;
                } catch (e) {
                  return false;
                }
              },
            }}
          >
            编辑
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              Modal.confirm({
                content: '删除后不可恢复，是否确认删除？',
                icon: '',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  vsf.services
                    .ReserveResearchLearningController_deleteResearchLearning_c2db63(
                      {
                        btoParam: {
                          id: vv?.id,
                        },
                      },
                    )
                    .then((res) => {
                      if (res?.code === 200) {
                        message.success(`删除成功`);
                        setTimeout(() => {
                          setRefresh(!refresh);
                        }, 1000);
                      }
                    });
                },
              });
            }}
          >
            删除
          </VSTableAction>
        </VSTableColumn>

        <VSTableToolbar>
          <Button
            children="批量删除"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            disabled={rows?.length <= 0}
            onClick={() => {
              handleDelete();
            }}
          />
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>

      <Modal id="preview" width={600} okText="" cancelText="" footer={null}>
        <div className="modalWatch_container__text">
          <div
            dangerouslySetInnerHTML={{ __html: richText }}
            className="bannerText"
          ></div>
        </div>
      </Modal>
    </Section>
  );
};
export default definePage(ResearchLearningList);
