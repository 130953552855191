import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveResearchLearningController_createResearchLearning_b668e9: {
    method: 'post',
    url: '/api/reserve-research-learning/create-research-learning',
    parameterFomatter: (data?: {
      btoParam: CreateResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
});
