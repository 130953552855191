import { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import React from 'react';

type ContainerProps = {
  route: RouteConfig;

  location: Location;
};

const CockpitManageRoot: React.FC<ContainerProps> = (props: any) => {
  const { route } = props;
  return <>{renderRoutes(route.routes, route.redirect)}</>;
};

export default definePage(CockpitManageRoot);
