import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveCampInfoController_createCamp_9d1756: {
    method: 'post',
    url: '/api/reserve-camp-info/create-camp',
    parameterFomatter: (data?: {
      btoParam: CreateCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampInfoVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampInfoController_updateCamp_ce8a0d: {
    method: 'post',
    url: '/api/reserve-camp-info/update-camp',
    parameterFomatter: (data?: {
      btoParam: UpdateCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampInfoVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveDayController_createReservePriceList_e20319: {
    method: 'post',
    url: '/api/reserve-camp-info/create-reserve-price-list',
    parameterFomatter: (data?: {
      btoParamList: CreateReservePriceBtoReserveCampInfoServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveDayController_getByCampId_42c039: {
    method: 'post',
    url: '/api/reserve-camp-info/get-by-camp-id',
    parameterFomatter: (data?: { campId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveDayController_deletePriceList_c035d4: {
    method: 'post',
    url: '/api/reserve-camp-info/delete-price-list',
    parameterFomatter: (data?: {
      deleteReserveDayBto: DeleteReserveDayBtoReserveCampInfoServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveDayController_updatePriceList_6c8aef: {
    method: 'post',
    url: '/api/reserve-camp-info/update-price-list',
    parameterFomatter: (data?: {
      priceBtoParam: UpdateReservePriceBtoReserveCampInfoServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveDayController_updateReservePrice_4a3794: {
    method: 'post',
    url: '/api/reserve-camp-info/update-reserve-price',
    parameterFomatter: (data?: {
      btoParam: UpdateReservePriceBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  ReserveCampInfoController_deleteListCamp_457182: {
    method: 'post',
    url: '/api/reserve-camp-info/delete-list-camp',
    parameterFomatter: (data?: {
      btoParam: DeleteCampBtoReserveCampInfoServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
