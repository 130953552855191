import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontActivityController_createActivity_dcbef3: {
    method: 'post',
    url: '/api/front-activity/create-activity',
    parameterFomatter: (data?: {
      btoParam: CreateActivityBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoVoFrontActivityEntranceWebVo,
    ) => data,
  },
  FrontActivityController_updateActivity_1e37ce: {
    method: 'post',
    url: '/api/front-activity/update-activity',
    parameterFomatter: (data?: {
      btoParam: UpdateActivityBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoVoFrontActivityEntranceWebVo,
    ) => data,
  },
  OssPolicyController_getOssPolicy_7c7b3a: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
});
