import AccountManage from '@pages/AccountManage';
import ActivitiesManage from '@pages/ActivitiesManage';
import AppointmentManage from '@pages/AppointmentManage';
import BannerManage from '@pages/BannerManage';
import CampingManage from '@pages/CampingManage';
import CampPositionManage from '@pages/CampPositionManage';
import HelpingManage from '@pages/HelpingManage';
import InfomationManage from '@pages/InfomationManage';
import Logs from '@pages/Logs';
import MessageManage from '@pages/MessageManage';
import PackageManagement from '@pages/PackageManagement';
import Service from '@pages/Service';
import ShopManage from '@pages/ShopManage';
import UserList from '@pages/UserList';

import campingManage from '@/assets/images/icon/campManage.png';
import setting from '@/assets/images/icon/setting.png';
import BannerManagement from '@/pages/BannerManagement';
import CampOrder from '@/pages/campOrder';
import Cockpit from '@/pages/Cockpit';
import CockpitManageRoot from '@/pages/CockpitManageRoot';
import CockpitMenu from '@/pages/CockpitMenu';
import Login from '@/pages/Login';
import MessageManageDetail from '@/pages/MessageManageDetail';
import OrderMange from '@/pages/OrderMange';
import PartitionManageRoot from '@/pages/partitionManageRoot';
import ResearchLearningMange from '@/pages/ResearchLearningMange';
import SystemManageRoot from '@/pages/systemManageRoot';
import TicketsMange from '@/pages/TicketsMange';

const routes = [
  {
    path: '/Login',
    exact: true,
    component: Login,
  },
  {
    // 首页管理
    path: '/bannerManage',
    component: BannerManage,
    exact: true,
    label: '首页管理',
  },
  {
    // 用户管理
    path: '/userList',
    component: UserList,
    exact: true,
    label: '用户管理',
  },
  {
    // 预约管理
    path: '/appointmentManage',
    component: AppointmentManage,
    exact: true,
    label: '预约管理',
  },
  // 商品管理
  {
    path: '/shopManage',
    component: ShopManage,
    exact: true,
    label: '商品管理',
  },
  // 订单管理
  {
    path: '/CampOrder',
    component: CampOrder,
    exact: true,
    label: '订单管理',
  },
  {
    // 活动管理
    path: '/activitiesManage',
    component: ActivitiesManage,
    label: '活动管理',
    exact: true,
  },

  {
    // 资讯管理
    path: '/InfomationManage',
    component: InfomationManage,
    label: '资讯管理',
    exact: true,
  },

  {
    // 援助管理
    path: '/helpingManage',
    component: HelpingManage,
    exact: true,
    label: '援助管理',
  },
  {
    // 消息管理
    path: '/messageManage',
    component: MessageManage,
    exact: true,
    label: '通知管理',
  },
  {
    // 消息管理
    path: '/MessageManageDetail/:id',
    component: MessageManageDetail,
    exact: true,
    hideInMenu: true,
    label: '消息详情',
  },
  {
    path: '/service',
    component: Service,
    exact: true,
  },

  {
    path: '/CockpitManage',
    component: CockpitManageRoot,
    label: '驾驶舱管理',
    icon: campingManage,
    routes: [
      {
        path: '/CockpitManage/Cockpit',
        exact: true,
        component: Cockpit,
        label: '驾驶舱',
      },

      {
        path: '/CockpitManage/CockpitMenu',
        exact: true,
        component: CockpitMenu,
        label: '驾驶舱数据管理',
      },
    ],
  },

  // {
  //   // 标记管理
  //   path: '/tagManage',
  //   component: TagManage,
  //   exact: true,
  //   label: '标记管理',
  // },
  {
    path: '/PartitionManage',
    component: PartitionManageRoot,
    label: '营地管理',
    routes: [
      {
        path: '/PartitionManage/campingManage',
        exact: true,
        component: CampingManage,
        label: '分区管理',
      },
      {
        path: '/PartitionManage/campPositionManage',
        exact: true,
        component: CampPositionManage,
        label: '营位管理',
      },
      {
        path: '/PartitionManage/packageManagement',
        exact: true,
        component: PackageManagement,
        label: '套餐管理',
      },
    ],
  },
  {
    path: '/SystemManage',
    component: SystemManageRoot,
    label: '系统管理',
    routes: [
      {
        path: '/SystemManage/accountManage',
        exact: true,
        component: AccountManage,
        label: '账号管理',
      },
      {
        path: '/SystemManage/logs',
        exact: true,
        component: Logs,
        label: '系统日志',
      },
    ],
  },

  // {
  //   // 门票管理
  //   path: '/TicketsMange',
  //   component: TicketsMange,
  //   exact: true,
  //   label: '门票管理',
  // },
  // {
  //   // 研学管理
  //   path: '/ResearchLearningMange',
  //   component: ResearchLearningMange,
  //   exact: true,
  //   label: '研学管理',
  // },
  // {
  //   // 首页管理
  //   path: '/BannerManagement',
  //   component: BannerManagement,
  //   exact: true,
  //   label: '首页管理',
  // },
  // {
  //   // 订单管理
  //   path: '/OrderMange',
  //   component: OrderMange,
  //   exact: true,
  //   label: '订单管理',
  // },
];

export default routes;
