import Root from '@/layouts';
import Cockpit from '@/pages/Cockpit';
import LearningCockpit from '@/pages/learningCockpit';
import Login from '@/pages/Login';

import pageRoutes from './routes';

const routes = [
  {
    path: '/Login',
    exact: true,
    component: Login,
  },
  {
    path: '/CockpitManage/Cockpit',
    exact: true,
    component: Cockpit,
  },
  {
    path: '/learningCockpitManage/learningCockpit',
    exact: true,
    component: LearningCockpit,
  },
  {
    path: '/',
    component: Root,
    // redirect: '/BannerManage',
    routes: pageRoutes,
  },
];

export default routes;
