import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveGroupBuyController_createGroupBuyManage_039406: {
    method: 'post',
    url: '/api/reserve-group-buy/create-group-buy-manage',
    parameterFomatter: (data?: {
      btoParam: CreateGroupBuyManageBtoReserveGroupBuyServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: GroupBuyManageVoReserveGroupBuyEntranceWebVo,
    ) => data,
  },
  ReserveGroupBuyController_getPagedByQueryGroupBuyManageQto_8923e6: {
    method: 'post',
    url: '/api/reserve-group-buy/get-paged-by-query-group-buy-manage-qto',
    parameterFomatter: (data?: {
      qto: QueryGroupBuyManageQtoReserveGroupBuyPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: GroupBuyManageVoReserveGroupBuyEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveGroupBuyController_updateGroupBuyManage_deb737: {
    method: 'post',
    url: '/api/reserve-group-buy/update-group-buy-manage',
    parameterFomatter: (data?: {
      btoParam: UpdateGroupBuyManageBtoReserveGroupBuyServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: GroupBuyManageVoReserveGroupBuyEntranceWebVo,
    ) => data,
  },
  ReserveGroupBuyController_getGroupBuyManageVoById_805fdc: {
    method: 'post',
    url: '/api/reserve-group-buy/get-group-buy-manage-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: GroupBuyManageVoReserveGroupBuyEntranceWebVo,
    ) => data,
  },
});
