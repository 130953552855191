import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontPageController_createFront_d10dde: {
    method: 'post',
    url: '/api/front-page/create-front',
    parameterFomatter: (data?: {
      btoParam: CreateFrontBtoFrontPageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: FrontPageVoFrontPageEntranceWebVo) =>
      data,
  },
  FrontPageController_updateFront_f784f8: {
    method: 'post',
    url: '/api/front-page/update-front',
    parameterFomatter: (data?: {
      btoParam: UpdateFrontBtoFrontPageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: FrontPageVoFrontPageEntranceWebVo) =>
      data,
  },
  FrontPageController_deleteFront_6e766e: {
    method: 'post',
    url: '/api/front-page/delete-front',
    parameterFomatter: (data?: {
      btoParam: DeleteFrontBtoFrontPageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FrontPageController_queryPagedByFrontPage_f222a8: {
    method: 'post',
    url: '/api/front-page/query-paged-by-front-page',
    parameterFomatter: (data?: {
      qto: FrontPageQtoFrontPagePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: FrontPageVoFrontPageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
