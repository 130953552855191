import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  VSPromiseTable,
  message,
  Image,
  VSTableAction,
  Modal,
  Tooltip,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import BannerManageForm from '@pages/BannerManageForm';
import styled from 'styled-components';
import BraftEditor from 'braft-editor';

const BannerImageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ManageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BannerTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [num, setNum] = useState();
  const [refresh, setRefresh] = useState();
  const [image, SetImage] = useState();
  const [richText, SetRichText] = useState(String);

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.FrontPageController_queryPagedByFrontPage_f222a8?.({
        qto: {
          from: params.pagination?.from ?? 0,
          size: params.pagination?.size ?? 20,
          orderList: params.orderList,
          ...params.search,
          ...params.filters,
          ...(params?.extra ?? {}),
          ...(params ?? {}),
        },
        ext: {},
      });
    setNum(res?.data?.count);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderBannerImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.carouselImg ?? '[]');
      return (
        <BannerImageContainer>
          {_imgs?.map((item, _idx) => {
            return (
              <>
                <Image
                  width={70}
                  height={70}
                  style={{ borderRadius: '2px', marginRight: '5px' }}
                  key={_idx}
                  src={item}
                />

                <div
                  className="watchBtn"
                  onClick={() => {
                    vsf.openModal('preview');
                    SetImage(item);
                    SetRichText(null);
                  }}
                >
                  预览
                </div>
              </>
            );
          })}
        </BannerImageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderWatchText = (_, record) => {
    try {
      return (
        <BannerImageContainer>
          <div
            className="watchBtn"
            onClick={() => {
              vsf.openModal('preview');
              SetImage(null);
              SetRichText(record?.richText);
            }}
          >
            预览内容
          </div>
        </BannerImageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const rederIndex = (_, __, index) => {
    return (
      <ManageContainer>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </ManageContainer>
    );
  };

  return (
    <div className="bannerContainer">
      <Section title="Banner">
        <VSPromiseTable
          editable={{
            editType: 'single',
            deleteConfirmProps: {
              title: '删除后不可恢复，是否确认删除？',
              icon: <></>,
            },
            columnProps: { hideInTable: true },
          }}
          vsid="76725"
          onFetch={onFetch}
          extraParams={{ refresh }}
          pagination={{
            onChange(currentIndex, _pageSize) {
              setPageIndex(currentIndex - 1);
              setPageSize(_pageSize);
            },
          }}
          addLinkPage={{
            linkPage: () => <BannerManageForm />,
            modalProps: { width: 670 },
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.BannerManageForm?.validateFieldsReturnFormatValue();
                const res =
                  await vsf.services?.FrontPageController_createFront_d10dde?.({
                    btoParam: fromParam,
                    extApiParam: params?.extApiParam,
                  });

                if (res && res?.code === 200) {
                  message.success('添加成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
          }}
          updateLinkPage={{
            linkPage: () => <BannerManageForm />,
            modalProps: { width: 670 },
            onLinkPageValidate: async () => {
              try {
                const fromParam =
                  await vsf?.refs?.BannerManageForm?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.FrontPageController_updateFront_f784f8?.(
                    {
                      btoParam: { ...(fromParam ?? {}) },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
          }}
        >
          <VSTableColumn
            dataIndex={['index']}
            title="序号"
            valueType="digit"
            fieldProps={{}}
            render={rederIndex}
          />

          <VSTableColumn dataIndex={['title']} title="标题" valueType="text" />

          <VSTableColumn
            dataIndex={['carouselImg']}
            title="图片"
            render={renderBannerImg}
          />
          {/* 
          <VSTableColumn
            dataIndex={['text']}
            title="文本"
            valueType="text"
            ellipsis
          /> */}

          {/* <VSTableColumn
            dataIndex={['link']}
            title="跳转页面"
            valueType="text"
          /> */}

          <VSTableColumn
            dataIndex={['richText']}
            title="内容"
            render={renderWatchText}
          />

          <VSTableColumn
            dataIndex={['createdAt']}
            title="添加时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            width={200}
            dataIndex={['action']}
            title="操作"
            valueType="option"
          >
            <VSTableAction
              children={(data) =>
                data.publishStatus === 'PUBLISH_DOWN' ? '上架' : '下架'
              }
              onClick={(v, vv, params) => {
                const text =
                  vv.publishStatus === 'PUBLISH_DOWN'
                    ? '是否上架？'
                    : '是否下架？';
                Modal.confirm({
                  content: text,
                  icon: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    vsf.services
                      .FrontPageController_updateFront_f784f8({
                        btoParam: {
                          id: vv?.id,
                          publishStatus:
                            vv.publishStatus === 'PUBLISH_DOWN'
                              ? 'PUBLISH_ON'
                              : 'PUBLISH_DOWN',
                        },
                      })
                      .then((res) => {
                        const meg =
                          vv.publishStatus === 'PUBLISH_DOWN' ? '上架' : '下架';
                        if (res?.code === 200) {
                          message.success(`${meg}成功`);
                          setTimeout(() => {
                            setRefresh(!refresh);
                          }, 1000);
                        }
                      });
                  },
                });
              }}
            ></VSTableAction>
            <VSTableAction
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                modalProps: {
                  width: 670,
                  title: '编辑Banner',
                },
                linkPage: () => <BannerManageForm />,
                onLinkPageValidate: async (params, __, record) => {
                  try {
                    const fromParam =
                      await vsf?.refs?.BannerManageForm?.validateFieldsReturnFormatValue();

                    const response =
                      await vsf?.services?.FrontPageController_updateFront_f784f8?.(
                        {
                          btoParam: {
                            ...fromParam,
                            id: record?.id,
                          },
                        },
                      );
                    if (response && response?.code === 200) {
                      message.success('编辑成功');
                      setTimeout(() => {
                        setRefresh(!refresh);
                      }, 500);
                    }
                    return response?.data;
                  } catch (error) {
                    console.error(error);
                    return false;
                  }
                },
              }}
            >
              编辑
            </VSTableAction>
            <VSTableAction
              onClick={(v, vv, params) => {
                Modal.confirm({
                  content: '删除后不可恢复，是否确认删除？',
                  icon: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    vsf.services
                      .FrontPageController_deleteFront_6e766e({
                        btoParam: {
                          id: vv?.id,
                        },
                      })
                      .then((res) => {
                        if (res?.code === 200) {
                          message.success(`删除成功`);
                          setTimeout(() => {
                            setRefresh(!refresh);
                          }, 1000);
                        }
                      });
                  },
                });
              }}
            >
              删除
            </VSTableAction>
          </VSTableColumn>

          <VSTableToolbar title="">
            <VSTableAddition
              children="新增"
              // disabled={num >= 10}
              position="top"
            />
          </VSTableToolbar>
        </VSPromiseTable>

        <Modal id="preview" width={600} okText="" cancelText="" footer={null}>
          {image && (
            <>
              <div className="modalWatch_container">
                <img src={image} alt="" className="bannerImage" />
              </div>
            </>
          )}

          {richText && (
            <>
              <div className="modalWatch_container__text">
                <div
                  dangerouslySetInnerHTML={{ __html: richText }}
                  className="bannerText"
                ></div>
              </div>
            </>
          )}
        </Modal>
      </Section>
    </div>
  );
};
export default definePage(BannerTable);
