import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
  VSTableAction,
} from '@vs/vsf-kit';
import React, { useState } from 'react';
import GroupBuyManagerForm from '@pages/GroupBuyManagerForm';
import { QRCodeCanvas } from 'qrcode.react';
import styled from 'styled-components';

const GroupBuyManagerContainer = styled.div`
  display: flex;
  align-items: center;
`;
const GroupBuyManagerList = (props) => {
  const [refresh, setRefresh] = useState();
  const rederQrCode = (_, params, index) => {
    // const codeUrl = `id=${params.id}&type=group {id="xxx", type=""}`;
    const codeUrl = JSON?.stringify({ id: params.id, type: 'group' });

    return (
      <GroupBuyManagerContainer>
        <QRCodeCanvas
          style={{
            width: 70,
            height: 70,
          }}
          id={`qrcode${index + 1}`}
          value={codeUrl}
        />
        {/* <div
          className="downLoadCode GroupBuyManagerCode"
          onClick={() => {
            const canvas = document.getElementById(`qrcode${index + 1}`);
            if (canvas) {
              const url = canvas.toDataURL();
              const a = document.createElement('a');
              a.download = '收款码.png';
              a.href = url;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }}
        >
          下载
        </div> */}
      </GroupBuyManagerContainer>
    );
  };

  const handleSend = async (id) => {
    const data =
      vsf?.services?.ReserveGroupBuyController_sendNoticeMessage_3528ea({
        id,
      });
    if (data?.code === 200) {
      message?.success('发送成功');
    }
  };
  return (
    <Section title="团购管理">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        vsid="91756"
        id="groupBuyManagerList"
        onFetch={async (params) => {
          // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
          // Tips: 以下是你选择的请求函数
          console.log(params, 'params.pagination');
          const res =
            await vsf.services?.ReserveGroupBuyController_getPagedByQueryGroupBuyManageQto_8923e6?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                qto: {
                  from: params.pagination?.from ?? 0,
                  size: params.pagination?.size ?? 20,
                  orderList: params.orderList,
                  ...params.search,
                  ...params.filters,
                  ...(params?.extra ?? {}),
                  ...(params ?? {}),
                },
              },
            ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
        // extraParams={{ refresh }}
        onAdd={async (params) => {
          // Tips: 函数入参结构和组件RO一致
          // Tips: 以下是你选择的请求函数
          console.log(params, 'params');
          const res =
            await vsf.services?.ReserveGroupBuyController_createGroupBuyManage_039406?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: { ...params, refresh: null },
                extApiParam: params?.extApiParam,
              },
            ); // Tips: 需要返回完整数据结构，和该组件RO一致
          return res?.data;
        }}
        onUpdate={async (params) => {
          // Tips: 函数入参结构和组件RO一致
          // Tips: 以下是你选择的请求函数
          console.log(params, 'params2');
          const res =
            await vsf.services?.ReserveGroupBuyController_updateGroupBuyManage_deb737?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: params,
                extApiParam: params?.extApiParam,
              },
            ); // Tips: 需要返回完整数据结构，和该组件RO一致
          return res?.data;
        }}
        addLinkPage={{
          linkPage: () => <GroupBuyManagerForm />,
          modalProps: {
            title: '创建订单',
            width: 500,
            okText: '确定',
            cancelText: '取消',
          },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.groupBuyManageForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.ReserveGroupBuyController_createGroupBuyManage_039406?.(
                  {
                    // TODO: 请检查以下这部分传参是否符合需求
                    btoParam: fromParam,
                    extApiParam: params?.extApiParam,
                  },
                ); // Tips: 需要返回提交的完整数据，和表单RO一致
              if (res && res?.code === 200) {
                // console.log('添加成功');
                message.success('创建成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              console.log('取消');
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['orderNumIs']}
          title="订单编号"
          valueType="text"
          search
          // order={0}
          columnKey={'orderNumIs'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '订单编号的值不合法',
                type: 'string',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['phoneIs']}
          title="手机号"
          valueType="text"
          search
          order={0}
          columnKey={'phoneIs'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '手机号长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['reserveDateRangeIn']}
          title="预约时间"
          valueType="dateRange"
          search
          order={0}
          columnKey={'reserveDateRangeIn'}
          hideInTable
          searchConfig={{
            transform: (values) => {
              if (!Array.isArray(values)) return values;
              return {
                reserveDateRangeIn: {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              };
            },
          }}
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['reserveDateRangeIn'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          convertValue={(obj) => {
            if (Array.isArray(obj)) return obj;
            return [obj?.begin, obj?.end];
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderNum']}
          title="订单编号"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '订单编号的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['activityName']}
          title="活动名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '活动名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['personName']}
          title="联系人姓名"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '联系人名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['phone']}
          title="手机号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '手机号长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['personNum']}
          title="人数"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '人数的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['reserveDate']}
          title="预约日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD' }}
        />

        <VSTableColumn
          dataIndex={['activityFrame']}
          title="预约时段"
          valueType="select"
          dataSource={[
            { label: '上午', value: 'MORNING' },
            { label: '下午', value: 'AFTERNOON' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          title="券码"
          valueType="select"
          dataSource={[
            { label: '上午', value: 'MORNING' },
            { label: '下午', value: 'AFTERNOON' },
          ]}
          fieldProps={{}}
          render={rederQrCode}
        />

        <VSTableColumn
          dataIndex={['status']}
          title="核销状态"
          valueType="select"
          dataSource={[
            { label: '已核销', value: 'VERIFICATION' },
            { label: '未核销', value: 'NO_VERIFICATION' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          width={130}
          dataIndex={['action']}
          title="操作"
          valueType="option"
        >
          <VSTableAction
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: {
                width: 500,
                title: '编辑团购信息',
              },
              linkPage: (value) => {
                return <GroupBuyManagerForm value={value} />;
              },
              onLinkPageValidate: async (value, _, record) => {
                try {
                  const fromParam =
                    await vsf?.refs?.groupBuyManageForm?.validateFieldsReturnFormatValue();
                  const res =
                    await vsf.services?.ReserveGroupBuyController_updateGroupBuyManage_deb737?.(
                      {
                        btoParam: { ...value, ...fromParam },
                        extApiParam: {},
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      setRefresh(!refresh);
                    }, 1000);
                  }
                  return res?.data;
                } catch (error) {
                  return false;
                }
              },
            }}
          >
            编辑
          </VSTableAction>
          <VSTableAction
            children="发送短信"
            onClick={(e, value) => {
              value?.status === 'NO_VERIFICATION' && handleSend(value?.id);
            }}
          />
        </VSTableColumn>

        <VSTableToolbar>
          <VSTableAddition children="创建订单" position='top' />
        </VSTableToolbar>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(GroupBuyManagerList);
