import { createServices } from '@vs/vsf-boot';
export default createServices({
  AidManagementController_queryPagedByAidInfoQto_31e6a6: {
    method: 'post',
    url: '/api/aid-management/query-paged-by-aid-info-qto',
    parameterFomatter: (data?: {
      qto: AidInfoQtoAidManagementPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: AidInfoVoAidManagementEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  AidManagementController_updateAid_7ae610: {
    method: 'post',
    url: '/api/aid-management/update-aid',
    parameterFomatter: (data?: {
      btoParam: UpdateAidBtoAidManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AidInfoVoAidManagementEntranceWebVo) =>
      data,
  },
});
