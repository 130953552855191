import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
const CampingMangeEditForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="84289"
        layout="inline"
        id="CampingMangeEditForm"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout key="0" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['partitionName']}
            label="分区名称"
            required
            valueType="text"
            rules={[{ required: true, message: '请输入分区名称' }]}
            fieldProps={{
              maxLength: 10,
            }}
          />

          <VSFormItem
            name={['partitionImg']}
            label="封面图"
            required
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传封面图' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  partitionImg: v,
                };
              }
              return {
                partitionImg: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize">建议尺寸：320 * 200</div>
          <VSFormItem
            name={['partitionStatus']}
            label="状态"
            required
            valueType="select"
            initialValue={'ENABLE'}
            rules={[{ required: true, message: '请选择状态' }]}
            dataSource={[
              { label: '在用', value: 'ENABLE' },
              { label: '停用', value: 'DISABLE' },
            ]}
          />

          <VSFormItem
            name={['remark']}
            label="备注"
            valueType="textarea"
            fieldProps={{
              maxLength: 250,
              autoSize: {
                minRows: 3,
                maxRows: 4,
              },
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(CampingMangeEditForm);
