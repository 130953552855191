import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderMerchantController_createMerchant_3413e0: {
    method: 'post',
    url: '/api/order-merchant/create-merchant',
    parameterFomatter: (data?: {
      btoParam: CreateMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_deleteBatchMerchant_709dfc: {
    method: 'post',
    url: '/api/order-merchant/delete-batch-merchant',
    parameterFomatter: (data?: {
      btoParam: DeleteMerchantBtoOrderMerchantServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantController_deleteMerchant_a447e3: {
    method: 'post',
    url: '/api/order-merchant/delete-merchant',
    parameterFomatter: (data?: {
      btoParam: DeleteMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_getPagedByMerchantQto_a88b3f: {
    method: 'post',
    url: '/api/order-merchant/get-paged-by-merchant-qto',
    parameterFomatter: (data?: { qto: MerchantQtoOrderMerchantPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MerchantInfoVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantController_merchantWorkPay_fb2f9b: {
    method: 'post',
    url: '/api/order-merchant/merchant-work-pay',
    parameterFomatter: (data?: {
      merchantId: number;
      userId: number;
      passWord: string;
      price: string;
      phone: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantController_updateMerchant_186dfc: {
    method: 'post',
    url: '/api/order-merchant/update-merchant',
    parameterFomatter: (data?: {
      btoParam: UpdateMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  ReserveGroupBuyController_sendNoticeMessage_3528ea: {
    method: 'post',
    url: '/api/reserve-group-buy/send-notice-message',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
});
