import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontResearchLearningController_createFrontLearning_320c57: {
    method: 'post',
    url: '/api/front-research-learning/create-front-learning',
    parameterFomatter: (data?: {
      btoParam: CreateFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
});
