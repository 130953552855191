import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  VSPromiseTable,
  VSTableAction,
  Modal,
  Button,
  message,
  Image,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import TicketsMangeForm from '@pages/TicketsMangeForm';
import TicketsMangePriceForm from '@pages/TicketsMangePriceForm';
import { timeFormat, timeMap } from '@/utils';
import styled from 'styled-components';
import BraftEditor from 'braft-editor';
const ManageContainer = styled.div`
  overflow: hidden;
  width: 70px;
  height: 70px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const DiscountContainer = styled.div``;
const TicketsMangeList = (props) => {
  const [refresh, setRefresh] = useState();
  const [id, setId] = useState();
  const [ticketName, setTicketName] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rows, setRows] = useState([]);
  const [richText, SetRichText] = useState(String);

  const rederIndex = (_, __, index) => {
    return (
      <Container>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </Container>
    );
  };

  const renderCampImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.ticketsCover ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '5px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderIntroduction = (_, record) => {
    try {
      return (
        <Container>
          <div
            className="watchBtn"
            onClick={() => {
              vsf.openModal('preview');
              SetRichText(record?.introduction);
            }}
          >
            预览介绍
          </div>
        </Container>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderDiscount = (_, record) => {
    var newStudentDiscount = [];
    for (let item of record?.studentDiscount.toString() ?? []) {
      newStudentDiscount.push(Number(item));
    }
    var newOutStudentDiscount = [];
    for (let item of record?.outStudentDiscount.toString() ?? []) {
      newOutStudentDiscount.push(Number(item));
    }

    try {
      return (
        <DiscountContainer>
          <div>
            普通学生票
            {newStudentDiscount?.length > 1
              ? record?.studentDiscount
              : record?.studentDiscount * 10}
            %；
          </div>
          <div>
            外地学生票
            {newOutStudentDiscount?.length > 1
              ? record?.outStudentDiscount
              : record?.outStudentDiscount * 10}
            %
          </div>
        </DiscountContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderToday = (_, record) => {
    try {
      return (
        <DiscountContainer>
          <div>
            上午：
            {record?.morningStock ?? 0}
          </div>
          <div>
            下午：
            {record?.afternoonStock ?? 0}
          </div>
        </DiscountContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveTicketsController_queryPagedByTicketsManageQto_7f3490?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    setRows([]);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handleDelete = async () => {
    try {
      if (rows?.length <= 0) {
        return message.error('请先选择要删除的门票');
      }
      Modal.confirm({
        content: '门票删除后不可恢复，是否确认删除？',
        icon: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          vsf.services
            .ReserveTicketsController_batchDeleteTickets_759754({
              btoParam: rows?.map((item) => ({
                id: item?.id,
              })),
            })
            .then((res) => {
              if (res && res.code === 200) {
                message.success('删除成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
            });
        },
      });
    } catch (error) {}
  };

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };
  return (
    <Section title="门票管理">
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
          columnProps: { hideInTable: true },
        }}
        vsid="62518"
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
        }}
        onFetch={onFetch}
        extraParams={{ refresh }}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        addLinkPage={{
          linkPage: () => <TicketsMangeForm />,
          modalProps: { width: 670, title: '新增门票' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.TicketsMangeForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.ReserveTicketsController_createTickets_14e4be?.(
                  {
                    btoParam: {
                      ...fromParam,
                      outStudentDiscount:
                        Number(fromParam?.outStudentDiscount) * 10,
                      studentDiscount: Number(fromParam?.studentDiscount) * 10,
                    },
                    extApiParam: params?.extApiParam,
                  },
                );

              if (res && res?.code === 200) {
                message.success('添加成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        onRemove={async (params) => {
          const res =
            await vsf.services?.ReserveTicketsController_deleteTickets_ba2f66?.(
              {
                btoParam: params,
                extApiParam: params?.extApiParam,
              },
            );
          return res?.code === 200;
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />

        <VSTableColumn
          dataIndex={['ticketsNo']}
          title="门票编号"
          search
          valueType="text"
          fieldProps={{}}
          columnKey={'ticketsNoIs'}
        />

        <VSTableColumn
          dataIndex={['ticketsName']}
          title="门票名称"
          search
          valueType="text"
          order={2}
          columnKey={'ticketsNameLike'}
          fieldProps={{
            maxLength: 20,
          }}
        />

        <VSTableColumn
          dataIndex={['ticketsCover']}
          title="门票封面"
          valueType="text"
          render={renderCampImg}
        />

        <VSTableColumn
          dataIndex={['introduction']}
          title="介绍"
          valueType="text"
          render={renderIntroduction}
        />

        <VSTableColumn
          title="票种折扣"
          valueType="text"
          render={renderDiscount}
        />

        <VSTableColumn
          dataIndex={['adultPrice']}
          title="成人票价格"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['maxCapacity']}
          title="最大客容量"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '最大客容量的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn title="今日库存" render={renderToday} />

        <VSTableColumn
          dataIndex={['audit']}
          title="退款需审核"
          valueType="select"
          dataSource={[
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="添加时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['status']}
          title="状态"
          search
          valueType="select"
          columnKey={'statusIs'}
          dataSource={[
            { label: '已上架', value: 'PUTAWAY' },
            { label: '已下架', value: 'OFFLINE' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          width={200}
          dataIndex={['action']}
          title="操作"
          valueType="option"
        >
          <VSTableAction
            onClick={(v, vv, params) => {
              vsf.openModal('setPriceModal');
              setId(vv?.id);
              setTicketName(vv?.ticketsName);
            }}
          >
            设置价格
          </VSTableAction>

          <VSTableAction
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: {
                width: 670,
                title: '编辑门票',
              },
              linkPage: () => <TicketsMangeForm />,
              onLinkPageValidate: async (_, __, record) => {
                try {
                  const fromParam =
                    await vsf?.refs?.TicketsMangeForm?.validateFieldsReturnFormatValue();
                  const response =
                    await vsf?.services?.ReserveTicketsController_updateTickets_923e34?.(
                      {
                        btoParam: {
                          ...fromParam,
                          outStudentDiscount:
                            Number(fromParam?.outStudentDiscount) * 10,
                          studentDiscount:
                            Number(fromParam?.studentDiscount) * 10,
                          id: record?.id,
                        },
                      },
                    );
                  if (response && response?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      setRefresh(!refresh);
                    }, 500);
                  }
                  return response?.data;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              },
            }}
          >
            编辑
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              Modal.confirm({
                content: '删除后不可恢复，是否确认删除？',
                icon: '',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  vsf.services
                    .ReserveTicketsController_deleteTickets_ba2f66({
                      btoParam: {
                        id: vv?.id,
                      },
                    })
                    .then((res) => {
                      if (res?.code === 200) {
                        message.success(`删除成功`);
                        setTimeout(() => {
                          setRefresh(!refresh);
                        }, 1000);
                      }
                    });
                },
              });
            }}
          >
            删除
          </VSTableAction>
        </VSTableColumn>

        <VSTableToolbar>
          <Button
            children="批量删除"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            disabled={rows?.length <= 0}
            onClick={() => {
              handleDelete();
            }}
          />
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>

      <Modal
        title="价格列表"
        id="setPriceModal"
        width={700}
        // onOk={handleSubmitPrice}
        okText="确定"
        cancelText="取消"
      >
        <div className="setPriceTable">
          <div style={{ fontSize: 14 }}>
            {ticketName} （仅支持设置即日起90天内的价格）
          </div>
          <VSPromiseTable
            editable={{
              editType: 'single',
              deleteConfirmProps: {
                title: '是否确认删除？',
                icon: <></>,
              },
            }}
            pagination={false}
            extraParams={{ refresh }}
            onFetch={async (params) => {
              const res =
                await vsf.services?.ReserveTicketsController_getByTicketsId_7e5eaf?.(
                  {
                    ticketsId: id,
                  },
                );
              return {
                data: res?.data ?? res?.data ?? [],
              };
            }}
            onRemove={async (params) => {
              const res =
                await vsf.services?.ReserveTicketsController_deleteDateTickets_46e8bc?.(
                  {
                    btoParam: {
                      id: params?.id,
                    },

                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('删除成功');
              }
              return res?.code === 200;
            }}
            addLinkPage={{
              linkPage: () => <TicketsMangePriceForm />,
              modalProps: { width: 550, title: '设置价格' },
              onLinkPageValidate: async (params) => {
                try {
                  const fromParam =
                    await vsf?.refs?.TicketsMangePriceForm?.validateFieldsReturnFormatValue();
                  const dateList = timeMap(
                    fromParam?.time?.begin,
                    fromParam?.time?.end,
                  );
                  const formList = [];
                  dateList?.map((item) => {
                    formList.push({
                      day: timeFormat(item),
                      price: fromParam?.price,
                      ticketsId: id,
                    });
                  });
                  const res =
                    await vsf.services?.ReserveTicketsController_createTicketPriceList_d4e5b4?.(
                      {
                        btoParamList: formList,
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('设置成功');
                  }
                  return res?.data;
                } catch (e) {
                  return false;
                }
              },
            }}
            updateLinkPage={{
              linkPage: () => <TicketsMangePriceForm />,
              modalProps: { width: 550, title: '设置价格' },
              onLinkPageValidate: async (params) => {
                try {
                  const fromParam =
                    await vsf?.refs?.TicketsMangePriceForm?.validateFieldsReturnFormatValue();
                  const res =
                    await vsf.services?.ReserveTicketsController_updateDateTickets_9a814b?.(
                      {
                        btoParam: {
                          ...fromParam,
                          ticketsId: params?.ticketsId,
                          id: params?.id,
                        },
                        extApiParam: params?.extApiParam,
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                  }
                  return res?.data;
                } catch (error) {
                  return false;
                }
              },
            }}
          >
            <VSTableColumn
              dataIndex={['day']}
              title="日期"
              valueType="date"
              order={-13}
              fieldProps={{
                format: 'YYYY-MM-DD',
                valueType: 'string',
              }}
            />

            <VSTableColumn
              dataIndex={['price']}
              title="价格"
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableToolbar title="">
              <VSTableAddition children="新增" position="top" />
            </VSTableToolbar>
          </VSPromiseTable>
        </div>
      </Modal>

      <Modal
        title="设置价格"
        id="setPriceFormModal"
        width={550}
        // onOk={handleSubmitPrice}
        okText="确定"
        cancelText="取消"
      >
        <TicketsMangePriceForm />
      </Modal>

      <Modal id="preview" width={600} okText="" cancelText="" footer={null}>
        <div className="modalWatch_container__text">
          <div
            dangerouslySetInnerHTML={{ __html: richText }}
            className="bannerText"
          ></div>
        </div>
      </Modal>
    </Section>
  );
};
export default definePage(TicketsMangeList);
