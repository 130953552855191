import './FundAnalysis.less';
import vsf, { definePage } from '@vs/vsf-boot';
import * as echarts from 'echarts';
import {
  Section,
  DatePicker,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
  Button,
  Statistic,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
moment.locale('zh-cn');

const FundAnalysis = (props) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [amountData, setAmountData] = useState({});
  const [params, setParams] = useState();
  const [statisticsData, setStatisticsData] = useState();

  const loadAmountManage = useCallback(async () => {
    const res =
      await vsf.services?.OrderMerchantController_queryAmountManage_725778?.({
        year,
        month,
      });
    if (res && res.code === 200) {
      setAmountData(res.data);
      loadInformationEcharts(res.data?.tradeRecord);
    }
  }, [year, month]);

  useEffect(() => {
    loadAmountManage();
  }, [loadAmountManage]);

  const handleTimePicker = (date, dateString) => {
    const CurDate = new Date(date);
    setYear(CurDate.getFullYear());
    setMonth(CurDate.getMonth() + 1);
  };

  const loadInformationEcharts = (monthDate) => {
    const monthData = [];
    monthDate?.forEach((item, index) => {
      monthData.push([item.tradeDate, item.tradeAmount]);
    });
    const chartDom = document.getElementById('centerEcharts');
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
        },

        series: [
          {
            type: 'line',
            lineStyle: {
              color: '#007940',
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: '#ecf2f0',
                },
                {
                  offset: 1,
                  color: '#cde3da',
                },
              ]),
            },

            data: monthData,
          },
        ],
      });
    }
  };

  const onFetch = useCallback(async (params) => {
    setParams(params);
    const res =
      await vsf.services?.OrderMerchantController_getPagedByTransactionRecordQto_43ab71?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const queryTransactionRecordStatistics = async () => {
    const res =
      await vsf.services.OrderMerchantController_queryTransactionRecordStatistics_09f967(
        {
          qto: {
            ...params.search,
          },
        },
      );
    if (res.data && res.code === 200) {
      setStatisticsData(res?.data);
    }
  };

  useEffect(() => {
    if (params) {
      queryTransactionRecordStatistics();
    }
  }, [params]);

  queryTransactionRecordStatistics;
  return (
    <Section
      title="资金管理"
      style={{
        position: 'relative',
      }}
    >
      <div className="recordBtn">
        <Button
          children="提现审批记录"
          style={{
            backgroundColor: '#37927b',
            color: 'white',
            height: '38px',
            borderRadius: '6px',
            fontWeight: 'bold',
          }}
          onClick={() => {
            vsf.navigateTo(`/WithdrawalApproval`);
          }}
        />
      </div>
      <div className="fundAnalysisContainer">
        <div className="fundAnalysisContainer_content">
          <div className="fundAnalysisContainer_top">
            <div className="fundAnalysisContainer_top__item">
              <div className="fundAnalysisContainer_top__item__text">
                总交易额(元)
              </div>
              <div className="fundAnalysisContainer_top__item__value">
                <Statistic
                  valueStyle={{ color: '#37927b', fontSize: 32 }}
                  value={amountData?.amountTotal ?? 0}
                  precision={2}
                />
              </div>
            </div>
            <div className="fundAnalysisContainer_top__item">
              <div className="fundAnalysisContainer_top__item__text">
                总交易订单
              </div>
              <div className="fundAnalysisContainer_top__item__value">
                {amountData?.orderTotal ?? ' '}
              </div>
            </div>
            <div className="fundAnalysisContainer_top__item">
              <div className="fundAnalysisContainer_top__item__text">
                总商家数
              </div>
              <div className="fundAnalysisContainer_top__item__value">
                {amountData?.merchantTotal ?? ' '}
              </div>
            </div>
            <div className="fundAnalysisContainer_top__item">
              <div className="fundAnalysisContainer_top__item__text">
                总注册用户数
              </div>
              <div className="fundAnalysisContainer_top__item__value">
                {amountData?.createUserTotal ?? ' '}
              </div>
            </div>
          </div>

          <div className="fundAnalysisContainer_center">
            <div className="fundAnalysisContainer_center__title">
              <div className="fundAnalysisContainer_center__title__text">
                交易趋势
              </div>
              <div className="fundAnalysisContainer_center__title__select">
                <DatePicker
                  onChange={handleTimePicker}
                  picker="month"
                  allowClear={false}
                  defaultValue={dayjs()?.format('YYYY-MM')}
                  locale={locale}
                />
              </div>
            </div>

            <div className="fundAnalysisContainer_statistics">
              <div className="statisticsText">
                <div className="statisticsIcon"></div>
                统计
              </div>
              <div className="statisticsItem">
                <div className="statisticsItemIcon"></div>
                订单总数： {amountData?.tradeOrderTotal ?? '-'}
              </div>
              <div className="statisticsItem">
                <div className="statisticsItemIcon"></div>
                交易总金额： {amountData?.tradeAmountTotal ?? '-'}
              </div>
            </div>
            <div
              className="fundAnalysisContainer_center__echarts"
              id="centerEcharts"
            ></div>
          </div>

          <div className="fundAnalysisContainer_bottom">
            <div className="fundAnalysisContainer_bottom__title">交易记录</div>
            <div className="fundAnalysisContainer_statisticsList">
              <div className="statisticsText">
                <div className="statisticsIcon"></div>
                统计
              </div>
              <div className="statisticsItem">
                <div className="statisticsItemIcon"></div>
                订单总数： {statisticsData?.tradeOrderTotal ?? '-'}
              </div>
              <div className="statisticsItem">
                <div className="statisticsItemIcon"></div>
                交易总金额： {statisticsData?.tradeAmountTotal ?? '-'}
              </div>
            </div>
            <VSPromiseTable vsid="90361" onFetch={onFetch}>
              <VSTableColumn
                dataIndex={['orderNo']}
                title="订单号"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['merchantName']}
                title="商家名称"
                valueType="text"
                search
                columnKey={['merchantNameLike']}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['consumeAmount']}
                title="消费金额"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['name']}
                title="用户名称"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['phone']}
                title="手机号"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['paymentMethod']}
                title="支付方式"
                valueType="select"
                dataSource={[
                  { label: '余额支付', value: 'BALANCE' },
                  { label: '微信支付', value: 'WECHAT' },
                ]}
              />

              <VSTableColumn
                dataIndex={['createdAt']}
                title="时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['reserveTime']}
                title="交易时间"
                search
                hideInTable
                order={-75}
                columnKey={'createdAtRangeIn'}
                valueType="dateRange"
                transform={(values) => {
                  if (!Array.isArray(values)) return values;
                  return ['createdAtRangeIn'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    {
                      begin: values[0],
                      end: values[1],
                      beginInclude: true,
                      endInclude: true,
                    },
                  );
                }}
                fieldProps={{
                  format: 'YYYY-MM-DD',
                  valueType: 'string',
                }}
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                      type: 'string',
                    },
                  ],
                }}
              />
            </VSPromiseTable>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(FundAnalysis);
