import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveDataManagementController_updateWyzInformation_a5e4cd: {
    method: 'post',
    url: '/api/reserve-data-management/update-wyz-information',
    parameterFomatter: (data?: {
      btoParam: UpdateWyzInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WyzInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a: {
    method: 'post',
    url: '/api/reserve-data-management/get-all-by-wyz-information-qto',
    parameterFomatter: (data?: {
      qto: WyzInformationQtoReserveDataManagementPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WyzInformationVoReserveDataManagementEntranceWebVo[],
    ) => data,
  },
});
