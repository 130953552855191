import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
moment.locale('zh-cn');
const CampPositionMangePriceForm = (props) => {
  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, 'day').endOf('day');
  };

  return (
    <Section>
      <div className="campNo">
        营位编号: <div style={{ marginLeft: 10 }}>{props?.campNo}</div>
      </div>
      <VSControlledForm
        vsid="67186"
        id="CampPositionPriceForm"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout key="4" columnCount={1} labelWidth={80}>
          {props?.value?.id ? (
            <VSFormItem
              name={['time']}
              label="日期"
              valueType="date"
              rules={[
                {
                  required: true,
                  message: '请选择日期',
                },
              ]}
            />
          ) : (
            <VSFormItem
              name={['time']}
              label="日期"
              valueType="dateRange"
              fieldProps={{
                disabledDate: disabledDate,
              }}
              locale={locale}
              rules={[
                {
                  required: true,
                  message: '请选择日期',
                },
              ]}
              transform={(v) => {
                return {
                  time: {
                    begin: dayjs(v[0]).format('YYYY-MM-DD 00:00:00'),
                    end: dayjs(v[1]).format('YYYY-MM-DD 00:00:00'),
                  },
                };
              }}
            />
          )}

          {/* <VSFormItem
            name={['timeFrame']}
            label="时段"
            valueType="select"
            rules={[
              {
                required: true,
                message: '请选择时段',
              },
            ]}
            dataSource={[
              { label: '过夜', value: 'OVERNIGHT' },
              { label: '不过夜', value: 'NO_OVERNIGHT' },
            ]}
          /> */}
          <VSFormItem
            name={['price']}
            label="价格"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入价格',
              },
              {
                pattern: new RegExp(/^(([1-9]\d*)|\d)(\.\d{1,2})?$/, 'g'),
                message: '请输入正确的金额',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(CampPositionMangePriceForm);
