import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const LearningActivityManagerFrom = (props) => {
  return (
    <Section>
      <VSControlledForm id="learningActivityManagerFrom" value={props?.value}>
        <VSFormLayout key="0" columnCount={1} labelWidth={120}>
          <VSFormItem
            name={['name']}
            label="活动名称"
            valueType="text"
            required
            fieldProps={{
              maxLength: 15,
            }}
          />

          <VSFormItem
            name={['activityTime']}
            label="活动时间"
            valueType="date"
            required
            fieldProps={{}}
          />

          <VSFormItem
            name={['activityEndTime']}
            label="活动结束时间"
            valueType="date"
            required
            fieldProps={{}}
          />

          <VSFormItem
            name={['applyNum']}
            label="报名人数"
            valueType="digit"
            required
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(LearningActivityManagerFrom);
