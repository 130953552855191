import { number } from 'echarts';
import React, { useEffect } from 'react';
// source from https://www.npmjs.com/package/use-force-update
export function useForceUpdate(): () => void {
  const [, dispatch] = React.useState<Record<string, any>>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

export const useHorizontalScroll = (ref) => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (ref) {
        ref.current.scrollLeft += event.deltaY;
      }
    };

    ref?.current?.addEventListener('wheel', handleWheel);
  }, [ref]);
};
export const convertToHttps = (url) => {
  if (url.startsWith('http://')) {
    return 'https://' + url.slice(7);
  }
  return url;
};

export const timeFormat = (stamp?: number | string | Date) => {
  if (!stamp) return '-';
  const time = new Date(stamp);
  const z = (n: number) => (n < 10 ? '0' + n : n);
  return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${z(
    time.getHours(),
  )}:${z(time.getMinutes())}:${z(time.getSeconds())}`;
};

export const timeMap = (begin, end) => {
  const startDate = new Date(begin);
  const endDate = new Date(end);
  const dateList: Array<Date> = [];
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    dateList.push(new Date(date));
  }

  return dateList;
};

export const formatNumber = (num) => {
  let str = String(num);
  if (str.length < 4) {
    while (str.length < 4) {
      str = '0' + str;
    }
  }
  return str;
};

export const getDaysInCurrentMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDay = new Date(year, month, 1);
  const nextMonth = new Date(year, month + 1, 1);
  const daysInMonth: string[] = [];
  let currentDay = firstDay;
  while (currentDay < nextMonth) {
    const yyyy = currentDay.getFullYear();
    const mm = (currentDay.getMonth() + 1).toString().padStart(2, '0');
    const dd = currentDay.getDate().toString().padStart(2, '0');
    const formattedDate = `${yyyy}-${mm}-${dd}`;
    daysInMonth.push(formattedDate);
    currentDay = new Date(currentDay.getTime() + 86400000);
  }

  return daysInMonth;
};

export const hidePhoneNumber = (phoneNumber) => {
  if (phoneNumber?.length !== 11) {
    return phoneNumber;
  }

  const hiddenDigits = '****';
  const start = phoneNumber.slice(0, 3);
  const end = phoneNumber.slice(7);
  return start + hiddenDigits + end;
};
