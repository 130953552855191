import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveDataManagementController_updateCampingInformation_34b9c9: {
    method: 'post',
    url: '/api/reserve-data-management/update-camping-information',
    parameterFomatter: (data?: {
      btoParam: UpdateCampingInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampingInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_getAllByCampingInformationQto_fb9e72: {
    method: 'post',
    url: '/api/reserve-data-management/get-all-by-camping-information-qto',
    parameterFomatter: (data?: {
      qto: CampingInformationQtoReserveDataManagementPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampingInformationVoReserveDataManagementEntranceWebVo[],
    ) => data,
  },
  ReserveDataManagementController_updateWyzInformation_a5e4cd: {
    method: 'post',
    url: '/api/reserve-data-management/update-wyz-information',
    parameterFomatter: (data?: {
      btoParam: UpdateWyzInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WyzInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a: {
    method: 'post',
    url: '/api/reserve-data-management/get-all-by-wyz-information-qto',
    parameterFomatter: (data?: {
      qto: WyzInformationQtoReserveDataManagementPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WyzInformationVoReserveDataManagementEntranceWebVo[],
    ) => data,
  },
  ReserveDataManagementController_createLearningInformation_3c3d69: {
    method: 'post',
    url: '/api/reserve-data-management/create-learning-information',
    parameterFomatter: (data?: {
      btoParam: CreateLearningInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_updateLearningInformation_7c5300: {
    method: 'post',
    url: '/api/reserve-data-management/update-learning-information',
    parameterFomatter: (data?: {
      btoParam: UpdateLearningInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_getAllByLearningInformationQto_fe4ccf: {
    method: 'post',
    url: '/api/reserve-data-management/get-all-by-learning-information-qto',
    parameterFomatter: (data?: {
      qto: LearningInformationQtoReserveDataManagementPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningInformationVoReserveDataManagementEntranceWebVo[],
    ) => data,
  },
  ReserveDataManagementController_updateAidStatus_d5e027: {
    method: 'post',
    url: '/api/reserve-data-management/update-aid-status',
    parameterFomatter: (data?: {
      btoParam: UpdateAidStatusBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AidStatusVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
  ReserveDataManagementController_getAllByAidStatusQto_535164: {
    method: 'post',
    url: '/api/reserve-data-management/get-all-by-aid-status-qto',
    parameterFomatter: (data?: {
      qto: AidStatusQtoReserveDataManagementPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AidStatusVoReserveDataManagementEntranceWebVo[],
    ) => data,
  },
});
