import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveCampInfoController_createCamp_9d1756: {
    method: 'post',
    url: '/api/reserve-camp-info/create-camp',
    parameterFomatter: (data?: {
      btoParam: CreateCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampInfoVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampInfoController_updateCamp_ce8a0d: {
    method: 'post',
    url: '/api/reserve-camp-info/update-camp',
    parameterFomatter: (data?: {
      btoParam: UpdateCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampInfoVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampInfoController_deleteCamp_bf3276: {
    method: 'post',
    url: '/api/reserve-camp-info/delete-camp',
    parameterFomatter: (data?: {
      btoParam: DeleteCampBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ReserveCampInfoController_queryPagedByCampInfoQto_64069a: {
    method: 'post',
    url: '/api/reserve-camp-info/query-paged-by-camp-info-qto',
    parameterFomatter: (data?: {
      qto: CampInfoQtoReserveCampInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CampInfoVoReserveCampInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReservePartitionController_getAllByPubPartitionQto_e25a8c: {
    method: 'post',
    url: '/api/reserve-camp-info/get-all-by-pub-partition-qto',
    parameterFomatter: (data?: {
      qto: PubPartitionQtoReserveCampInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PartitionInfoVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveCampPlanController_getAllByCampPlanListQto_71a42e: {
    method: 'post',
    url: '/api/reserve-camp-info/get-all-by-camp-plan-list-qto',
    parameterFomatter: (data?: {
      qto: CampPlanListQtoReserveCampInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampPlanVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveCampInfoController_deleteListCamp_457182: {
    method: 'post',
    url: '/api/reserve-camp-info/delete-list-camp',
    parameterFomatter: (data?: {
      btoParam: DeleteCampBtoReserveCampInfoServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserAccountController_userPasswordLogin_b21bf2: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/user-password-login',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_createUserPassword_f3a420: {
    method: 'post',
    url: '/api/user-account/create-user-password',
    parameterFomatter: (data?: {
      btoParam: CreateUserPasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
      };
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  UserAccountController_updatePassword_9a5392: {
    method: 'post',
    url: '/api/user-account/update-password',
    parameterFomatter: (data?: {
      btoParam: UpdatePasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
});
