import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveTicketsController_createDateTickets_ad4141: {
    method: 'post',
    url: '/api/reserve-tickets/create-date-tickets',
    parameterFomatter: (data?: {
      btoParam: CreateDateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_createTickets_14e4be: {
    method: 'post',
    url: '/api/reserve-tickets/create-tickets',
    parameterFomatter: (data?: {
      btoParam: CreateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_updateDateTickets_9a814b: {
    method: 'post',
    url: '/api/reserve-tickets/update-date-tickets',
    parameterFomatter: (data?: {
      btoParam: UpdateDateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_updateTickets_923e34: {
    method: 'post',
    url: '/api/reserve-tickets/update-tickets',
    parameterFomatter: (data?: {
      btoParam: UpdateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_batchDeleteTickets_759754: {
    method: 'post',
    url: '/api/reserve-tickets/batch-delete-tickets',
    parameterFomatter: (data?: {
      btoParam: DeleteTicketsBtoReserveTicketsServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ReserveTicketsController_deleteDateTickets_46e8bc: {
    method: 'post',
    url: '/api/reserve-tickets/delete-date-tickets',
    parameterFomatter: (data?: {
      btoParam: DeleteDateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_deleteTickets_ba2f66: {
    method: 'post',
    url: '/api/reserve-tickets/delete-tickets',
    parameterFomatter: (data?: {
      btoParam: DeleteTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_queryPagedByTicketsManageQto_7f3490: {
    method: 'post',
    url: '/api/reserve-tickets/query-paged-by-tickets-manage-qto',
    parameterFomatter: (data?: {
      qto: TicketsManageQtoReserveTicketsPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TicketsVoReserveTicketsEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveTicketsController_getByTicketsId_7e5eaf: {
    method: 'post',
    url: '/api/reserve-tickets/get-by-tickets-id',
    parameterFomatter: (data?: { ticketsId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  OrderResearchLearningController_auditRefundOrder_9168c8: {
    method: 'post',
    url: '/api/order-research-learning/audit-refund-order',
    parameterFomatter: (data?: {
      auditRefundVo: AuditRefundVoOrderResearchLearningEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  OssPolicyController_obsUploadFile_4b9697: {
    method: 'post',
    url: '/api/external-api/upload',
    parameterFomatter: (data?: { file: MultipartFile }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
});
