import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontPageController_queryPagedByFrontPage_f222a8: {
    method: 'post',
    url: '/api/front-page/query-paged-by-front-page',
    parameterFomatter: (data?: {
      qto: FrontPageQtoFrontPagePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: FrontPageVoFrontPageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveMessageController_queryPagedByMessageQto_57e38a: {
    method: 'post',
    url: '/api/reserve-message/query-paged-by-message-qto',
    parameterFomatter: (data?: {
      qto: MessageQtoReserveMessagePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MessagePageVoReserveMessageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveMessageController_getById_febd5b: {
    method: 'post',
    url: '/api/reserve-message/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MessageInfoVoReserveMessageEntranceWebVo,
    ) => data,
  },
  ReserveMessageController_updateMessage_f8fd69: {
    method: 'post',
    url: '/api/reserve-message/update-message',
    parameterFomatter: (data?: {
      btoParam: UpdateMessageBtoReserveMessageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MessagePageVoReserveMessageEntranceWebVo,
    ) => data,
  },
  ReserveMessageController_updateBatchMessage_1877b0: {
    method: 'post',
    url: '/api/reserve-message/update-batch-message',
    parameterFomatter: (data?: {
      btoParam: UpdateMessageBtoReserveMessageServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ReserveMessageController_updateBatchRead_bc08b8: {
    method: 'post',
    url: '/api/reserve-message/update-batch-read',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
