import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  VSPromiseTable,
  VSTableAction,
  Modal,
  Button,
  message,
  VSControlledForm,
  VSFormLayout,
  VSFormItem,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import WorkConsumeForm from '@pages/WorkConsumeForm';
const WorkConsumeList = (props) => {
  const [refresh, setRefresh] = useState();
  const [rows, setRows] = useState([]);
  const [id, setId] = useState();
  const [item, setItem] = useState();
  const [isBatch, setIsBatch] = useState(false);
  const [isWithHold, setIsWithHold] = useState(false);

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.OrderMerchantVoucherController_getPagedByConsumeVoucherQto_d3715a?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
        },
      );
    setRows([]);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handleSubmitRecharge = async () => {
    try {
      const fromParam =
        await vsf?.refs?.rechargeForm?.validateFieldsReturnFormatValue();
      if (isWithHold) {
        if (fromParam?.balance > item.balance) {
          message.warning('扣款金额不得超过当前余额');
        } else {
          const res =
            await vsf.services.OrderMerchantVoucherController_deduction_dd21a4({
              btoParam: {
                id: item?.id,
                ...fromParam,
              },
            });

          if (res && res.code === 200) {
            message.success('扣款成功');
            setTimeout(() => {
              setRefresh(!refresh);
            }, 1000);
            return res?.data;
          }
          return false;
        }
      } else {
        if (isBatch) {
          if (rows?.length <= 0) {
            return message.error('请先选择充值的账号');
          }
          const res =
            await vsf.services.OrderMerchantVoucherController_updateBatchWorkConsume_3270bf(
              {
                btoParamList: rows?.map((item) => ({
                  id: item?.id,
                  ...fromParam,
                })),
              },
            );

          if (res && res.code === 200) {
            message.success('充值成功');
            setTimeout(() => {
              setRefresh(!refresh);
            }, 1000);
            return res?.data;
          }
          return false;
        } else {
          const res =
            await vsf.services.OrderMerchantVoucherController_rechargeWorkVoucher_95302c(
              {
                btoParam: {
                  id: item?.id,
                  ...fromParam,
                },
              },
            );

          if (res && res.code === 200) {
            message.success('充值成功');
            setTimeout(() => {
              setRefresh(!refresh);
            }, 1000);
            return res?.data;
          }
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  };

  const rechargeMoney = (v, params, index) => {
    return (
      <div>
        {params?.rechargeType === 'DEDUCTION_MONEY'
          ? '-' + params?.rechargeMoney
          : params?.rechargeMoney}
      </div>
    );
  };

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };
  return (
    <Section title="集团消费券管理">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        onFetch={onFetch}
        vsid="46713"
        extraParams={{ refresh }}
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
        }}
        addLinkPage={{
          linkPage: () => <WorkConsumeForm />,
          modalProps: {
            title: '新增工作人员账号',
            width: 500,
            okText: '确定',
            cancelText: '取消',
          },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.WorkConsumeForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.OrderMerchantVoucherController_createWorkConsume_c6f4df?.(
                  {
                    btoParam: fromParam,
                    extApiParam: params?.extApiParam,
                  },
                );

              if (res && res?.code === 200) {
                message.success('添加成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['workName']}
          title="用户名称"
          valueType="text"
          search
          order={-1}
          columnKey={'workNameLike'}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['phone']}
          title="手机号"
          valueType="text"
          search
          order={-2}
          columnKey={'phoneIs'}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['balance']}
          title="账户余额"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['consumeMoney']}
          title="累计消费金额"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['rechargeMoney']}
          title="累计充值金额"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          width={230}
          dataIndex={['action']}
          title="操作"
          valueType="option"
        >
          <VSTableAction
            children="充值"
            onClick={(v, vv, params) => {
              setIsBatch(false);
              setIsWithHold(false);
              setItem(vv);
              vsf.openModal('recharge');
            }}
          />
          <VSTableAction
            onClick={(v, vv, params) => {
              setItem(vv);
              setIsBatch(true);
              setIsWithHold(true);
              vsf.openModal('recharge');
            }}
          >
            扣款
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              vsf.openModal('rechargeRecord');
              setId(vv?.id);
            }}
          >
            充值记录
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              setId(vv?.id);
            }}
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: {
                width: 500,
                title: '编辑工作人员账号',
              },
              linkPage: () => <WorkConsumeForm />,
              onLinkPageValidate: async (_, __, record) => {
                try {
                  const fromParam =
                    await vsf?.refs?.WorkConsumeForm?.validateFieldsReturnFormatValue();
                  const res =
                    await vsf.services?.OrderMerchantVoucherController_updateWorkConsume_0a5d20?.(
                      {
                        btoParam: {
                          ...fromParam,
                          id: record.id,
                        },
                        extApiParam: {},
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      setRefresh(!refresh);
                    }, 1000);
                  }
                  return res?.data;
                } catch (error) {
                  return false;
                }
              },
            }}
          >
            编辑
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              Modal.confirm({
                content: '工作人员删除后不可恢复，是否确认删除？',
                icon: '',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  vsf.services
                    .OrderMerchantVoucherController_deleteWorkConsume_81acad({
                      btoParam: {
                        id: vv?.id,
                      },
                    })
                    .then((res) => {
                      if (res?.code === 200) {
                        message.success(`删除成功`);
                        setTimeout(() => {
                          setRefresh(!refresh);
                        }, 500);
                      }
                    });
                },
              });
            }}
          >
            删除
          </VSTableAction>
        </VSTableColumn>

        <VSTableToolbar title="">
          <Button
            children="批量充值"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            disabled={rows?.length <= 0}
            onClick={() => {
              vsf.openModal('recharge');
              setIsBatch(true);
              setIsWithHold(false);
            }}
          />
          <VSTableAddition children="新增" />
        </VSTableToolbar>
      </VSPromiseTable>
      <Modal
        title={
          isBatch && !isWithHold
            ? '批量充值'
            : `${item?.workName ?? ''}-${item?.phone ?? ''}`
        }
        id="recharge"
        width={500}
        onOk={handleSubmitRecharge}
        okText="确定"
        cancelText="取消"
      >
        <VSControlledForm vsid="94767" id="rechargeForm" value={props.value}>
          <VSFormLayout key="0" columnCount={1} labelWidth={80}>
            {isWithHold ? (
              <>
                <VSFormItem
                  name={['balance']}
                  label="扣款金额"
                  valueType="text"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入扣款金额',
                    },
                    {
                      pattern: new RegExp(/^\d+(\.\d+)?$/),
                      message: '请输入正确的提现金额',
                    },
                  ]}
                  fieldProps={{
                    min: 0,
                    maxLength: 5,
                    suffix: <div>元</div>,
                  }}
                />

                <VSFormItem
                  name={['remark']}
                  label="备注"
                  style={{
                    marginLeft: 25,
                  }}
                  valueType="textarea"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入备注',
                    },
                  ]}
                  fieldProps={{
                    maxLength: 250,
                    autoSize: {
                      minRows: 3,
                      maxRows: 4,
                    },
                  }}
                />
              </>
            ) : (
              <>
                <VSFormItem
                  name={['balance']}
                  label="充值金额"
                  valueType="text"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入充值金额',
                    },
                    {
                      pattern: new RegExp(/^\d+(\.\d+)?$/),
                      message: '请输入正确的提现金额',
                    },
                  ]}
                  fieldProps={{
                    min: 0,
                    maxLength: 5,
                    suffix: <div>元</div>,
                  }}
                />
              </>
            )}
          </VSFormLayout>
        </VSControlledForm>
      </Modal>

      <Modal
        title="充值记录"
        id="rechargeRecord"
        width={800}
        okText="确定"
        cancelText="取消"
      >
        <div className="setPriceTable">
          <VSPromiseTable
            editable={{
              editType: 'single',
              columnProps: { hideInTable: true },
            }}
            pagination={false}
            onFetch={async (params) => {
              const res =
                await vsf.services?.OrderMerchantVoucherController_getListRechargeInfoVoByVoucherId_92611f?.(
                  {
                    voucherId: id,
                  },
                );
              return {
                data: res?.data ?? res?.data ?? [],
              };
            }}
          >
            <VSTableColumn
              dataIndex={['name']}
              title="用户名称"
              valueType="text"
            />
            <VSTableColumn
              dataIndex={['phone']}
              title="手机号"
              valueType="text"
            />
            <VSTableColumn
              //   dataIndex={['rechargeMoney']}
              title="充值金额"
              valueType="digit"
              render={rechargeMoney}
            />
            <VSTableColumn
              dataIndex={['rechargeTime']}
              title="充值时间"
              valueType="date"
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm:ss',
                valueType: 'string',
              }}
            />
            <VSTableColumn
              dataIndex={['remark']}
              title="备注"
              valueType="text"
              ellipsis
            />
          </VSPromiseTable>
        </div>
      </Modal>
    </Section>
  );
};
export default definePage(WorkConsumeList);
