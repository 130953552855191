import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserReserveController_queryPagedByUserReserveInfoQto_5c05d1: {
    method: 'post',
    url: '/api/reserve-camp-info/query-paged-by-user-reserve-info-qto',
    parameterFomatter: (data?: {
      qto: UserReserveInfoQtoReserveCampInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: UserReserveInfoVoReserveCampInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserReserveController_updateReserveInfo_cb95f8: {
    method: 'post',
    url: '/api/reserve-camp-info/update-reserve-info',
    parameterFomatter: (data?: {
      btoParam: UpdateReserveInfoBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserReserveInfoVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
});
