import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveDataManagementController_updateCampingInformation_34b9c9: {
    method: 'post',
    url: '/api/reserve-data-management/update-camping-information',
    parameterFomatter: (data?: {
      btoParam: UpdateCampingInformationBtoReserveDataManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampingInformationVoReserveDataManagementEntranceWebVo,
    ) => data,
  },
});
