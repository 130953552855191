import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserAccountController_getAllByUserProfileQto_adf45b: {
    method: 'post',
    url: '/api/user-account/get-all-by-user-profile-qto',
    parameterFomatter: (data?: {
      qto: UserProfileQtoComVsUserAccountPersistQto;
      tab: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto[],
    ) => data,
  },
  UserAccountController_createUserPassword_f3a420: {
    method: 'post',
    url: '/api/user-account/create-user-password',
    parameterFomatter: (data?: {
      btoParam: CreateUserPasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_updatePassword_9a5392: {
    method: 'post',
    url: '/api/user-account/update-password',
    parameterFomatter: (data?: {
      btoParam: UpdatePasswordBtoComVsUserAccountServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_deletePassword_e23731: {
    method: 'post',
    url: '/api/user-account/delete-password',
    parameterFomatter: (data?: {
      btoParam: DeletePasswordBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  UserAccountController_getPageByUserProfileUserQto_ea5f70: {
    method: 'post',
    url: '/api/user-account/get-page-by-user-profile-user-qto',
    parameterFomatter: (data?: {
      qto: UserProfilePageQtoComVsUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: UserProfileDtoComVsUserAccountManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveDayController_createReservePriceList_e20319: {
    method: 'post',
    url: '/api/reserve-camp-info/create-reserve-price-list',
    parameterFomatter: (data?: {
      btoParamList: CreateReservePriceBtoReserveCampInfoServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
});
