import './index.less';

import ActivitiesManage from '@pages/ActivitiesManage';
import ShopManage from '@pages/ShopManage';
import vsf, { definePage } from '@vs/vsf-boot';
import { PageRouterProps } from '@vs/vsf-boot/lib/esm/definePage';
import { Menu } from '@vs/vsf-kit';
import { cloneDeep, flatMapDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import MerchantFundAnalysis from '@/pages/MerchantFundAnalysis';
import OrderMange from '@/pages/OrderMange';
import ResearchLearningMange from '@/pages/ResearchLearningMange';
import lydRoutes from '@/router/lydRoutes';
import wyzRoutes from '@/router/wyzRoutes';
import storage from '@/utils/storage';

const lydMerchant = [
  // 商品管理
  {
    path: '/shopManage',
    component: ShopManage,
    exact: true,
    label: '商品管理',
  },
  {
    // 活动管理
    path: '/activitiesManage',
    component: ActivitiesManage,
    label: '活动管理',
    exact: true,
  },
];

const wyzMerchant = [
  {
    // 研学管理
    path: '/ResearchLearningMange',
    component: ResearchLearningMange,
    exact: true,
    label: '研学管理',
  },
  {
    path: '/OrderMange',
    component: OrderMange,
    exact: true,
    label: '订单管理',
  },
  {
    path: '/MerchantFundAnalysis',
    exact: true,
    component: MerchantFundAnalysis,
    label: '资金管理',
  },
];

type OwnProps = {
  demo?: string;
} & PageRouterProps;
const Left: React.FC<OwnProps> = (props) => {
  const [openKeys, setOpenKeys] = useState<string[]>();
  const [selectedKey, setSelectedKey] = useState<string>();

  useEffect(() => {
    const items = getItems();
    const openKeys = flatMapDeep(items, 'children').map(
      (item) => item.key?.[0],
    );
    setOpenKeys([...openKeys, 'index']);
  }, []);

  const getItems = () => {
    try {
      const routeArr: any =
        storage.get('platform') === 'learning'
          ? cloneDeep(wyzRoutes)
          : storage.get('platform') === 'camp'
            ? cloneDeep(lydRoutes)
            : storage.get('platform') === 'CAMP_STORE'
              ? cloneDeep(lydMerchant)
              : storage.get('platform') === 'LEARNING_STORE'
                ? cloneDeep(wyzMerchant)
                : storage.get('platform') === 'CAMP_ADMIN'
                  ? cloneDeep(lydRoutes)
                  : storage.get('platform') === 'LEARNING_ADMIN'
                    ? cloneDeep(wyzRoutes)
                    : [];

      const tempMenu = routeArr
        ?.filter((item) => item?.label && item.path && !item.hideInMenu)
        ?.map((route) => {
          return {
            key: route?.path,
            label: route?.label,
            // icon: (
            //   <img
            //     style={{ width: '16px', height: '16px' }}
            //     src={
            //       route?.path === '/accountManage'
            //         ? route?.selectIcon
            //         : route?.icon
            //     }
            //     alt=""
            //   />
            // ),
            children: route?.routes
              ?.filter((item) => item?.label)
              ?.map((secRoute) => ({
                key: secRoute?.path,
                label: secRoute?.label,
              })),
          };
        });

      if (tempMenu)
        return [
          {
            label:
              storage.get('platform') === 'learning'
                ? '王营庄数字化'
                : storage.get('platform') === 'camp'
                  ? '露营地数字化'
                  : storage.get('platform') === 'CAMP_ADMIN'
                    ? '露营地数字化'
                    : storage.get('platform') === 'CAMP_STORE'
                      ? '露营地数字化'
                      : storage.get('platform') === 'LEARNING_ADMIN'
                        ? '王营庄数字化'
                        : storage.get('platform') === 'LEARNING_STORE'
                          ? '王营庄数字化'
                          : '',
            key: 'index',
            children: tempMenu,
          },
        ];
      return [];
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSelectedKey(window.location.pathname);
  }, [props.routes, selectedKey]);

  const handelMenuItemClick = (e) => {
    // const _key = e.key;
    // const parentKey = `/${_key?.split('/')?.[1]}`;
    // const parentNode = items?.[0]?.children?.find(
    //   (item) => item.key === parentKey,
    // );
    const tempMenu = cloneDeep(
      storage.get('platform') === 'learning' ? wyzRoutes : lydRoutes,
    );

    // setItems(
    //   items?.map((item) => {
    //     return {
    //       ...item,
    //       children: item?.children?.map((_v) => {
    //         return {
    //           ..._v,
    //           icon:
    //             _v.key === parentNode?.key ? (
    //               <img
    //                 style={{ width: '16px', height: '16px' }}
    //                 src={tempMenu?.find((vv) => vv.path === _v.key)?.selectIcon}
    //                 alt=""
    //               />
    //             ) : (
    //               <img
    //                 style={{ width: '16px', height: '16px' }}
    //                 src={tempMenu?.find((vv) => vv.path === _v.key)?.icon}
    //                 alt=""
    //               />
    //             ),
    //         };
    //       }),
    //     };
    //   }),
    // );

    vsf?.navigateTo(e.key);
  };

  return (
    <Menu
      selectedKeys={
        selectedKey
          ? [selectedKey]
          : storage.get('platform') === 'learning'
            ? ['/BannerManagement']
            : ['/bannerManage']
      }
      openKeys={openKeys}
      onOpenChange={(keys) => setOpenKeys(keys)}
      mode="inline"
      items={getItems()}
      onClick={handelMenuItemClick}
    />
  );
};

export default definePage(Left);
