import BannerManagement from '@pages/BannerManagement';
import LearningUserList from '@pages/learningUserList';
import MerchantManage from '@pages/MerchantManage';

import AccountManage from '@/pages/AccountManage';
import FundAnalysis from '@/pages/FundAnalysis';
import GroupBuyManagerList from '@/pages/GroupBuyManagerList';
import LearningCockpit from '@/pages/learningCockpit';
import learningCockpitManageRoot from '@/pages/learningCockpitManageRoot';
import learningCockpitMenu from '@/pages/learningCockpitMenu';
import MerchantFundAnalysis from '@/pages/MerchantFundAnalysis';
import OrderMange from '@/pages/OrderMange';
import ResearchLearningMange from '@/pages/ResearchLearningMange';
import SystemManagementRoot from '@/pages/systemManagementRoot';
import SystemMangeLogs from '@/pages/SystemMangeLogs';
import TicketsMange from '@/pages/TicketsMange';
import WithdrawalApproval from '@/pages/WithdrawalApproval';
import WorkConsume from '@/pages/WorkConsume';

const routes = [
  {
    // 首页管理
    path: '/BannerManagement',
    component: BannerManagement,
    exact: true,
    label: '首页管理',
  },
  // {
  //   path: '/merchantMangeRoot',
  //   component: MerchantMangeRoot,
  //   label: '商家管理',
  //   routes: [
  //     {
  //       // 研学管理
  //       path: '/merchantMangeRoot/ResearchLearningMange',
  //       component: ResearchLearningMange,
  //       exact: true,
  //       label: '研学管理',
  //     },
  //     {
  //       path: '/merchantMangeRoot/MerchantMange',
  //       exact: true,
  //       component: MerchantMange,
  //       label: '商家资金',
  //     },
  //   ],
  // },
  {
    // 用户管理
    path: '/LearningUserList',
    component: LearningUserList,
    exact: true,
    label: '用户管理',
  },
  {
    // 研学管理
    path: '/ResearchLearningMange',
    component: ResearchLearningMange,
    exact: true,
    label: '研学管理',
  },
  {
    // 门票管理
    path: '/TicketsMange',
    component: TicketsMange,
    exact: true,
    label: '门票管理',
  },
  {
    // 订单管理
    path: '/OrderMange',
    component: OrderMange,
    exact: true,
    label: '订单管理',
  },
  {
    // 商家管理
    path: '/MerchantManage',
    component: MerchantManage,
    exact: true,
    label: '商家管理',
  },
  {
    // 资金管理
    path: '/FundAnalysis',
    component: FundAnalysis,
    exact: true,
    label: '资金管理',
  },

  // {
  //   // 商家资金管理
  //   path: '/MerchantFundAnalysis',
  //   component: MerchantFundAnalysis,
  //   exact: true,
  //   label: '资金管理',
  // },

  {
    // 提现审批记录
    path: '/WithdrawalApproval',
    component: WithdrawalApproval,
    exact: true,
    hideInMenu: true,
    label: '提现审批记录',
  },

  {
    // 集团消费券管理
    path: '/WorkConsume',
    component: WorkConsume,
    exact: true,
    label: '集团消费券管理',
  },

  {
    // 团购管理
    path: '/GroupBuying',
    component: GroupBuyManagerList,
    exact: true,
    label: '团购管理',
  },

  {
    path: '/learningCockpitManage',
    component: learningCockpitManageRoot,
    label: '驾驶舱管理',

    routes: [
      {
        path: '/learningCockpitManage/learningCockpit',
        exact: true,
        component: LearningCockpit,
        label: '驾驶舱',
      },

      {
        path: '/learningCockpitManage/learningCockpitMenu',
        exact: true,
        component: learningCockpitMenu,
        label: '驾驶舱数据管理',
      },
    ],
  },

  {
    path: '/SystemManagement',
    component: SystemManagementRoot,
    label: '系统管理',
    routes: [
      {
        path: '/SystemManagement/AccountManage',
        exact: true,
        component: AccountManage,
        label: '账号管理',
      },
      // {
      //   path: '/SystemManagement/RoleManage',
      //   exact: true,
      //   component: RoleManage,
      //   label: '角色管理',
      // },
      {
        path: '/SystemManagement/SystemMangeLogs',
        exact: true,
        component: SystemMangeLogs,
        label: '系统日志',
      },
    ],
  },
];

export default routes;
