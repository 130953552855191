import { defineStore } from '@vs/vsf-boot';

const message = defineStore({
  isHasReadList: [],

  setIsHasReadList: (list) => {
    message.isHasReadList = list;
  },
});

export default message;
