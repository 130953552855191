import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableAction,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  Button,
  VSPromiseTable,
  message,
  Modal,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import { ReadStatusEnum } from '@/config';
import storage from '@/utils/storage';
const MessageManageTable = (props) => {
  const [dataList, setDataList] = useState([]);
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState();

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };

  const handleIsRead = async () => {
    try {
      if (rows?.length <= 0) {
        return message.error('请先选择要已读的消息');
      }
      const res =
        await vsf.services?.ReserveMessageController_updateBatchMessage_1877b0?.(
          {
            btoParam: rows?.map((item) => ({
              id: item.id,
              readStatus: ReadStatusEnum?.READ,
            })),
          },
        );
      if (res && res.code === 200) {
        message.success('操作成功');
        setTimeout(() => {
          setRefresh(!refresh);
        }, 500);
      }
    } catch (error) {}
  };

  const handleAllIsRead = async () => {
    try {
      Modal.confirm({
        content: '是否确认全部已读？',
        icon: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          vsf.services
            .ReserveMessageController_updateBatchRead_bc08b8({})
            .then((res) => {
              if (res && res.code === 200) {
                message.success('操作成功');
                vsf.stores.message?.setIsHasReadList([]);
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 500);
              }
            });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveMessageController_queryPagedByMessageQto_57e38a?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    setRows([]);
    setDataList(res?.data?.result ?? res?.data ?? []);
    vsf.stores.message?.setIsHasReadList(
      res?.data?.result?.filter((item) => item?.readStatus !== 'READ'),
    );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <Section title="消息列表">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        vsid="18584"
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
          getCheckboxProps: (row) => ({
            disabled: row?.readStatus === 'READ',
          }),
        }}
        onFetch={onFetch}
        extraParams={{ refresh }}
      >
        <VSTableColumn
          dataIndex={['title']}
          title="标题"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '标题长度不合法', type: 'string', min: 0, max: 1024 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['sendTime']}
          title="发送时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          width={200}
          dataIndex={['action']}
          title="操作"
          valueType="option"
        >
          <VSTableAction
            onClick={(v, record) =>
              vsf.navigateTo(`/MessageManageDetail/${record.id}`)
            }
          >
            查看
          </VSTableAction>
        </VSTableColumn>

        <VSTableToolbar title="">
          <Button
            children="标为已读"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            onClick={() => {
              handleIsRead();
            }}
          />
          <Button
            children="全部标为已读"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            onClick={() => {
              handleAllIsRead();
            }}
          />
        </VSTableToolbar>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(MessageManageTable);
