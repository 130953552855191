import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  VSPromiseTable,
  message,
  Image,
  Breadcrumb,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import { UsedStatusEnum } from '@/config';
import PackageManageForm from '@pages/PackageManageForm';
import styled from 'styled-components';

const ManageContainer = styled.div`
  overflow: hidden;
  width: 70px;
  height: 70px;
`;
const PackageManagementTable = (props) => {
  const renderPlanCoverImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.planCover ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '5px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveCampPlanController_queryPagedByCampPlanQto_9f3c6a?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <Section>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>营地管理</Breadcrumb.Item>
          <Breadcrumb.Item>套餐管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '套餐删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
        }}
        vsid="10322"
        onFetch={onFetch}
        onRemove={async (params) => {
          const res =
            await vsf.services?.ReserveCampPlanController_deleteCampPlan_a9697c?.(
              {
                btoParam: params,
                extApiParam: params?.extApiParam,
              },
            );
          if (res && res?.code === 200) {
            message.success('删除成功');
          }
          return res?.code === 200;
        }}
        addLinkPage={{
          linkPage: () => <PackageManageForm />,
          modalProps: { width: 520, title: '新增套餐' },
          onLinkPageValidate: async (params) => {
            try {
              const formParam =
                await vsf.refs.PackageManageForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.ReserveCampPlanController_createCampPlan_1cf65e?.(
                  {
                    btoParam: {
                      ...params,
                      ...formParam,
                      campPlanConfigBtoList: formParam?.campPlanConfigDtoList,
                    },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('添加成功');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <PackageManageForm />,
          modalProps: { width: 520, title: '编辑套餐' },
          onLinkPageValidate: async (params) => {
            try {
              const formParam =
                await vsf.refs.PackageManageForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.ReserveCampPlanController_updateCampPlan_d8cb5e?.(
                  {
                    btoParam: {
                      ...params,
                      ...formParam,
                      campPlanConfigBtoList: formParam?.campPlanConfigDtoList,
                    },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('编辑成功');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['planName']}
          title="套餐名称"
          valueType="text"
          search
          order={-56}
          columnKey={'planNameLike'}
          formItemProps={{
            rules: [
              {
                message: '套餐名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['planCover']}
          title="套餐封面"
          valueType="text"
          fieldProps={{}}
          render={renderPlanCoverImg}
        />

        {/* <VSTableColumn
          dataIndex={['planStatus']}
          title="状态"
          valueType="select"
          search
          order={-57}
          columnKey={'planStatusIs'}
          dataSource={[
            { label: '可用', value: 'ENABLE' },
            { label: '不可用', value: 'DISABLE' },
          ]}
          fieldProps={{}}
        /> */}

        <VSTableColumn
          dataIndex={['planPrice']}
          title="套餐价格"
          valueType="text"
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['campPlanConfigDtoList', 'configName']}
          title="配置名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '配置名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        /> */}

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableToolbar title="">
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(PackageManagementTable);
