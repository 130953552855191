import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const WorkConsumeForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="99473"
        id="WorkConsumeForm"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout key="1" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['workName']}
            label="用户名称"
            valueType="text"
            required={true}
            rules={[
              {
                required: true,
                message: '请输入用户名称',
              },
            ]}
            fieldProps={{
              maxLength: 10,
            }}
          />

          <VSFormItem
            name={['phone']}
            label="手机号"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: new RegExp(/^1[3-9]\d{9}$/),
                message: '请输入正确的手机号',
              },
            ]}
            fieldProps={{
              maxLength: 11,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(WorkConsumeForm);
