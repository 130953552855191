import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAction,
  VSTableAddition,
  message,
  Button,
  Modal,
  Breadcrumb,
  TextArea,
  Radio,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import MerchantManageForm from '@/pages/MerchantManageForm';
import styled from 'styled-components';

const ManageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const MerchantManageList = (props) => {
  const merchantId = props?.routes?.query?.id;
  const [refresh, setRefresh] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [refundDetail, setRefundDetail] = useState({});
  const [value, setValue] = useState(1);
  const [textValue, setTextValue] = useState();
  const rederIndex = (_, __, index) => {
    return (
      <ManageContainer>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </ManageContainer>
    );
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.OrderMerchantApprovalController_getPagedByApprovalQto_5db8bb?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            merchantIdIs: merchantId ?? undefined,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handleSubmit = async () => {
    if (!textValue && value !== 1) {
      message.warning('请填写驳回原因');
    } else {
      try {
        const res =
          await vsf.services?.OrderMerchantApprovalController_approval_8a4284?.(
            {
              btoParam: {
                approval: value,
                reason: textValue,
                merchantId: refundDetail?.merchantId,
                id: refundDetail?.id,
                withdrawalAmount: refundDetail?.withdrawalAmount,
              },
            },
          );

        if (res && res?.code === 200) {
          message.success('审批成功');
          vsf.closeModal('refundModal');
          setTimeout(() => {
            setRefresh(!refresh);
          }, 1500);
        }
        return false;
      } catch (e) {
        return false;
      }
    }
  };
  return (
    <Section>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              merchantId
                ? vsf.navigateTo(`/MerchantFundAnalysis`)
                : vsf.navigateTo(`/FundAnalysis`);
            }}
          >
            资金管理
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {merchantId ? '提现记录' : '提现审批记录'}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <VSPromiseTable
        editable={{
          editType: 'single',
          columnProps: { hideInTable: true },
        }}
        vsid="77857"
        extraParams={{ refresh }}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />
        <VSTableColumn
          dataIndex={['merchantName']}
          title="商家名称"
          valueType="text"
          search={!merchantId}
          order={-1}
          columnKey={'merchantNameLike'}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['linkman']}
          title="联系人"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['phone']}
          title="手机号"
          valueType="text"
          search={!merchantId}
          order={-2}
          columnKey={'phoneIs'}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['withdrawalAmount']}
          title="提现金额"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['status']}
          title="状态"
          valueType="select"
          search
          order={-3}
          columnKey={'statusIs'}
          dataSource={[
            { label: '待处理', value: 'PENDING' },
            { label: '已通过', value: 'PROCESSED' },
            { label: '已驳回', value: 'REJECT' },
          ]}
          fieldProps={{}}
        />

        {merchantId ? (
          <VSTableColumn dataIndex={['reason']} title="原因" valueType="text" />
        ) : (
          ''
        )}

        {merchantId ? (
          <VSTableColumn
            dataIndex={['time']}
            title="申请时间"
            search={merchantId ? true : false}
            hideInTable
            order={1}
            columnKey={'timeRangeIn'}
            valueType="dateRange"
            fieldProps={{
              format: 'YYYY-MM-DD',
              valueType: 'string',
            }}
            searchConfig={{
              transform: (values) => {
                if (!Array.isArray(values)) return values;
                return ['timeRangeIn'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  {
                    begin: values[0],
                    end: values[1],
                    beginInclude: true,
                    endInclude: true,
                  },
                );
              },
            }}
          />
        ) : (
          ''
        )}

        <VSTableColumn
          dataIndex={['time']}
          title="申请时间"
          hideInForm={merchantId}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        {merchantId ? (
          <VSTableColumn
            dataIndex={['reviewTime']}
            title="审批时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />
        ) : (
          ''
        )}
        {merchantId ? (
          ''
        ) : (
          <VSTableColumn
            width={200}
            dataIndex={['action']}
            title="操作"
            valueType="option"
          >
            <VSTableAction
              show={(v) => v.status === 'PENDING'}
              onClick={(v, vv, params) => {
                vsf.openModal('refundModal');
                setTimeout(() => {
                  setRefundDetail(vv);
                }, 1);
              }}
            >
              提现审批
            </VSTableAction>
          </VSTableColumn>
        )}
      </VSPromiseTable>

      <Modal
        title="提现审批"
        id="refundModal"
        width={500}
        onOk={handleSubmit}
        okText="确定"
        cancelText="取消"
      >
        <div className="refundDetail">
          <div className="refundBox">
            <div className="refundText">商家名称</div>
            <div className="refundValue">
              {refundDetail?.merchantName ?? ''}
            </div>
          </div>

          <div className="refundBox">
            <div className="refundText">联系人</div>
            <div className="refundValue">{refundDetail?.linkman ?? ''}</div>
          </div>

          <div className="refundBox">
            <div className="refundText">手机号</div>
            <div className="refundValue">{refundDetail?.phone ?? ''}</div>
          </div>

          <div className="refundBox">
            <div className="refundText">提现金额</div>
            <div className="refundValue">
              ¥{refundDetail?.withdrawalAmount ?? ''}
            </div>
          </div>

          <div className="refundBox">
            <div className="refundText"> </div>
            <div className="refundValue">
              <Radio.Group
                onChange={(e) => {
                  setValue(e);
                }}
                dataSource={[
                  {
                    label: '通过',
                    value: 1,
                  },
                  {
                    label: '驳回',
                    value: 0,
                  },
                ]}
                value={value}
              ></Radio.Group>
            </div>
          </div>

          {value === 0 && (
            <>
              <div className="refundBox">
                <div className="refundText">原因</div>
                <div className="refundValue">
                  {' '}
                  <TextArea
                    rows={4}
                    value={textValue}
                    onChange={(e) => {
                      setTextValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </Section>
  );
};
export default definePage(MerchantManageList);
