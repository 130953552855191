import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useRef } from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const TicketsForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="21556"
        id="TicketsMangeForm"
        value={{
          ...props.value,
          studentDiscount:
            props?.value?.studentDiscount.toString().length > 1
              ? props?.value?.studentDiscount / 10
              : props?.value?.studentDiscount,
          outStudentDiscount:
            props?.value?.outStudentDiscount.toString().length > 1
              ? props?.value?.outStudentDiscount / 10
              : props?.value?.outStudentDiscount,
        }}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout key="1" columnCount={1} labelWidth={120}>
          <VSFormItem
            name={['ticketsNo']}
            label="门票编号"
            valueType="text"
            readonly={props?.value?.id}
            required
            rules={[
              {
                required: true,
                message: '请输入门票编号',
              },
            ]}
            fieldProps={{
              maxLength: 10,
            }}
          />

          <VSFormItem
            name={['ticketsName']}
            label="门票名称"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入门票名称',
              },
            ]}
            fieldProps={{
              maxLength: 250,
            }}
          />

          <VSFormItem
            name={['ticketsCover']}
            label="门票封面"
            valueType="fileUpload"
            required
            rules={[{ required: true, message: '请上传门票封面' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  ticketsCover: v,
                };
              }
              return {
                ticketsCover: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                accept: '.jpg,.png,.jpeg',
                maxCount: 6,
              },
            }}
          />
          <div className="suggessSize" style={{ marginLeft: '120px' }}>
            建议尺寸：400 * 400
          </div>

          <VSFormItem
            name="introduction"
            label="介绍"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写介绍' }]}
          />

          {/* <VSFormItem
            name={['introduction']}
            label="景点介绍"
            valueType="fileUpload"
            required
            rules={[{ required: true, message: '请上传景点介绍' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  introduction: v,
                };
              }
              return {
                introduction: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              uploadProps: {
                accept: '.jpg,.png,.jpeg',
                maxCount: 6,
              },
            }}
          /> */}

          <VSFormItem
            name={['adultPrice']}
            label="成人票价格"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入成人票价格',
              },
              {
                pattern: new RegExp(/^(([1-9]\d*)|\d)(\.\d{1,2})?$/, 'g'),
                message: '请输入正确的金额',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{
              maxLength: 4,
              min: 0,
            }}
          />

          <VSFormItem
            name={['studentDiscount']}
            label="普通学生票折扣"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入普通学生票折扣',
              },
              {
                pattern: new RegExp(/^(?:10(?:\.0)?|[0-9](?:\.[0-9])?)$/),
                message: '请输入正确的普通学生票折扣',
              },
            ]}
            fieldProps={{
              // maxLength: 2,
              min: 0,
              suffix: <div>折</div>,
            }}
          />

          <VSFormItem
            name={['outStudentDiscount']}
            label="外地学生票折扣"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入外地学生票折扣',
              },
              {
                pattern: new RegExp(/^(?:10(?:\.0)?|[0-9](?:\.[0-9])?)$/),
                message: '请输入正确的外地学生票折扣',
              },
            ]}
            fieldProps={{
              // maxLength: 2,
              min: 0,

              suffix: <div>折</div>,
            }}
          />

          <VSFormItem
            name={['maxCapacity']}
            label="最大客容量"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入最大客容量',
              },
            ]}
            fieldProps={{
              maxLength: 5,
              min: 1,
            }}
          />
          <VSFormItem
            name={['audit']}
            label="退款需审核"
            required
            valueType="select"
            rules={[{ required: true, message: '请选择退款是否需要审核' }]}
            dataSource={[
              { label: '是', value: 1 },
              { label: '否', value: 0 },
            ]}
            fieldProps={{}}
          />
          {props?.value?.id && (
            <>
              <VSFormItem
                name={['status']}
                label="状态"
                valueType="select"
                rules={[
                  {
                    required: true,
                    message: '请输入最大客容量',
                  },
                ]}
                style={{ marginLeft: 65 }}
                dataSource={[
                  { label: '已上架', value: 'PUTAWAY' },
                  { label: '已下架', value: 'OFFLINE' },
                ]}
              />
            </>
          )}
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(TicketsForm);
