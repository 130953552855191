import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const MerchantManageForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="85020"
        id="MerchantManageForm"
        value={props.value}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout key="1" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['merchantName']}
            label="商家名称"
            required={true}
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入商家名称',
              },
            ]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['linkman']}
            label="联系人"
            valueType="text"
            required={true}
            rules={[
              {
                required: true,
                message: '请输入联系人',
              },
            ]}
            fieldProps={{
              maxLength: 10,
            }}
          />

          <VSFormItem
            name={['phone']}
            label="手机号"
            valueType="text"
            required={true}
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: new RegExp(/^1[3-9]\d{9}$/),
                message: '请输入正确的手机号',
              },
            ]}
            fieldProps={{
              maxLength: 11,
            }}
          />

          <VSFormItem
            name={['status']}
            label="状态"
            required={true}
            valueType="select"
            initialValue={'ENABLE'}
            rules={[{ required: true, message: '请选择状态' }]}
            dataSource={[
              { label: '在用', value: 'ENABLE' },
              { label: '停用', value: 'DISABLE' },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(MerchantManageForm);
