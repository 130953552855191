import AccountManage from '@pages/AccountManage';
import ActivitiesManage from '@pages/ActivitiesManage';
import AppointmentManage from '@pages/AppointmentManage';
import BannerManage from '@pages/BannerManage';
import BannerManagement from '@pages/BannerManagement';
import CampingManage from '@pages/CampingManage';
import CampPositionManage from '@pages/CampPositionManage';
import HelpingManage from '@pages/HelpingManage';
import InfomationManage from '@pages/InfomationManage';
import LearningUserList from '@pages/learningUserList';
import Logs from '@pages/Logs';
import MerchantManage from '@pages/MerchantManage';
import MessageManage from '@pages/MessageManage';
import PackageManagement from '@pages/PackageManagement';
import Service from '@pages/Service';
import ShopManage from '@pages/ShopManage';
import TagManage from '@pages/TagManage';
import UserList from '@pages/UserList';

import campingManage from '@/assets/images/icon/campManage.png';
import setting from '@/assets/images/icon/setting.png';
import AccountManagement from '@/pages/AccountManagement';
import CampOrder from '@/pages/campOrder';
import Cockpit from '@/pages/Cockpit';
import CockpitManageRoot from '@/pages/CockpitManageRoot';
import CockpitMenu from '@/pages/CockpitMenu';
import FundAnalysis from '@/pages/FundAnalysis';
import GroupBuyManagerList from '@/pages/GroupBuyManagerList';
import LearningCockpit from '@/pages/learningCockpit';
import learningCockpitManageRoot from '@/pages/learningCockpitManageRoot';
import learningCockpitMenu from '@/pages/learningCockpitMenu';
import Login from '@/pages/Login';
import MerchantFundAnalysis from '@/pages/MerchantFundAnalysis';
import MessageManageDetail from '@/pages/MessageManageDetail';
import OrderMange from '@/pages/OrderMange';
import PartitionManageRoot from '@/pages/partitionManageRoot';
import ResearchLearningMange from '@/pages/ResearchLearningMange';
import RoleManage from '@/pages/RoleManage';
import SystemManagementRoot from '@/pages/systemManagementRoot';
import SystemManageRoot from '@/pages/systemManageRoot';
import SystemMangeLogs from '@/pages/SystemMangeLogs';
import TicketsMange from '@/pages/TicketsMange';
import WithdrawalApproval from '@/pages/WithdrawalApproval';
import WorkConsume from '@/pages/WorkConsume';

const routes = [
  {
    path: '/Login',
    exact: true,
    component: Login,
  },
  {
    // 首页管理
    path: '/bannerManage',
    component: BannerManage,
    exact: true,
    label: '首页管理',
  },
  {
    // 用户管理
    path: '/userList',
    component: UserList,
    exact: true,
    label: '用户管理',
  },
  {
    // 预约管理
    path: '/appointmentManage',
    component: AppointmentManage,
    exact: true,
    label: '预约管理',
  },
  // 商品管理
  {
    path: '/shopManage',
    component: ShopManage,
    exact: true,
    label: '商品管理',
  },
  {
    // 订单管理
    path: '/CampOrder',
    component: CampOrder,
    exact: true,
    label: '订单管理',
  },
  {
    // 活动管理
    path: '/activitiesManage',
    component: ActivitiesManage,
    label: '活动管理',
    exact: true,
  },

  {
    // 资讯管理
    path: '/InfomationManage',
    component: InfomationManage,
    label: '资讯管理',
    exact: true,
  },

  {
    // 援助管理
    path: '/helpingManage',
    component: HelpingManage,
    exact: true,
    label: '援助管理',
  },
  {
    // 消息管理
    path: '/messageManage',
    component: MessageManage,
    exact: true,
    label: '通知管理',
  },
  {
    // 消息管理
    path: '/MessageManageDetail/:id',
    component: MessageManageDetail,
    exact: true,
    hideInMenu: true,
    label: '消息详情',
  },
  {
    path: '/service',
    component: Service,
    exact: true,
  },
  {
    path: '/CockpitManage',
    component: CockpitManageRoot,
    label: '驾驶舱管理',
    // icon: campingManage,
    routes: [
      {
        path: '/CockpitManage/Cockpit',
        exact: true,
        component: Cockpit,
        label: '驾驶舱',
      },

      {
        path: '/CockpitManage/CockpitMenu',
        exact: true,
        component: CockpitMenu,
        label: '驾驶舱数据管理',
      },
    ],
  },

  // {
  //   // 标记管理
  //   path: '/tagManage',
  //   component: TagManage,
  //   exact: true,
  //   label: '标记管理',
  // },
  {
    path: '/PartitionManage',
    component: PartitionManageRoot,
    label: '营地管理',
    icon: campingManage,
    routes: [
      {
        path: '/PartitionManage/campingManage',
        exact: true,
        component: CampingManage,
        label: '分区管理',
      },
      {
        path: '/PartitionManage/campPositionManage',
        exact: true,
        component: CampPositionManage,
        label: '营位管理',
      },
      {
        path: '/PartitionManage/packageManagement',
        exact: true,
        component: PackageManagement,
        label: '套餐管理',
      },
    ],
  },
  {
    path: '/SystemManage',
    component: SystemManageRoot,
    label: '系统管理',
    icon: setting,
    routes: [
      {
        path: '/SystemManage/accountManage',
        exact: true,
        component: AccountManage,
        label: '账号管理',
      },
      {
        path: '/SystemManage/logs',
        exact: true,
        component: Logs,
        label: '系统日志',
      },
    ],
  },

  {
    path: '/SystemManagement',
    component: SystemManagementRoot,
    label: '系统管理',
    icon: setting,
    routes: [
      {
        path: '/SystemManagement/accountManage',
        exact: true,
        component: AccountManage,
        label: '账号管理',
      },
      {
        path: '/SystemManagement/RoleManage',
        exact: true,
        component: RoleManage,
        label: '角色管理',
      },
      {
        path: '/SystemManagement/SystemMangeLogs',
        exact: true,
        component: SystemMangeLogs,
        label: '系统日志',
      },
    ],
  },

  {
    // 用户管理
    path: '/LearningUserList',
    component: LearningUserList,
    exact: true,
    label: '用户管理',
  },
  {
    // 研学管理
    path: '/ResearchLearningMange',
    component: ResearchLearningMange,
    exact: true,
    label: '研学管理',
  },
  {
    // 门票管理
    path: '/TicketsMange',
    component: TicketsMange,
    exact: true,
    label: '门票管理',
  },
  {
    // 首页管理
    path: '/BannerManagement',
    component: BannerManagement,
    exact: true,
    label: '首页管理',
  },

  {
    path: '/learningCockpitManage',
    component: learningCockpitManageRoot,
    label: '驾驶舱管理',

    routes: [
      {
        path: '/learningCockpitManage/learningCockpit',
        exact: true,
        component: LearningCockpit,
        label: '驾驶舱',
      },

      {
        path: '/learningCockpitManage/learningCockpitMenu',
        exact: true,
        component: learningCockpitMenu,
        label: '驾驶舱数据管理',
      },
    ],
  },

  {
    // 订单管理
    path: '/OrderMange',
    component: OrderMange,
    exact: true,
    label: '订单管理',
  },
  {
    // 商家管理
    path: '/MerchantManage',
    component: MerchantManage,
    exact: true,
    label: '商家管理',
  },

  {
    // 资金管理
    path: '/FundAnalysis',
    component: FundAnalysis,
    exact: true,
    label: '资金管理',
  },
  {
    // 商家资金管理
    path: '/MerchantFundAnalysis',
    component: MerchantFundAnalysis,
    exact: true,
    label: '资金管理',
  },

  {
    // 提现审批记录
    path: '/WithdrawalApproval',
    component: WithdrawalApproval,
    exact: true,
    hideInMenu: true,
    label: '提现审批记录',
  },
  {
    // 集团消费券管理
    path: '/WorkConsume',
    component: WorkConsume,
    exact: true,
    label: '集团消费券管理',
  },
  {
    // 团购管理
    path: '/GroupBuying',
    component: GroupBuyManagerList,
    exact: true,
    label: '团购管理',
  },
];

export default routes;
