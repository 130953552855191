import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useRef } from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const ResearchLearnForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="80023"
        id="ResearchLearnForm"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout key="1" columnCount={1} labelWidth={120}>
          <VSFormItem
            name={['name']}
            label="活动名称"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入活动名称',
              },
            ]}
            fieldProps={{
              maxLength: 250,
            }}
          />

          <VSFormItem
            name={['coverImg']}
            label="活动封面"
            required
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传活动封面' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  coverImg: v,
                };
              }
              return {
                coverImg: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize" style={{ marginLeft: '120px' }}>
            建议尺寸：400 * 500
          </div>
          <VSFormItem
            name="activityDesc"
            label="活动详情"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写活动详情' }]}
          />

          {/* <VSFormItem
            name={['activityDesc']}
            label="活动详情"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传活动详情图片' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  activityDesc: v,
                };
              }
              return {
                activityDesc: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,

              uploadProps: {
                maxCount: 6,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          /> */}

          <VSFormItem
            name={['activityPlace']}
            label="活动场馆"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入活动场馆',
              },
            ]}
            fieldProps={{
              maxLength: 250,
            }}
          />
          <VSFormItem
            name={['price']}
            label="价格"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入价格',
              },
              {
                pattern: new RegExp(/^(([1-9]\d*)|\d)(\.\d{1,2})?$/, 'g'),
                message: '请输入正确的金额',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />

          <VSFormItem
            name={['personCount']}
            label="人数"
            valueType="digit"
            rules={[{ required: true, message: '请输入人数' }]}
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />

          <VSFormItem
            name={['activityStartTime']}
            label="活动开始时间"
            valueType="date"
            rules={[{ required: true, message: '请选择活动开始时间' }]}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm:ss',
              showTime: true,
              placeholder: '请选择活动开始时间',
            }}
          />

          <VSFormItem
            name={['activityEndTime']}
            label="活动结束时间"
            valueType="date"
            rules={[{ required: true, message: '请选择活动结束时间' }]}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm:ss',
              showTime: true,
              placeholder: '请选择活动结束时间',
            }}
          />
          <VSFormItem
            name={['isRefundCheck']}
            label="退款需审核"
            required
            valueType="select"
            rules={[{ required: true, message: '请选择退款是否需要审核' }]}
            dataSource={[
              { label: '是', value: 'IS_CHECK' },
              { label: '否', value: 'NO_CHECK' },
            ]}
            fieldProps={{}}
          />
          {/* {props?.value?.id && (
            <>
              <VSFormItem
                name={['publishStatus']}
                label="状态"
                required
                valueType="select"
                style={{ marginLeft: 58 }}
                rules={[{ required: true, message: '请选择状态' }]}
                dataSource={[
                  { label: '已上架', value: 'PUBLISH_ON' },
                  { label: '已下架', value: 'PUBLISH_DOWN' },
                  { label: '已售罄', value: 'SELL_OUT' },
                ]}
                fieldProps={{}}
              />
            </>
          )} */}
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(ResearchLearnForm);
