import './FundAnalysis.less';
import vsf, { definePage } from '@vs/vsf-boot';
import * as echarts from 'echarts';
import {
  Section,
  DatePicker,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  VSControlledForm,
  VSFormLayout,
  VSFormItem,
  message,
  Button,
  Modal,
  Statistic,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import storage from '@/utils/storage';
moment.locale('zh-cn');

const FundAnalysis = (props) => {
  const [merchantInfo, setMerchantInfo] = useState();

  const loadMerchantInfo = useCallback(async () => {
    const data = JSON.parse(storage.get('userData') ?? '{}');
    if (data?.phone) {
      const res =
        await vsf.services?.OrderMerchantController_getMerchantInfoVoByPhone_cb949b?.(
          {
            phone: data?.phone,
          },
        );
      if (res && res.code === 200) {
        setMerchantInfo(res.data);
      }
    }
  }, []);

  useEffect(() => {
    loadMerchantInfo();
  }, [loadMerchantInfo]);

  const onFetch = useCallback(async (params) => {
    if (params?.extra.merchantIdIs) {
      const res =
        await vsf.services?.OrderMerchantController_getPagedByTransactionRecordQto_43ab71?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
            },
            ext: {},
          },
        );

      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, []);

  const handleSubmitRecharge = async () => {
    try {
      const fromParam =
        await vsf?.refs?.rechargeForm?.validateFieldsReturnFormatValue();
      if (fromParam?.withdrawalAmount > merchantInfo.balance) {
        message.warning('提现金额不得超过当前余额');
      } else {
        const res = await vsf.services.OrderMerchantController_withdraw_62855a({
          btoParam: {
            merchantId: merchantInfo?.id,
            ...fromParam,
          },
        });

        if (res && res.code === 200) {
          message.success('申请提现成功');
          return res?.data;
        }
      }
    } catch (error) {}
  };
  return (
    <Section
      title="资金管理"
      style={{
        position: 'relative',
      }}
    >
      <div className="fundAnalysisTitle">
        <div className="fundAnalysisTop">
          <div className="fundAnalysisTop__name">账户余额（元）</div>
          <div
            className="fundAnalysisTop__record"
            onClick={() => {
              vsf.navigateTo(`/WithdrawalApproval?id=${merchantInfo?.id}`);
            }}
          >
            提现记录
          </div>
        </div>
        <div className="fundAnalysisBottom">
          <div className="fundAnalysisTop__value">
            <Statistic value={merchantInfo?.balance} precision={2} />
          </div>
          <div className="fundAnalysisTop__btn">
            <Button
              children="申请提现"
              style={{
                backgroundColor: '#37927b',
                color: 'white',
                height: '38px',
                borderRadius: '6px',
                fontWeight: 'bold',
              }}
              onClick={() => {
                vsf.openModal('recharge');
              }}
            />
          </div>
        </div>
      </div>
      <div className="fundAnalysisContainer">
        <div className="fundAnalysisContainer_content">
          <div className="fundAnalysisContainer_bottom">
            <div className="fundAnalysisContainer_bottom__title">交易记录</div>
            <VSPromiseTable
              vsid="90361"
              extraParams={{
                merchantIdIs: merchantInfo?.id,
              }}
              onFetch={onFetch}
            >
              <VSTableColumn
                dataIndex={['orderNo']}
                title="订单号"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['name']}
                title="用户名称"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['phone']}
                title="手机号"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['consumeAmount']}
                title="消费金额"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['paymentMethod']}
                title="支付方式"
                valueType="select"
                dataSource={[
                  { label: '余额支付', value: 'BALANCE' },
                  { label: '微信支付', value: 'WECHAT' },
                ]}
              />

              <VSTableColumn
                dataIndex={['createdAt']}
                title="时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />
            </VSPromiseTable>

            <Modal
              title={'申请提现'}
              id="recharge"
              width={500}
              onOk={handleSubmitRecharge}
              okText="确定"
              cancelText="取消"
            >
              <VSControlledForm
                vsid="94767"
                id="rechargeForm"
                value={props.value}
              >
                <VSFormLayout key="0" columnCount={1} labelWidth={80}>
                  <VSFormItem
                    name={['withdrawalAmount']}
                    label="提现金额"
                    valueType="text"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: '请输入提现金额',
                      },
                      {
                        pattern: new RegExp(/^\d+(\.\d+)?$/),
                        message: '请输入正确的提现金额',
                      },
                    ]}
                    fieldProps={{
                      min: 0,
                      maxLength: 5,
                      suffix: <div>元</div>,
                    }}
                  />
                  <div className="accountBalance">
                    <div className="accountBalanceText">
                      账号余额：
                      <Statistic
                        style={{
                          marginTop: -10,
                          marginRight: 10,
                        }}
                        value={merchantInfo?.balance}
                        precision={2}
                      />{' '}
                      元
                    </div>
                    <div
                      className="accountBalanceBtn"
                      onClick={() => {
                        setTimeout(() => {
                          vsf.refs.rechargeForm?.setFieldsValue({
                            withdrawalAmount: merchantInfo?.balance,
                          });
                        }, 1);
                      }}
                    >
                      全部
                    </div>
                  </div>
                </VSFormLayout>
              </VSControlledForm>
            </Modal>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default definePage(FundAnalysis);
