import './CampingManage.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  Button,
  Input,
  Image,
  Row,
  Col,
  message,
  Modal,
  Breadcrumb,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Empty } from 'antd';
import { UsedStatusEnum, UsedStatusEnumValue } from '@/config';
import CampingMangeEditForm from '@pages/CampingMangeEditForm';

const CampingManage = (props) => {
  const [campingList, setCampingList] = useState();
  const [partitionNameLike, setPartitionNameLike] = useState();
  const [isSearch, setIsSearch] = useState();

  const [type, setType] = useState();
  const [id, setId] = useState();
  useEffect(async () => {
    const res = await vsf.services
      ?.ReservePartitionController_queryPagedByPartitionInfoQto_4bf9a9?.({
        qto: {
          from: 0,
          size: 10,
          partitionNameLike: isSearch ?? '',
        },
        ext: {},
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setCampingList(resposne?.data?.result);
        }
      });
  }, [isSearch]);

  const handleChange = (type, item) => {
    vsf.openModal('addModal');
    setType(type);
    setId(item?.id);
    if (type === 'edit') {
      const value = {
        ...item,
        partitionImg: [
          {
            url: JSON.parse(item?.partitionImg)?.join(),
          },
        ],
      };
      setTimeout(() => {
        vsf.refs.CampingMangeEditForm?.setFieldsValue(item);
      }, 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const values =
        (await vsf.refs.CampingMangeEditForm?.validateFieldsReturnFormatValue()) ??
        {};
      if (type === 'edit') {
        const res =
          await vsf.services.ReservePartitionController_updatePartition_eb22ef({
            btoParam: {
              ...values,
              id: id,
            },
          });
        if (res.data && res.code === 200) {
          message.success('编辑分区成功');
          setTimeout(() => {
            vsf.services
              ?.ReservePartitionController_queryPagedByPartitionInfoQto_4bf9a9?.(
                {
                  qto: {
                    from: 0,
                    size: 10,
                    partitionNameLike: '',
                  },
                  ext: {},
                },
              )
              .then((resposne) => {
                if (resposne && resposne?.code === 200) {
                  setCampingList(resposne?.data?.result);
                }
              });
          }, 1000);

          return true;
        }
      } else {
        const res =
          await vsf.services.ReservePartitionController_createPartition_c4b511({
            btoParam: {
              ...values,
            },
          });

        if (res.data && res.code === 200) {
          message.success('新增分区成功');
          setTimeout(() => {
            vsf.services
              ?.ReservePartitionController_queryPagedByPartitionInfoQto_4bf9a9?.(
                {
                  qto: {
                    from: 0,
                    size: 10,
                    partitionNameLike: '',
                  },
                  ext: {},
                },
              )
              .then((resposne) => {
                if (resposne && resposne?.code === 200) {
                  setCampingList(resposne?.data?.result);
                }
              });
          }, 1000);
          return true;
        }
      }

      return false;
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      content: '分区删除后不可恢复，是否确认删除？',
      icon: '',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        vsf.services
          .ReservePartitionController_deletePartition_c13a6f({
            btoParam: {
              id,
            },
          })
          .then((res) => {
            if (res?.code === 200) {
              message.success(`删除分区成功`);
              vsf.services
                ?.ReservePartitionController_queryPagedByPartitionInfoQto_4bf9a9?.(
                  {
                    qto: {
                      from: 0,
                      size: 1000,
                      partitionNameLike: '',
                    },
                    ext: {},
                  },
                )
                .then((resposne) => {
                  if (resposne && resposne?.code === 200) {
                    setCampingList(resposne?.data?.result);
                  }
                });
            }
          });
      },
    });
  };
  return (
    <Section>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>营地管理</Breadcrumb.Item>
          <Breadcrumb.Item>分区管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="campingManage_Container">
        <div className="campingManage_Container__title">
          <div className="campingManage_Container__search">
            <div>
              {' '}
              分区名称：
              <Input
                placeholder="请输入分区名称"
                width={150}
                style={{ marginLeft: '10px' }}
                value={partitionNameLike}
                onChange={(e) => {
                  setPartitionNameLike(e?.target?.value);
                }}
              ></Input>
            </div>
            <div className="campingManage_Container__btn">
              <Button
                children="重置"
                className="campingManage_btn"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                }}
                onClick={() => {
                  setIsSearch(undefined);
                  setPartitionNameLike(undefined);
                }}
              ></Button>
              <Button
                children="查询"
                className="campingManage_btn"
                style={{
                  backgroundColor: '#37927b',
                  color: 'white',
                }}
                onClick={() => {
                  setIsSearch(partitionNameLike);
                }}
              ></Button>
            </div>
          </div>
        </div>

        <div className="campingManage_Container__AddBtn">
          <Button
            children="新增"
            onClick={() => handleChange('add')}
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
          ></Button>
        </div>
        {campingList?.length > 0 ? (
          <>
            <div className="campingManage_Container__Content">
              <div className="campingManage_Container__Content__box">
                <Row gutter={[32, 16]} id="campingList">
                  {(campingList ?? [])?.map((item, index) => (
                    <Col span={8} key={index}>
                      <div className="campingManage_Container__Content__item">
                        <div className="campingManage_left">
                          <Image
                            className="campingManage_itemImg"
                            src={JSON.parse(item?.partitionImg) ?? null}
                            preview={false}
                          />
                        </div>
                        <div className="campingManage_right">
                          <div className="campingManage_partitionName">
                            {item?.partitionName ?? ''}
                          </div>

                          <div className="campingManage_status">
                            <div
                              className={
                                item?.partitionStatus === UsedStatusEnum.ENABLE
                                  ? 'campingManage_status__icon'
                                  : 'campingManage_status__stop'
                              }
                            ></div>
                            <div className="campingManage_status__text">
                              {item?.partitionStatus === UsedStatusEnum.ENABLE
                                ? UsedStatusEnumValue.ENABLE
                                : UsedStatusEnum.DISABLE
                                  ? UsedStatusEnumValue?.DISABLE
                                  : ''}
                            </div>
                          </div>
                          <div>
                            <div className="campingManage_Button">
                              <Button
                                children="编辑"
                                className="campingManage_btn"
                                style={{
                                  backgroundColor: '#37927b',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  handleChange('edit', item);
                                }}
                              ></Button>
                            </div>

                            <div className="campingManage_Button">
                              <Button
                                children="删除"
                                className="campingManage_btn"
                                style={{
                                  backgroundColor: '#D6D7D9',
                                  color: 'balck',
                                  opacity: 1,
                                }}
                                onClick={() => {
                                  handleDelete(item?.id);
                                }}
                              ></Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />
        )}

        <Modal
          title={type === 'edit' ? '编辑分区' : '新增分区'}
          id="addModal"
          width={500}
          onOk={handleSubmit}
          okText="确定"
          cancelText="取消"
        >
          <CampingMangeEditForm />
        </Modal>
      </div>
    </Section>
  );
};
export default definePage(CampingManage);
