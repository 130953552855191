import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Switch } from '@vs/vsf-kit';
import * as echarts from 'echarts';
import React, { useEffect, useState, useRef } from 'react';
import { ScrollBoard, WaterLevelPond } from '@jiaminghi/data-view-react';
import titleBar from '@/assets/images/Cockpit/learningTitle.png';
import dayjs from 'dayjs';

import { getDaysInCurrentMonth, hidePhoneNumber } from '@/utils';

const Cockpit = (props) => {
  const [messageList, setMessageList] = useState([]);
  const [campPieData, setCampPieData] = useState([]);
  const [activityData, setActivityData] = useState({});
  const [ticketInfo, setTicketInfo] = useState({});
  const [userDataInfo, setUserDataInfo] = useState({});
  const [orderData, setOrderData] = useState({});
  const [monthData, setMonthData] = useState();
  const [monthOrderData, setMonthOrderData] = useState();

  const [change, setIsChange] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const timer = useRef();

  const loadAllServiceData = () => {
    loadUserInfo();
    loadTicketInfo();
    loadOrderInfo();
    loadLogsData();
    loadOrderMonth();
    loadCampPieData();
    loadActivityData();
    loadMonthData();
    loadMonthOrderData();
  };

  const loadMonthData = () => {
    vsf?.services
      ?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.({
        qto: {
          modelIs: 'MONTH_DATA',
        },
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setMonthData({
            monthTotal: resposne?.data.find(
              (item) => item.name === '月度新增用户',
            )?.value,
            monthOrder: resposne?.data.find(
              (item) => item.name === '月度新增订单',
            )?.value,
          });
        }
      });
  };

  const loadMonthOrderData = () => {
    vsf?.services
      ?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.({
        qto: {
          modelIs: 'ORDER_DATA',
        },
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setMonthOrderData({
            monthOrder: resposne?.data.find((item) => item.name === '本月订单')
              ?.value,
            totalOrder: resposne?.data.find((item) => item.name === '订单总数')
              ?.value,
            todayOrder: resposne?.data.find((item) => item.name === '今日订单')
              ?.value,
            weekOrder: resposne?.data.find((item) => item.name === '本周订单')
              ?.value,
          });
        }
      });
  };

  const loadCampPieData = () => {
    if (change) {
      vsf?.services
        ?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.({
          qto: {
            modelIs: 'VENUE',
          },
        })
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            const data = resposne?.data?.map((item) => {
              return {
                ticketsName: item?.name,
                percentage: `${item?.value}%`,
              };
            });
            setCampPieData(data);
            setTimeout(() => {
              loadPieEcharts(data);
            }, 1);
          }
        });
    } else {
      vsf?.services
        ?.ReserveTicketsController_queryTicketsReal_fadf2a?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setCampPieData(resposne?.data);
            setTimeout(() => {
              loadPieEcharts(resposne?.data);
            }, 1);
          }
        });
    }
  };

  const loadActivityData = () => {
    if (change) {
      vsf?.services
        ?.ReserveDataManagementController_getAllByLearningInformationQto_fe4ccf?.(
          {
            qto: {},
          },
        )
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            const renamedData = resposne?.data?.map((item) => ({
              ...item,
              activityName: item?.name,
              activityNum: item?.applyNum,
              activityTime: `${item?.activityTime?.replace(
                /-/g,
                '.',
              )}-${item?.activityEndTime?.replace(/-/g, '.')}`,
            }));

            const activityInfo = renamedData?.filter(
              (item) => item?.status === true,
            );
            const notActivityInfo = renamedData?.filter(
              (item) => item?.status === false,
            );

            const data = {
              activityInfo: activityInfo,
              underwayNum: activityInfo?.length,
              notActivityInfo: notActivityInfo,
              notStartNum: notActivityInfo?.length,
            };

            setActivityData(data);
          }
        });
    } else {
      vsf?.services
        ?.ReserveResearchLearningController_queryResearchScreen_d63aea?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setActivityData(resposne?.data);
          }
        });
    }
  };
  const loadUserInfo = async () => {
    if (change) {
      const initialData =
        await vsf?.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
          {
            qto: {
              modelIs: 'USER_DATA',
            },
          },
        );

      if (initialData && initialData?.code === 200) {
        const data = {
          screenUserTotal: initialData?.data.find(
            (item) => item.name === '总数',
          )?.value,
          todayOrderUser: initialData?.data.find(
            (item) => item.name === '今日下单数',
          )?.value,
          addUser: initialData?.data.find((item) => item.name === '新增用户数')
            ?.value,

          userInfo: [
            {
              nickname: '用户c362c',
              gender: 'MAN',
              phone: '15217843525',
            },
            {
              nickname: '用户a392c',
              gender: 'WOMAN',
              phone: '13851561465',
            },
            {
              nickname: '用户z372c',
              gender: 'WOMAN',
              phone: '15846541215',
            },
            {
              nickname: '用户t332c',
              gender: 'MAN',
              phone: '15259846565',
            },
            {
              nickname: '用户y312c',
              gender: 'WOMAN',
              phone: '13755462535',
            },
            {
              nickname: '用户h332c',
              gender: 'WOMAN',
              phone: '13845431258',
            },
            {
              nickname: '用户j322c',
              gender: 'MAN',
              phone: '18654135785',
            },
          ],
        };
        setUserDataInfo(data);
      }
    } else {
      const res = await vsf?.services
        ?.UserAccountController_queryLearningScreen_0fe423?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setUserDataInfo(resposne?.data);
          }
        });
    }
  };

  const loadOrderInfo = async () => {
    if (change) {
      const respose =
        await vsf?.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
          {
            qto: {
              modelIs: 'CURRENT_YEAR',
            },
          },
        );

      if (respose && respose?.code === 200) {
        const renamedData = respose?.data?.map((item) => ({
          ...item,
          month: item.name,
          num: item.value,
        }));

        const data = {
          everyMonthOrder: renamedData,
        };

        setOrderData(data);
        setTimeout(() => {
          loadOrderEcharts(data);
        }, 1);
      }
    } else {
      const res = await vsf?.services
        ?.OrderResearchLearningController_queryLearningOrderScreen_e773d4?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setOrderData(resposne?.data);
            loadOrderEcharts(resposne?.data);
          }
        });
    }
  };

  const loadTicketInfo = async () => {
    if (change) {
      const respose =
        await vsf?.services?.ReserveDataManagementController_getAllByWyzInformationQto_ba6f9a?.(
          {
            qto: {
              modelIs: 'TICKETS',
            },
          },
        );

      if (respose && respose?.code === 200) {
        const renamedData = respose?.data?.map((item) => ({
          ...item,
          ticketsType: item.name,
          percentage: item.value + '%',
        }));

        const data = {
          ticketsPercentage: renamedData,
        };

        setTimeout(() => {
          loadTicketEcharts(data);
        }, 1);
      }
    } else {
      const res = await vsf?.services
        ?.ReserveTicketsController_queryTicketsScreen_1d7078?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            loadTicketEcharts(resposne?.data);
            setTicketInfo(resposne?.data);
          }
        });
    }
  };

  const loadOrderMonth = async () => {
    const res = await vsf?.services
      ?.OrderInfoController_queryOrderMonth_e2db8d?.({})
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          loadInformationEcharts(resposne?.data, getDaysInCurrentMonth());
        }
      });
  };

  useEffect(() => {
    loadAllServiceData();
    timer.current = setInterval(
      () => {
        loadAllServiceData();
      },
      24 * 60 * 60 * 1000,
    );
    return () => {
      clearInterval(timer.current);
    };
  }, [change]);
  const loadInformationEcharts = (orderMonth, monthDate) => {
    const monthData = [];
    monthDate?.forEach((item, index) => {
      monthData.push([
        item.split('-')[2],
        orderMonth?.orderMonthInfo?.find((v) => v?.day === item)?.orderNum ??
          '',
      ]);
    });
    const chartDom = document.getElementById('information_data_echarts');
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption({
        tooltip: {
          axisPointer: {
            type: 'axis',
            backgroundColor: '#007186',
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '30%'],
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#013A3F',
            },
          },
          interval: 100,
        },
        visualMap: {
          type: 'piecewise',
          show: false,
          dimension: 0,
          seriesIndex: 0,
          pieces: [
            {
              gt: 1,
              lt: 3,
              color: '#004051',
            },
            {
              gt: 5,
              lt: 7,
              color: '#002832',
            },
          ],
        },
        series: [
          {
            type: 'line',
            showSymbol: true,
            symbol: 'circle',
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              color: '#33F2FF',
              width: 4,
            },
            itemStyle: {
              normal: {
                borderColor: '#33F2FF', // 拐点边框颜色
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0.4,
                  color: '#003d4e',
                },
                {
                  offset: 1,
                  color: '#002833',
                },
              ]),
            },

            data: monthData,
          },
        ],
      });
    }
  };

  const loadTicketEcharts = (orderData) => {
    const dataList = orderData?.ticketsPercentage?.map((item) => {
      return {
        name: item?.ticketsType,
        value: Number(item?.percentage.replace(/%/g, '')),
      };
    });

    const chartDom = document.getElementById('rightBottomEcharts');

    if (chartDom) {
      const myChart = echarts.init(chartDom);

      myChart.setOption({
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          top: '15%',
          left: '50%',
          formatter: function (name) {
            const data = dataList;
            const item = data.find((d) => d.name === name);
            return `{name|${item?.name}}{time|${item?.value} %}`;
          },
          textStyle: {
            rich: {
              name: {
                fontSize: 12,
                color: '#D6D7D9',
                width: 50,
              },
              time: {
                fontSize: 15,
                color: '#33F2FF',

                padding: [0, 0, 0, 30],
              },
            },
          },
          itemGap: 20,
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            data: dataList,
            center: ['25%', '50%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },

            color: ['#00FFEA', '#2651FF', '#54A1F6', '#E68657'],
          },
        ],
      });
    }
  };

  const loadOrderEcharts = (orderData) => {
    const chartDom = document.getElementById('rightCenterEcharts');

    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: change
            ? orderData?.everyMonthOrder?.map((item) => item.month)
            : orderData?.everyMonthOrder?.map((item) => item.month + '月'),
        },
        yAxis: {
          type: 'value',
          splitLine: { show: false },
        },
        label: {
          show: true,
          position: 'top',
          color: '#FFFFFF',
        },
        series: [
          {
            data: orderData?.everyMonthOrder?.map((item) => item.num),
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#4CFF64',
                  },
                  {
                    offset: 1,
                    color: '#00B1BE',
                  },
                ]),
              },
            },
          },
        ],
      });
    }
  };

  const loadLogsData = () => {
    if (change) {
      const data = [
        {
          content: '修改了用户信息',
          userAccount: '用户183fbc',
          createdAt: dayjs().format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '新增了研学活动',
          userAccount: '用户123fbc',
          createdAt: dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '编辑了首页Banner',
          userAccount: '用户44fbc',
          createdAt: dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '新增了门票',
          userAccount: '用户6fbc',
          createdAt: dayjs().subtract(2, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '新增了首页Banner',
          userAccount: '用户555fbc',
          createdAt: dayjs().subtract(2, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '删除了研学活动',
          userAccount: '用户22fbc',
          createdAt: dayjs().subtract(3, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '编辑了首页Banner',
          userAccount: '用户36fbc',
          createdAt: dayjs().subtract(4, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '修改了研学活动',
          userAccount: '用户1fbc',
          createdAt: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm'),
        },
      ];
      setMessageList(data);
    } else {
      const res = vsf?.services
        ?.LogsOperationController_queryPagedByOperationLogsIdxQto_93a253?.({
          qto: {
            from: 0,
            size: 1000,
            projectTypeIs: 'learning',
          },
          ext: {},
        })
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setMessageList(resposne?.data?.result);
          }
        });
    }
  };

  const loadPieEcharts = (pieData) => {
    pieData?.forEach((item, index) => {
      const Id = 'information_item_echarts' + index;
      const chartDom = document.getElementById(Id);
      if (chartDom) {
        const myChart = echarts.init(chartDom);
        myChart.setOption({
          series: [
            {
              type: 'pie',
              radius: ['40', '50'],
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: Number(item?.percentage.replace(/%/g, '')),
                  selected: false,
                  itemStyle: {
                    color: '#33F2FF',
                    borderWidth: 2,
                    borderRadius: 25,
                  },
                  label: {
                    show: true,
                    position: 'center',
                    fontSize: 22,
                    formatter: function (params) {
                      return `{name|${index + 1}号营地}\n{time|${
                        params.value
                      } %}`;
                    },
                    rich: {
                      name: {
                        fontSize: 14,
                        color: '#33F2FF',
                      },
                      time: {
                        fontSize: 16,
                        color: '#33F2FF',
                        height: 30,
                      },
                    },
                  },
                },
                {
                  value: 100 - Number(item?.percentage.replace(/%/g, '')),
                  name: '',
                  itemStyle: {
                    color: '#003845',
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
          ],
        });
      }
    });
  };

  return (
    <div className="learningCockpitContainer">
      <div className="learningCockpitContainer_title">
        <img
          src={titleBar}
          className="learningCockpitContainer_title__img"
          alt=""
        />
        <div
          className="setIcon"
          onClick={() => {
            setIsShow(!isShow);
          }}
        >
          设置
        </div>
        {isShow && (
          <>
            <div className="setIsChange">
              <div className="changeName">切换为测试数据</div>
              <div className="changeDio">
                <Switch
                  defaultValue={change}
                  onChange={(checked) => {
                    setIsChange(checked);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="learningCockpitContainer_content">
        <div className="learningCockpitContainer_content__leftBorder">
          <div className="learningCockpitContainer_content__leftCon">
            <div className="learningCockpitContainer_content__leftCon__title">
              <div className="learningCockpitContainer_content__leftCon__text">
                用户信息
              </div>
            </div>

            <div className="learningCockpitContainer_content__leftCon__box">
              <div className="learningCockpitContainer_content__boxTitle">
                <div className="learningCockpitContainer_content__boxTitle__Box">
                  <div className="learningCockpitContainer_content__boxTitle__item">
                    <div className="content_titleBox">
                      <div className="content_titleBox__value">
                        {userDataInfo?.screenUserTotal ?? 0}
                      </div>
                      <div className="content_titleBox__text">总数</div>
                    </div>
                  </div>

                  <div className="learningCockpitContainer_content__boxTitle__item">
                    <div className="content_titleBox">
                      <div className="content_titleBox__value">
                        {userDataInfo?.todayOrderUser ?? 0}
                      </div>
                      <div className="content_titleBox__text">今日下单数</div>
                    </div>
                  </div>

                  <div className="learningCockpitContainer_content__boxTitle__item">
                    <div className="content_titleBox">
                      <div className="content_titleBox__value">
                        {userDataInfo?.addUser ?? 0}
                      </div>
                      <div className="content_titleBox__text">新增用户数</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="learningCockpitContainer_content__list">
                {userDataInfo?.userInfo && (
                  <>
                    <ScrollBoard
                      config={{
                        header: [' 昵称', '性别', '手机号码'],
                        data: (userDataInfo?.userInfo ?? [])?.map((item) => [
                          item?.nickname ?? '',
                          item?.gender === 'MAN'
                            ? '男'
                            : item?.gender === 'WOMAN'
                              ? '女'
                              : '未知',
                          hidePhoneNumber(item?.phone) ?? '',
                        ]),
                        rowNum: 6,
                        oddRowBGC: 'transparent',
                        evenRowBGC: 'transparent',
                        headerBGC: '#004346',
                        headerHeight: 30,
                        align: ['center', 'center', 'center'],
                        hoverPause: false,
                      }}
                      style={{ width: '100%', height: '33vh' }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="learningCockpitContainer_content__bottomCon">
            <div className="learningCockpitContainer_content__bottomCon__title">
              <div className="learningCockpitContainer_content__bottomCon__text">
                系统日志
              </div>
            </div>

            <div className="learningCockpitContainer_content__bottomCon__content">
              <div className="learningCockpitContainer_content__bottomCon__content__title">
                <div className="learningCockpitContainer_content__bottomCon__content__title__item">
                  事件明细
                </div>
                <div className="learningCockpitContainer_content__bottomCon__content__title__item">
                  操作人员
                </div>
                <div className="learningCockpitContainer_content__bottomCon__content__title__item">
                  日期和时间
                </div>
              </div>
              <div className="learningCockpitContainer_content__bottomCon__content__img">
                {' '}
              </div>
              <div className="learningCockpitContainer_content__bottomCon__content__list">
                <ScrollBoard
                  config={{
                    header: ['', '', ''],
                    data: (messageList ?? [])?.map((item) => [
                      item?.content ?? '',
                      item?.userAccount ?? '',
                      item?.createdAt ?? '',
                    ]),
                    rowNum: 6,
                    oddRowBGC: 'transparent',
                    evenRowBGC: 'transparent',
                    headerBGC: 'transparent',
                    headerHeight: 30,
                    align: ['center', 'center', 'center'],
                    hoverPause: false,
                  }}
                  style={{ width: '100%', height: '33vh' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="learningCockpitContainer_content__centerBorder">
          <div className="learningCockpitContainer_content__centerCon">
            <div className="learningCockpitContainer_content__centerCon__titleCon">
              <div className="learningCockpitContainer_content__centerCon__titleCon__item">
                <div className="learningCockpitContainer_content__centerCon__titleCon__item__value">
                  {change ? monthData?.monthTotal : userDataInfo?.monthTotal}
                </div>
                <div className="learningCockpitContainer_content__centerCon__titleCon__item__text">
                  月度新增用户
                </div>
              </div>
              <div className="learningCockpitContainer_content__centerCon__titleCon__item">
                <div className="learningCockpitContainer_content__centerCon__titleCon__item__value">
                  {change ? monthData?.monthOrder : orderData?.monthOrder}
                </div>
                <div className="learningCockpitContainer_content__centerCon__titleCon__item__text">
                  月度新增订单
                </div>
              </div>
            </div>

            <div className="learningCockpitContainer_content__centerCon__dataCon">
              <div className="learningCockpitContainer_content__centerCon__dataCon__box">
                <div className="learningCockpitContainer_content__centerCon__dataCon__box__title">
                  场馆实时数据
                </div>
              </div>
              <div className="learningCockpitContainer_content__centerCon__dataCon__bottom">
                <div className="learningCockpitContainer_content__centerCon__dataCon__bottom__item">
                  {(campPieData ?? []).map((item, index) => {
                    return (
                      <>
                        <div>
                          <div className="learningCockpitContainer_content__centerCon__dataCon__bottom__item__title">
                            {item.ticketsName}
                          </div>
                          <WaterLevelPond
                            config={{
                              data: [
                                Number(item?.percentage.replace(/%/g, '')),
                              ],
                            }}
                            colors={[]}
                            style={{ width: '5vw', height: '12vh' }}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="learningCockpitContainer_content__centerCon__dataCon__footer">
                {' '}
              </div>
            </div>
          </div>
          <div className="learningCockpitContainer_content__centerBottomCon">
            <div className="learningCockpitContainer_content__centerBottomCon__item">
              <div className="learningCockpitContainer_content__centerBottomCon__item__title">
                <div className="learningCockpitContainer_content__centerBottomCon__item__title__text">
                  进行中的活动数：
                </div>
                <div className="learningCockpitContainer_content__centerBottomCon__item__title__value">
                  {activityData?.underwayNum ?? 0}
                </div>
              </div>
              <div className="learningCockpitContainer_content__centerBottomCon__item__list">
                <ScrollBoard
                  config={{
                    header: [' ', '', ''],
                    data: (activityData?.activityInfo ?? [])?.map((item) => [
                      item?.activityTime ?? '',
                      item?.activityName ?? '',
                      item?.activityNum,
                    ]),
                    rowNum: 4,
                    oddRowBGC: 'transparent',
                    headerBGC: 'transparent',
                    evenRowBGC: 'transparent',
                    headerHeight: 0,
                    align: ['left', 'center', 'center'],
                    hoverPause: false,
                  }}
                  style={{ width: '100%', height: '14vh' }}
                />
              </div>
            </div>
            <div className="learningCockpitContainer_content__centerBottomCon__item">
              <div className="learningCockpitContainer_content__centerBottomCon__item__title">
                <div className="learningCockpitContainer_content__centerBottomCon__item__title__text">
                  未开始的活动数：
                </div>
                <div className="learningCockpitContainer_content__centerBottomCon__item__title__value">
                  {activityData?.notStartNum ?? 0}
                </div>
              </div>
              <div className="learningCockpitContainer_content__centerBottomCon__item__list">
                <ScrollBoard
                  config={{
                    header: [' ', '', ''],
                    data: (activityData?.notActivityInfo ?? [])?.map((item) => [
                      item?.activityTime ?? '',
                      item?.activityName ?? '',
                      item?.activityNum,
                    ]),
                    rowNum: 3,
                    oddRowBGC: 'transparent',
                    evenRowBGC: 'transparent',
                    headerBGC: 'transparent',
                    headerHeight: 0,
                    align: ['left', 'center', 'center'],
                    hoverPause: false,
                  }}
                  style={{ width: '100%', height: '14vh' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="learningCockpitContainer_content__rightBorder">
          <div className="learningCockpitContainer_content__rightCon">
            <div className="learningCockpitContainer_content__rightCon__title">
              <div className="learningCockpitContainer_content__rightCon__text">
                订单信息
              </div>
            </div>

            <div className="learningCockpitContainer_content__rightCon__box">
              <div className="learningCockpitContainer_content__rightCon__box__item">
                <div className="learningCockpitContainer_content__rightCon__box__item__value">
                  {change
                    ? monthOrderData?.totalOrder ?? 0
                    : orderData?.totalOrder ?? 0}
                </div>
                <div className="learningCockpitContainer_content__rightCon__box__item__text">
                  订单总数
                </div>
              </div>

              <div className="learningCockpitContainer_content__rightCon__box__item">
                <div className="learningCockpitContainer_content__rightCon__box__item__value">
                  {change
                    ? monthOrderData?.todayOrder ?? 0
                    : orderData?.todayOrder ?? 0}
                </div>
                <div className="learningCockpitContainer_content__rightCon__box__item__text">
                  今日订单
                </div>
              </div>

              <div className="learningCockpitContainer_content__rightCon__box__item">
                <div className="learningCockpitContainer_content__rightCon__box__item__value">
                  {change
                    ? monthOrderData?.weekOrder ?? 0
                    : orderData?.weekOrder ?? 0}
                </div>
                <div className="learningCockpitContainer_content__rightCon__box__item__text">
                  本周订单
                </div>
              </div>

              <div className="learningCockpitContainer_content__rightCon__box__item">
                <div className="learningCockpitContainer_content__rightCon__box__item__value">
                  {change
                    ? monthOrderData?.monthOrder ?? 0
                    : orderData?.monthOrder ?? 0}
                </div>
                <div className="learningCockpitContainer_content__rightCon__box__item__text">
                  本月订单
                </div>
              </div>
            </div>
          </div>
          <div className="learningCockpitContainer_content__rightCenterCon">
            <div className="learningCockpitContainer_content__rightCenterCon__title">
              <div className="learningCockpitContainer_content__rightCenterCon__text">
                本年订单信息
              </div>
            </div>

            <div
              className="learningCockpitContainer_content__rightCenterCon__echarts"
              id="rightCenterEcharts"
            ></div>
          </div>

          <div className="learningCockpitContainer_content__rightBottomCon">
            <div className="learningCockpitContainer_content__rightBottomCon__title">
              <div className="learningCockpitContainer_content__rightBottomCon__text">
                门票信息
              </div>
            </div>

            <div
              className="learningCockpitContainer_content__rightBottomCon__echarts"
              id="rightBottomEcharts"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default definePage(Cockpit);
