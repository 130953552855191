import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserAccountController_createUserPassword_f3a420: {
    method: 'post',
    url: '/api/user-account/create-user-password',
    parameterFomatter: (data?: {
      btoParam: CreateUserPasswordBtoComVsUserAccountServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  UserAccountController_updatePassword_9a5392: {
    method: 'post',
    url: '/api/user-account/update-password',
    parameterFomatter: (data?: {
      btoParam: UpdatePasswordBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
