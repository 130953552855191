import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
  Image,
  Modal,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import ActivitiesForm from '@pages/ActivitiesForm';
import styled from 'styled-components';

const ManageContainer = styled.div`
  overflow: hidden;
  width: 70px;
  height: 70px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const ManageImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  margin-right: 8px;
`;
const ActivitiesManageTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [richText, SetRichText] = useState(String);
  const [refresh, setRefresh] = useState();
  const rederIndex = (_, __, index) => {
    return (
      <Container>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </Container>
    );
  };

  const renderWatchText = (_, record) => {
    try {
      return (
        <Container>
          <div
            className="watchBtn"
            onClick={() => {
              vsf.openModal('preview');
              SetRichText(record?.activityText);
            }}
          >
            预览内容
          </div>
        </Container>
      );
    } catch (error) {
      console.error(error);
    }
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.FrontActivityController_queryPagedByActivityPage_1a5f37?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderManageImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.activityImg ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '5px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Section title="活动管理">
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '活动删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
        }}
        vsid="44446"
        onFetch={onFetch}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        extraParams={{ refresh }}
        onRemove={async (params) => {
          const res =
            await vsf.services?.FrontActivityController_deleteActivity_3ae619?.(
              {
                btoParam: params,
                extApiParam: params?.extApiParam,
              },
            );
          if (res && res?.code === 200) {
            message.success('删除成功');
            setTimeout(() => {
              setRefresh(!refresh);
            }, 1000);
          }
          return res?.code === 200;
        }}
        addLinkPage={{
          linkPage: () => <ActivitiesForm />,
          modalProps: { width: 670, title: '新增活动' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.ActivitiesForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.FrontActivityController_createActivity_dcbef3?.(
                  {
                    btoParam: fromParam,
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('添加成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <ActivitiesForm />,
          modalProps: { width: 670, title: '编辑活动' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.ActivitiesForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.FrontActivityController_updateActivity_1e37ce?.(
                  {
                    btoParam: { ...fromParam, id: params?.id },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('编辑成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />

        <VSTableColumn
          dataIndex={['actName']}
          title="活动名称"
          valueType="text"
          search
          columnKey={'actNameLike'}
          formItemProps={{
            rules: [
              {
                message: '活动名称长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['activityImg']}
          title="活动图片"
          valueType="text"
          render={renderManageImg}
        />

        <VSTableColumn
          dataIndex={['activityText']}
          title="活动内容"
          render={renderWatchText}
        />

        {/* <VSTableColumn
          dataIndex={['activityUrl']}
          title="跳至页面"
          valueType="text"
          fieldProps={{}}
          ellipsis
        /> */}

        <VSTableToolbar title="">
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>

      <Modal id="preview" width={600} okText="" cancelText="" footer={null}>
        {richText && (
          <>
            <div className="modalWatch_container__text">
              <div
                dangerouslySetInnerHTML={{ __html: richText }}
                className="bannerText"
              ></div>
            </div>
          </>
        )}
      </Modal>
    </Section>
  );
};
export default definePage(ActivitiesManageTable);
