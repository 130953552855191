import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Select,
  message,
} from '@vs/vsf-kit';
import React, { useState, useRef, useEffect } from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

const PackageManageForm = (props) => {
  return (
    <Section>
      <VSControlledForm vsid="79755" id="PackageManageForm" value={props.value}>
        <VSFormLayout key="2" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['planName']}
            label="套餐名称"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请填写套餐名称',
                type: 'string',
                min: 0,
                max: 20,
              },
            ]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['planCover']}
            label="套餐封面"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传套餐封面' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  planCover: v,
                };
              }
              return {
                planCover: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                // maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize">建议尺寸：400 * 400</div>
          <VSFormItem
            name="campPlanConfigDtoList"
            label="套餐配置"
            valueType="campPlanSet"
            required
            fieldProps={{
              selectProps: {
                id: props?.value?.id,
                fieldNames: { value: 'id', label: 'commodityName' },
              },
              goodsDataSource: () => {
                // 活动类别列表数据从接口获取
                return new Promise((resolve, reject) => {
                  vsf.services
                    .MallCommodityController_getAllByCommodityInfoPublishQto_10ca09?.(
                      {
                        qto: {
                          publishStatusIs: 'PUBLISH_ON',
                        },
                        ext: {},
                      },
                    )
                    .then((res) => {
                      if (res?.data) {
                        resolve(res.data);
                      } else {
                        reject();
                      }
                    })
                    .catch(reject);
                });
              },
            }}
          />

          {/* <div className="campPLanSet">
            <VSFormItem
              name={['campPlanConfigBtoList']}
              label="套餐配置"
              valueType="select"
              style={{
                width: 250,
              }}
              fieldProps={{
                placeholder: '请选择配置选项',
                // mode: 'multiple',
                onSelect: (e) => {
                  setCampPlan(e);
                  setNum(1);
                },
                fieldNames: { value: 'id', label: 'commodityName' },
                dataSource: () => {
                  // 活动类别列表数据从接口获取
                  return new Promise((resolve, reject) => {
                    vsf.services
                      .MallCommodityController_getAllByCommodityInfoPublishQto_10ca09?.(
                        {
                          qto: {
                            publishStatusIs: 'PUBLISH_ON',
                          },
                          ext: {},
                        },
                      )
                      .then((res) => {
                        if (res?.data) {
                          resolve(res.data);
                        } else {
                          reject();
                        }
                      })
                      .catch(reject);
                  });
                },
              }}
              rules={[
                {
                  required: true,
                  message: '请选择配置选项',
                },
              ]}
            />

            <div className="campPLanSet_icon">
              <div>
                <MinusCircleOutlined
                  className="campPLanSet_icon__left"
                  onClick={() => {
                    if (num > 0) {
                      setNum(num - 1);
                    }
                  }}
                />
              </div>

              <div className="campPLanSet_icon__num">{num}</div>
              <div>
                <PlusCircleOutlined
                  className="campPLanSet_icon__right"
                  onClick={() => {
                    setNum(num + 1);
                  }}
                />
              </div>
            </div>

            <div className="campPLanSet_btn">
              <div>删除</div>
              <div
                onClick={() => {
                  if (campPlan) {
                    setCampPlanList([
                      ...campPlanList,
                      { ...campPlan, num: num },
                    ]);
                  } else {
                    message.warn('请选择配置选项');
                  }
                }}
              >
                新增
              </div>
            </div>
          </div>

          {campPlanList?.length > 0 && (
            <>
              {campPlanList?.map((item, index) => {
                return (
                  <div className="campPLanList">
                    <div>{item.label}</div>

                    <div className="campPLanList_icon">
                      <div>
                        <MinusCircleOutlined
                          className="campPLanList_icon__left"
                          onClick={() => {
                            if (item.num > 0) {
                              setCampPlanList(
                                campPlanList?.map((cur, idx) => {
                                  return {
                                    ...cur,
                                    num: index === idx ? cur.num - 1 : cur.num,
                                  };
                                }),
                              );
                            }
                          }}
                        />
                      </div>

                      <div className="campPLanList_icon__num">{item.num}</div>
                      <div>
                        <PlusCircleOutlined
                          className="campPLanList_icon__right"
                          onClick={() => {
                            setCampPlanList(
                              campPlanList?.map((cur, idx) => {
                                return {
                                  ...cur,
                                  num: index === idx ? cur.num + 1 : cur.num,
                                };
                              }),
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="campPLanList_move"
                      onClick={() => {
                        setCampPlanList(
                          cloneDeep(campPlanList)?.filter(
                            (fill) => fill.value !== item.value,
                          ),
                        );
                      }}
                    >
                      删除
                    </div>
                  </div>
                );
              })}
            </>
          )} */}

          <VSFormItem
            name={['planPrice']}
            label="套餐价格"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请填写套餐价格',
              },
              {
                pattern: new RegExp(/^(([1-9]\d*)|\d)(\.\d{1,2})?$/, 'g'),
                message: '请输入正确的金额',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(PackageManageForm);
