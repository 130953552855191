import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAction,
  Modal,
  message,
  Form,
  Radio,
  TextArea,
  Image,
} from '@vs/vsf-kit';
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { RefundReasonEnumValue } from '@/config';

const DiscountContainer = styled.div``;
const ManageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const OrderList = (props) => {
  const [refresh, setRefresh] = useState();

  const [refundDetail, setRefundDetail] = useState({});
  const [textValue, setTextValue] = useState();
  const [value, setValue] = useState(1);

  const [params, setParams] = useState();
  const [statisticsData, setStatisticsData] = useState();

  const onFetch = useCallback(async (params) => {
    setParams(params);
    const res =
      await vsf.services?.OrderInfoController_queryPagedByCampOrderQto_8ad15d?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            modelTypeIs: 'GOODS',
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const queryCampStatistics = async () => {
    const res =
      await vsf.services.OrderInfoController_queryCampStatistics_a249ba({
        qto: {
          modelTypeIs: 'GOODS',
          ...params.search,
        },
      });
    if (res.data && res.code === 200) {
      setStatisticsData(res?.data);
    }
  };

  useEffect(() => {
    if (params) {
      queryCampStatistics();
    }
  }, [params]);

  const handleSubmit = async () => {
    if (!textValue && value !== 1) {
      message.warning('请填写拒绝原因');
    } else {
      try {
        const res =
          await vsf.services?.OrderResearchLearningController_auditRefundOrder_9168c8?.(
            {
              auditRefundVo: {
                auditStatus: value === 1 ? true : false,
                refuseContent: textValue,
                learningOrderId: refundDetail?.id,
              },
            },
          );

        if (res && res?.code === 200) {
          message.success('审核成功');
          vsf.closeModal('refundModal');
          setTimeout(() => {
            setRefresh(!refresh);
          }, 1500);
        }
        return false;
      } catch (e) {
        return false;
      }
    }
  };

  const renderOrderContent = (_, record) => {
    try {
      return (
        <DiscountContainer>
          <div>
            {record?.goodsName}
            {record?.goodsNum > 0 && <>*{record?.goodsNum}</>}
          </div>
        </DiscountContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderImg = () => {
    try {
      const _imgs = JSON.parse(refundDetail?.refundImg ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '10px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="statisticsContainer">
      <Section title="订单管理">
        <div className="statistics">
          <div className="statisticsText">
            <div className="statisticsIcon"></div>
            统计
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            订单总数： {statisticsData?.orderCount ?? '-'}
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            交易总金额： {statisticsData?.totalTranAmount ?? '-'}
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            成交总金额： {statisticsData?.completeAmount ?? '-'}
          </div>
          <div className="statisticsItem">
            <div className="statisticsItemIcon"></div>
            退款总金额： {statisticsData?.refundAmount ?? '-'}
          </div>
        </div>
        <VSPromiseTable
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          extraParams={{ refresh }}
          onFetch={onFetch}
          vsid="74964"
        >
          <VSTableColumn
            dataIndex={['orderNo']}
            title="订单编号"
            valueType="text"
            search
            order={-60}
            columnKey={'orderNoIs'}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['phone']}
            title="手机号"
            valueType="text"
            search
            order={-61}
            columnKey={'phoneIs'}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['goodsName']}
            title="订单内容"
            valueType="text"
            render={renderOrderContent}
          />

          <VSTableColumn
            dataIndex={['orderStatus']}
            title="订单状态"
            valueType="select"
            search
            order={-62}
            columnKey={'orderStatusIs'}
            dataSource={[
              { label: '待支付', value: 'NO_PAY' },
              { label: '已支付', value: 'HAS_PAY' },
              { label: '退款中', value: 'REFUND' },
              { label: '已退款', value: 'REFUND_SUCCESS' },
              { label: '已完成', value: 'ORDER_SUCCESS' },
              { label: '已取消', value: 'ORDER_CLOSE' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['goodsPrice']}
            title="订单金额"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['goodsKind']}
            title="订单种类"
            valueType="select"
            search
            order={-62}
            columnKey={'goodsKindIs'}
            dataSource={[
              { label: '租赁', value: 'LEASE' },
              { label: '购买', value: 'PURCHASE' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['reserveTime']}
            title="订单时间"
            search
            hideInTable
            order={-75}
            columnKey={'createdAtRangeIn'}
            valueType="dateRange"
            transform={(values) => {
              if (!Array.isArray(values)) return values;
              return ['createdAtRangeIn'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              );
            }}
            fieldProps={{
              format: 'YYYY-MM-DD',
              valueType: 'string',
            }}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请选择',
                  type: 'string',
                },
              ],
            }}
          />

          {/* <VSTableColumn
          dataIndex={['modelType']}
          title="订单类型"
          valueType="select"
          search
          order={-62}
          columnKey={'modelTypeIs'}
          dataSource={[
            { label: '全部', value: '' },
            { label: '营地', value: 'CAMP' },
            { label: '商品', value: 'GOODS' },
          ]}
          fieldProps={{}}
        /> */}

          <VSTableColumn
            dataIndex={['buyerMessage']}
            title="买家留言"
            valueType="text"
          />

          <VSTableColumn
            dataIndex={['createdAt']}
            title="下单时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['completeTime']}
            title="核销时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            width={230}
            dataIndex={['action']}
            title="操作"
            valueType="option"
          >
            <VSTableAction
              show={(data) => data.orderStatus === 'HAS_PAY'}
              onClick={(v, vv, params) => {
                Modal.confirm({
                  content: '是否确认核销？',
                  icon: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    vsf.services
                      .OrderInfoController_updateOrderInfo_2311bc({
                        btoParam: {
                          id: vv?.id,
                          voucherOrderBto: {
                            voucherStatus: 'VERIFICATION',
                          },
                        },
                      })
                      .then((res) => {
                        if (res?.code === 200) {
                          message.success(`核销成功`);
                          setTimeout(() => {
                            setRefresh(!refresh);
                          }, 500);
                        }
                      });
                  },
                });
              }}
            >
              核销
            </VSTableAction>
          </VSTableColumn>
        </VSPromiseTable>

        {/* <Modal
        title="退款详情"
        id="refundModal"
        width={500}
        onOk={handleSubmit}
        okText="确定"
        cancelText="取消"
      >
        <div className="refundDetail">
          <div className="refundBox">
            <div className="refundText">订单编号</div>
            <div className="refundValue">{refundDetail?.orderNo ?? ''}</div>
          </div>
          <div className="refundBox">
            <div className="refundText">订单内容</div>
            <div className="refundValue">
              {refundDetail?.orderContent}：
              {refundDetail?.studentNum > 0 && (
                <>
                  {refundDetail?.studentNum > 0 ? '普通学生票' : undefined} *
                  {refundDetail?.studentNum}、
                </>
              )}
              {refundDetail?.adultNum > 0 && (
                <>
                  {refundDetail?.adultNum > 0 ? '成人票' : undefined} *
                  {refundDetail?.adultNum}、
                </>
              )}
              {refundDetail?.outStudentNum > 0 && (
                <>
                  {refundDetail?.outStudentNum > 0 ? '外地学生票' : undefined} *
                  {refundDetail?.outStudentNum}、
                </>
              )}
              {refundDetail?.freeTicketsNum > 0 && (
                <>
                  {refundDetail?.freeTicketsNum > 0 ? '免票' : undefined} *
                  {refundDetail?.freeTicketsNum}
                </>
              )}
            </div>
          </div>
          <div className="refundBox">
            <div className="refundText">售后方式</div>
            <div className="refundValue">仅退款</div>
          </div>
          <div className="refundBox">
            <div className="refundText">退款金额</div>
            {refundDetail?.refundPrice ? (
              <>
                <div className="refundValue">
                  ¥{refundDetail?.refundPrice ?? ''}
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="refundBox">
            <div className="refundText">退款原因</div>
            <div className="refundValue">
              {refundDetail?.refundReason === 'MAKE_MISTAKE'
                ? '错拍多拍'
                : refundDetail?.refundReason === 'CHANGE'
                ? '计划有变'
                : refundDetail?.refundReason === 'NOT_NEED'
                ? '不要了'
                : refundDetail?.refundReason === 'OTHER'
                ? '其他'
                : '' ?? ''}
            </div>
          </div>

          <div className="refundBox">
            <div className="refundText">姓名</div>
            <div className="refundValue">{refundDetail?.name ?? ''}</div>
          </div>

          <div className="refundBox">
            <div className="refundText">手机号</div>
            <div className="refundValue">{refundDetail?.phone ?? ''}</div>
          </div>

          <div className="refundBox">
            <div className="refundText">退款凭证</div>
            <div className="refundValue">{renderImg()}</div>
          </div>

          <div className="refundBox">
            <div className="refundText">退款说明</div>
            <div className="refundValue">
              <Radio.Group
                onChange={(e) => {
                  setValue(e);
                }}
                dataSource={[
                  {
                    label: '确认退款',
                    value: 1,
                  },
                  {
                    label: '拒绝退款',
                    value: 2,
                  },
                ]}
                value={value}
              ></Radio.Group>
            </div>
          </div>

          {value === 2 && (
            <>
              <div className="refundBox">
                <div className="refundText">退款原因</div>
                <div className="refundValue">
                  {' '}
                  <TextArea
                    rows={4}
                    value={textValue}
                    onChange={(e) => {
                      setTextValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal> */}
      </Section>
    </div>
  );
};
export default definePage(OrderList);
