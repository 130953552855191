import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Breadcrumb,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import storage from '@/utils/storage';

const ManageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const LogsTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const rederIndex = (_, __, index) => {
    return (
      <ManageContainer>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </ManageContainer>
    );
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.LogsOperationController_queryPagedByOperationLogsIdxQto_93a253?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            projectTypeIs: 'learning',
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <Section>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>系统日志</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        vsid="77321"
        onFetch={onFetch}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />
        <VSTableColumn
          dataIndex={['model']}
          title="操作模块"
          valueType="text"
          search
          order={-80}
          columnKey={'modelLike'}
          formItemProps={{
            rules: [
              {
                message: '操作模块长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['content']}
          title="操作内容"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '操作内容长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['userAccount']}
          title="操作账号"
          valueType="text"
          search
          order={-81}
          columnKey={'userAccountLike'}
          formItemProps={{
            rules: [
              {
                message: '操作账号长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="操作时间"
          search
          hideInTable
          order={-82}
          columnKey={'createdAtRangeIn'}
          valueType="dateRange"
          fieldProps={{ format: 'YYYY-MM-DD' }}
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['createdAtRangeIn'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="操作时间"
          order={-82}
          columnKey={'createdAtRangeIn'}
          valueType="dateRange"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['createdAtRangeIn'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
        />
        <VSTableColumn
          dataIndex={['ip']}
          title="IP"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'ip长度不合法', type: 'string', min: 0, max: 1024 },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(LogsTable);
