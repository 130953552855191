import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useRef } from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const InfomationForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="84289"
        layout="inline"
        id="InfomationForm"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout key="0" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['informationName']}
            label="名称"
            valueType="text"
            rules={[{ message: '请输入名称' }]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['img']}
            label="图片"
            required
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传图片' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  img: v,
                };
              }
              return {
                img: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize" style={{ marginLeft: '80px' }}>
            建议尺寸：300 * 400
          </div>
          <VSFormItem
            name="content"
            label="内容"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写内容' }]}
          />

          {/* <VSFormItem
            name={['content']}
            label="资讯内容"
            valueType="textarea"
            rules={[{ max: 250, message: '请输入资讯内容（250字以内）' }]}
            fieldProps={{
              maxLength: 250,
              autoSize: {
                minRows: 3,
                maxRows: 4,
              },
            }}
          /> */}

          {/* <VSFormItem
            name={['activityUrl']}
            label="跳至页面"
            valueType="text"
            fieldProps={{}}
          /> */}
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(InfomationForm);
