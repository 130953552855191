import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontPageController_createFront_d10dde: {
    method: 'post',
    url: '/api/front-page/create-front',
    parameterFomatter: (data?: {
      btoParam: CreateFrontBtoFrontPageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: FrontPageVoFrontPageEntranceWebVo) =>
      data,
  },
  FrontPageController_updateFront_f784f8: {
    method: 'post',
    url: '/api/front-page/update-front',
    parameterFomatter: (data?: {
      btoParam: UpdateFrontBtoFrontPageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: FrontPageVoFrontPageEntranceWebVo) =>
      data,
  },
  OssPolicyController_getOssPolicy_7c7b3a: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
});
