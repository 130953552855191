import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const AccountForm = (props) => {
  return (
    <Section>
      <VSControlledForm vsid="94767" id="AccountForm" value={props.value}>
        <VSFormLayout key="0" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['id']}
            label="主键"
            valueType="text"
            style={{ display: 'none' }}
          />
          <VSFormItem
            name={['nickname']}
            label="用户名"
            valueType="text"
            fieldProps={{}}
            rules={[
              {
                required: true,
                message: '请输入用户名',
              },
            ]}
          />

          <VSFormItem
            name={['phone']}
            label="手机号"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: new RegExp(/^1[3-9]\d{9}$/),
                message: '请输入正确的手机号',
              },
            ]}
            fieldProps={{
              maxLength: 11,
            }}
          />
          {!props.value?.id && (
            <>
              <VSFormItem
                name={['password']}
                label="初始密码"
                valueType="text"
                rules={[
                  {
                    required: true,
                    message: '请输入初始密码',
                  },
                ]}
                fieldProps={{}}
              />
            </>
          )}

          {!props.value?.id && (
            <>
              <VSFormItem
                name={['userType']}
                label="角色"
                valueType="select"
                style={{
                  marginLeft: 30,
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择角色',
                  },
                ]}
                dataSource={props?.userTypeList}
                fieldProps={{}}
              />
            </>
          )}

          {props.value?.id && (
            <>
              <VSFormItem
                name={['status']}
                label="用户状态"
                valueType="select"
                rules={[
                  {
                    required: true,
                    message: '请选择用户状态',
                  },
                ]}
                dataSource={[
                  { label: '在用', value: 'NORMAL' },
                  { label: '注销', value: 'BANNED' },
                ]}
                fieldProps={{}}
              />
            </>
          )}
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(AccountForm);
