export const STORAGE_KEY_PREFIX = 'camping-area';

export enum ReserveStatusEnum {
  BOOKED = 'BOOKED',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
}

export enum ReserveStatusEnumValue {
  BOOKED = '已预约',
  SUCCESS = '已完成',
  CANCELED = '已取消',
}

export enum ReadStatusEnum {
  READ = 'READ',
  NO_READ = 'NO_READ',
}

export enum ReadStatusEnumValue {
  READ = '已读',
  NO_READ = '未读',
}

export enum UsedStatusEnum {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export enum UsedStatusEnumValue {
  ENABLE = '在用',
  DISABLE = '停用',
}

export enum CommodityTypeEnum {
  OUTDOOR = 'OUTDOOR',
  RESTAURANT = 'RESTAURANT',
  SPECIALITY = 'SPECIALITY',
  TOURISM = 'TOURISM',
}

export enum CommodityTypeEnumValue {
  OUTDOOR = '露营装备',
  RESTAURANT = '餐饮食材',
  SPECIALITY = '名优特产',
}

export enum RefundReasonEnum {
  MAKE_MISTAKE = 'MAKE_MISTAKE',
  CHANGE = 'CHANGE',
  NOT_NEED = 'NOT_NEED',
  OTHER = 'OTHER',
}

export enum RefundReasonEnumValue {
  MAKE_MISTAKE = '错拍多拍',
  CHANGE = '计划有变',
  NOT_NEED = '不要了',
  OTHER = '其他',
}

export default {};
