import './index.less';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Form,
  Input,
  message,
  Search,
  Select,
  Space,
} from '@vs/vsf-kit';
import React, { useEffect, useRef, useState } from 'react';

import backImg from '@/assets/images/backImg.png';
import BackGroundImg from '@/assets/images/LoginBackgroundImg.png';
import loginBackImg from '@/assets/images/loginBackImg.png';
import logo from '@/assets/images/logo.png';
import services from '@/services/index';
import storage from '@/utils/storage';
const Login: React.FC<React.Component> = (props) => {
  const [disabled, setDisabled] = useState(Boolean);
  const [loading, setLoading] = useState(Boolean);
  const [time, setTime] = useState(60);
  const [form] = Form.useForm();
  const [loginType, setLoginType] = useState('login');
  const initialTiem = useRef(60);
  const timer = useRef<any>();
  useEffect(() => {
    const data = storage.get('userData') ?? undefined;
    if (data) {
      vsf?.navigateTo('./bannerManage');
    }
  }, []);
  const onFinish = (values) => {
    if (loginType === 'login') {
      onLogin(values);
    } else if (loginType === 'register') {
      onRegister(values);
    } else if (loginType === 'forget') {
      onForget(values);
    }
  };
  const onRegister = (values) => {
    const res = vsf.services
      .UserAccountController_createUserPassword_f3a420?.({
        extApiParam: {
          verify: values?.code,
        },
        btoParam: {
          status: '0',
          userType: '0',
          phone: values?.phone,
          nickname: values?.username,
          accountUserBtoList: [
            {
              iBindingAccountBase: {
                userName: values?.username,
                '@class':
                  'com.vs.user_account.service.bto.CreateUserPasswordBto$UserPasswordBto',
                userType: '0',
                password: values?.password,
              },
            },
          ],
        },
      })
      .then((res) => {
        if (res && res?.code === 200) {
          message.success('注册成功，请登录账号');
          setLoginType('login');
        }
      });
  };
  const onForget = (values) => {
    const res = vsf.services
      .UserAccountController_forgotPassword_1a101b?.({
        extApiParam: {
          verify: values?.code,
          passWord: values?.password,
        },
        btoParam: {
          phone: values?.phone,
          accountUserBtoList: [
            {
              iBindingAccountBase: {
                '@class':
                  'com.vs.user_account.service.bto.ForgotPasswordBto$UserPasswordBto',
                password: values?.password,
              },
            },
          ],
        },
      })
      .then((res) => {
        if (res && res?.code === 200) {
          message.success('找回密码成功，请重新登录');
        }
      });
  };
  const onLogin = (values) => {
    const res = vsf.services
      .UserAccountController_userPasswordLogin_b21bf2?.({
        username: values?.username,
        password: values?.password,
      })
      .then((res) => {
        if (res && res?.code === 200) {
          storage.set('userData', JSON.stringify(res?.data));
          message.success('登录成功');
          if (res.data?.userType === 'ADMIN') {
            vsf.stores.system.setPlatform('learning');
            storage.set('platform', 'learning');
            vsf?.navigateTo('./BannerManagement');
          } else if (res.data?.userType === 'CAMP_ADMIN') {
            vsf.stores.system.setPlatform('CAMP_ADMIN');
            storage.set('platform', 'CAMP_ADMIN');
            vsf?.navigateTo('./bannerManage');
          } else if (res.data?.userType === 'CAMP_STORE') {
            vsf.stores.system.setPlatform('CAMP_STORE');
            storage.set('platform', 'CAMP_STORE');
            vsf?.navigateTo('./shopManage');
          } else if (res.data?.userType === 'LEARNING_ADMIN') {
            vsf.stores.system.setPlatform('LEARNING_ADMIN');
            storage.set('platform', 'LEARNING_ADMIN');
            vsf?.navigateTo('./BannerManagement');
          } else if (res.data?.userType === 'LEARNING_STORE') {
            vsf.stores.system.setPlatform('LEARNING_STORE');
            storage.set('platform', 'LEARNING_STORE');
            vsf?.navigateTo('./ResearchLearningMange');
          }
        }
      });
  };

  const onCode = () => {
    const values = form.getFieldsValue();
    if (values?.phone) {
      setLoading(true);
      const res = vsf.services
        .UserAccountController_sendVerifyCode_67447a?.({
          phone: values?.phone,
        })
        .then((res) => {
          if (res && res?.code === 200) {
            setDisabled(true);
            setLoading(false);
            message?.success('短信验证码发送成功，注意查收');
            timer.current = setInterval(() => {
              initialTiem.current -= 1;
              setTime(initialTiem.current);
              if (initialTiem.current <= 0) {
                clearInterval(timer.current);
                setDisabled(false);
                setLoading(false);
              }
            }, 1000);
          } else if (res?.code !== 200) {
            setDisabled(false);
            setLoading(false);
          }
        });
    } else {
      message.error('请先输入手机号');
    }
  };
  return (
    <div className="login_container">
      <div className="login_container__title">
        <img src={logo} className="title_logo" />
      </div>
      <img src={BackGroundImg} className="login_container__bg" />

      <div className="login_container__content">
        <img
          className="login_container__content__left"
          src={loginBackImg}
        ></img>
        <img src={backImg} className="login_container__backImg" />
        <div className="login_container__content__right">
          <div className="content_right__title">
            {loginType === 'login'
              ? '账号登录'
              : loginType === 'register'
                ? '账号注册'
                : loginType === 'forget'
                  ? '忘记密码'
                  : ''}
          </div>

          <Form
            form={form}
            name="normal_login"
            className="login-form"
            autoComplete="off"
            onFinish={onFinish}
          >
            <div className="content_right__box">
              <Space direction="vertical" size="middle">
                {loginType === 'login' ? (
                  <>
                    <Form.Item
                      name="username"
                      rules={[{ required: true, message: '请输入用户名' }]}
                    >
                      <Space.Compact>
                        <Input
                          addonBefore="账号"
                          style={{ width: '320px' }}
                          placeholder="请输入用户名"
                        />

                        {/* <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="请输入用户名"
                        /> */}
                      </Space.Compact>
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[{ required: true, message: '请输入密码' }]}
                    >
                      <Space.Compact>
                        <Input.Password
                          addonBefore="密码"
                          style={{ width: '320px' }}
                          placeholder="请输入密码"
                        />
                      </Space.Compact>
                    </Form.Item>

                    <div className="content_right__bottom">
                      {/* <div
                        className="content_right__bottom__text"
                        onClick={() => {
                          setLoginType('register');
                          form?.resetFields();
                          setDisabled(false);
                          setLoading(false);
                        }}
                      >
                        账号注册
                      </div> */}
                      <div
                        className="content_right__bottom__text"
                        onClick={() => {
                          setLoginType('forget');
                          form?.resetFields();
                          setDisabled(false);
                          setLoading(false);
                        }}
                      >
                        忘记密码?
                      </div>
                    </div>
                    <div className="content_right__btn">
                      <Form.Item>
                        <Button
                          children="登录"
                          className="campingManage_btn"
                          htmlType="submit"
                          type="primary"
                          style={{
                            width: '95%',
                            backgroundColor: '#37927b',
                            color: 'white',
                            borderRadius: '16px',
                            height: '35px',
                          }}
                        ></Button>
                      </Form.Item>
                    </div>
                  </>
                ) : loginType === 'register' ? (
                  <>
                    <div className="register_box">
                      <Form.Item
                        name="username"
                        rules={[{ required: true, message: '请输入用户名' }]}
                      >
                        <Input placeholder="用户名" />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入密码' }]}
                      >
                        <Input.Password placeholder="密码" />
                      </Form.Item>
                      <Form.Item
                        name="surePassword"
                        rules={[
                          {
                            required: true,
                            message: '请输入确认密码',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('两次输入的密码不一致'),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="确认密码" />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[{ required: true, message: '请输入手机号' }]}
                      >
                        <Input placeholder="手机号" maxLength={11} />
                      </Form.Item>
                      <Form.Item
                        name="code"
                        rules={[{ required: true, message: '请输入验证码' }]}
                      >
                        <div className="codeBtnBox">
                          <Input
                            placeholder="验证码"
                            style={{ width: '60%' }}
                            maxLength={6}
                          />
                          <Button
                            // children="获取验证码"
                            className="codeBtn"
                            type="primary"
                            disabled={disabled}
                            loading={loading}
                            onClick={() => {
                              onCode();
                            }}
                          >
                            {disabled ? time + 's' : '获取验证码'}
                          </Button>
                        </div>
                      </Form.Item>

                      <div className="register_box__bottom">
                        <div
                          className="register_box__bottom_text"
                          onClick={() => {
                            setLoginType('login');
                            form?.resetFields();
                            setDisabled(false);
                            setLoading(false);
                          }}
                        >
                          使用已有账号登录
                        </div>
                        <div className="register_box__bottom_btn">
                          <Button
                            children="注册"
                            className="registerBtn"
                            htmlType="submit"
                            type="primary"
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : loginType === 'forget' ? (
                  <>
                    <div className="register_box">
                      <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入新密码' }]}
                      >
                        <Input.Password placeholder="新密码" />
                      </Form.Item>
                      <Form.Item
                        name="surePassword"
                        rules={[
                          {
                            required: true,
                            message: '请输入确认密码',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('两次输入的密码不一致'),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="确认密码" />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[{ required: true, message: '请输入手机号' }]}
                      >
                        <Input placeholder="手机号" maxLength={11} />
                      </Form.Item>
                      <Form.Item
                        name="code"
                        rules={[{ required: true, message: '请输入验证码' }]}
                      >
                        <div className="codeBtnBox">
                          <Input
                            placeholder="验证码"
                            maxLength={6}
                            style={{ width: '60%' }}
                          />
                          <Button
                            className="codeBtn"
                            disabled={disabled}
                            onClick={() => {
                              onCode();
                            }}
                            type="primary"
                          >
                            {disabled ? time + 's' : '获取验证码'}
                          </Button>
                        </div>
                      </Form.Item>

                      <div className="register_box__bottom">
                        <div
                          className="register_box__bottom_text"
                          onClick={() => {
                            setLoginType('login');
                            form?.resetFields();
                            setDisabled(false);
                          }}
                        >
                          账号登录
                        </div>
                        <div className="register_box__bottom_btn">
                          <Button
                            children="确认"
                            className="registerBtn"
                            htmlType="submit"
                            type="primary"
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default definePage(Login);
