import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useState, useEffect, useRef } from 'react';
import { UsedStatusEnum } from '@/config';
import getOssPolicy from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const CampPositionManageForm = (props) => {
  const [partitionList, setPartitionList] = useState([]);
  useEffect(() => {
    loadPartitionList();
  }, []);

  const loadPartitionList = async () => {
    const res =
      await vsf.services.ReserveCampPlanController_getAllByCampPlanListQto_71a42e?.(
        {
          qto: {},
        },
      );
    if (res && res.code === 200) {
      setPartitionList(
        res?.data.map((item) => {
          return {
            label: item.planName,
            value: item.id,
          };
        }),
      );
    }
  };
  return (
    <Section>
      <VSControlledForm
        vsid="67186"
        id="CampPositionManageForm"
        value={{
          ...props.value,
          campPlan: props?.value?.campPlan?.map((item) => item?.id),
        }}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout key="4" columnCount={1} labelWidth={100}>
          <VSFormItem
            name={['campNo']}
            label="营位编号"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入营位编号',
              },
            ]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['campImg']}
            label="营位图片"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传营位图片' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  campImg: v,
                };
              }
              return {
                campImg: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                // maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize" style={{ marginLeft: '100px' }}>
            建议尺寸：400 * 400
          </div>

          {/* <VSFormItem
            name={['qr']}
            label="营位二维码"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传营位二维码' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              return {
                qr: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          /> */}
          <VSFormItem
            name={['partitionName']}
            label="所属分区"
            valueType="select"
            rules={[
              {
                required: true,
              },
            ]}
            fieldProps={{
              fieldNames: { value: 'id', label: 'partitionName' },
              dataSource: () => {
                // 活动类别列表数据从接口获取
                return new Promise((resolve, reject) => {
                  vsf.services
                    .ReservePartitionController_getAllByPubPartitionQto_e25a8c?.(
                      {
                        qto: {
                          partitionStatusIs: UsedStatusEnum?.ENABLE,
                        },
                      },
                    )
                    .then((res) => {
                      if (res.data) {
                        resolve(res.data);
                      } else {
                        reject();
                      }
                    })
                    .catch(reject);
                });
              },
            }}
          />
          <VSFormItem
            name={['campPrice']}
            label="日常价格"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入日常价格',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />
          <VSFormItem
            name={['campPlan']}
            label="可选套餐"
            valueType="select"
            fieldProps={{
              mode: 'multiple',
            }}
            dataSource={partitionList}
          />

          <VSFormItem
            name={['usedStatus']}
            label="状态"
            valueType="select"
            rules={[{ required: true, message: '请选择状态' }]}
            dataSource={[
              { label: '在用', value: 'ENABLE' },
              { label: '停用', value: 'DISABLE' },
            ]}
            fieldProps={{}}
          />

          {/* <VSFormItem
            name={['introduction']}
            label="营位介绍"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传营位介绍图片' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  introduction: v,
                };
              }
              return {
                introduction: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              // limit: 500 * 1024,
              // onLimitOverflow: (fileSize, limit) => {
              //   vsf.showToast('图片内存大小不可超过500kb', 'error');
              // },
              uploadProps: {
                // maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize">建议尺寸：700*900</div> */}

          <VSFormItem
            name="introduction"
            label="营位介绍"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写介绍' }]}
          />

          <VSFormItem
            name={['reserveNotice']}
            label="预定须知"
            valueType="textarea"
            rules={[
              {
                required: true,
                message: '请输入预定须知 ',
              },
            ]}
            fieldProps={{
              maxLength: 250,
              autoSize: {
                minRows: 3,
                maxRows: 4,
              },
            }}
          />

          <VSFormItem
            name={['withdrawalRules']}
            label="退改规则"
            valueType="textarea"
            rules={[
              {
                required: true,
                message: '请输入退改规则',
              },
            ]}
            fieldProps={{
              maxLength: 250,
              autoSize: {
                minRows: 3,
                maxRows: 4,
              },
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(CampPositionManageForm);
