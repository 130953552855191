import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderMerchantApprovalController_getPagedByApprovalQto_5db8bb: {
    method: 'post',
    url: '/api/order-merchant/get-by-approval-qto',
    parameterFomatter: (data?: { qto: ApprovalQtoOrderMerchantPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ApprovalInfoVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantVoucherController_updateWorkConsume_0a5d20: {
    method: 'post',
    url: '/api/order-merchant/update-work-consume',
    parameterFomatter: (data?: {
      btoParam: UpdateWorkConsumeBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantVoucherController_updateBatchWorkConsume_3270bf: {
    method: 'post',
    url: '/api/order-merchant/update-batch-work-consume',
    parameterFomatter: (data?: {
      btoParamList: UpdateWorkConsumeBtoOrderMerchantServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantVoucherController_deduction_dd21a4: {
    method: 'post',
    url: '/api/order-merchant/deduction',
    parameterFomatter: (data?: {
      btoParam: RechargeWorkBvoOrderMerchantEntranceWebBvo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_merchantWorkPay_fb2f9b: {
    method: 'post',
    url: '/api/order-merchant/merchant-work-pay',
    parameterFomatter: (data?: {
      merchantId: number;
      userId: number;
      passWord: string;
      price: string;
      phone: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantController_getMerchantInfoVoByPhone_cb949b: {
    method: 'post',
    url: '/api/order-merchant/get-merchant-info-vo-by-phone',
    parameterFomatter: (data?: { phone: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantApprovalController_approval_8a4284: {
    method: 'post',
    url: '/api/order-merchant/approval',
    parameterFomatter: (data?: {
      btoParam: UpdateApprovalBtoOrderMerchantServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApprovalInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantApprovalController_updateApproval_28e1e4: {
    method: 'post',
    url: '/api/order-merchant/update-approval',
    parameterFomatter: (data?: {
      btoParam: UpdateApprovalBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApprovalInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantVoucherController_getConsumeVoucherVoByPhone_32f823: {
    method: 'post',
    url: '/api/order-merchant/get-consume-voucher-vo-by-phone',
    parameterFomatter: (data?: { phone: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_getPagedByTransactionRecordQto_43ab71: {
    method: 'post',
    url: '/api/order-merchant/get-paged-by-transaction-record-qto',
    parameterFomatter: (data?: {
      qto: TransactionRecordQtoOrderMerchantPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TransactionInfoVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantController_withdraw_62855a: {
    method: 'post',
    url: '/api/order-merchant/withdraw',
    parameterFomatter: (data?: {
      btoParam: UpdateApprovalBtoOrderMerchantServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantVoucherController_queryBalance_5c8ae3: {
    method: 'post',
    url: '/api/order-merchant/query-balance',
    parameterFomatter: (data?: { phone: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OrderMerchantController_getMerchantInfoVoById_a43be6: {
    method: 'post',
    url: '/api/order-merchant/get-merchant-info-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_merchantPay_35abf3: {
    method: 'post',
    url: '/api/order-research-learning/merchant-pay',
    parameterFomatter: (data?: {
      merchantId: number;
      paymentChannel: PaymentChannelEnum;
      callbackUrl: string;
      paymentMethod: PaymentMethodEnum;
      openId: string;
      userId: number;
      passWord: string;
      price: string;
    }) => data,
    responseFormatter: (_, __, data?: ResultDtoComVsRevenueCashierManagerDto) =>
      data,
  },
});
