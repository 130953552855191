import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderMerchantVoucherController_createWorkConsume_c6f4df: {
    method: 'post',
    url: '/api/order-merchant/create-work-consume',
    parameterFomatter: (data?: {
      btoParam: CreateWorkConsumeBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantVoucherController_getPagedByConsumeVoucherQto_d3715a: {
    method: 'post',
    url: '/api/order-merchant/get-paged-by-consume-voucher-qto',
    parameterFomatter: (data?: {
      qto: ConsumeVoucherQtoOrderMerchantPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ConsumeVoucherVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantVoucherController_rechargeWorkVoucher_95302c: {
    method: 'post',
    url: '/api/order-merchant/recharge-work-voucher',
    parameterFomatter: (data?: {
      btoParam: RechargeWorkBvoOrderMerchantEntranceWebBvo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantVoucherController_updateBatchWorkConsume_3270bf: {
    method: 'post',
    url: '/api/order-merchant/update-batch-work-consume',
    parameterFomatter: (data?: {
      btoParamList: UpdateWorkConsumeBtoOrderMerchantServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantVoucherController_getListRechargeInfoVoByVoucherId_92611f: {
    method: 'post',
    url: '/api/order-merchant/get-list-recharge-info-vo-by-voucher-id',
    parameterFomatter: (data?: { voucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: RechargeInfoVoOrderMerchantEntranceWebVo[],
    ) => data,
  },
  OrderMerchantVoucherController_deleteWorkConsume_81acad: {
    method: 'post',
    url: '/api/order-merchant/delete-work-consume',
    parameterFomatter: (data?: {
      btoParam: DeleteWorkConsumeBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
});
