import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const CampCockpitManagerFrom = (props) => {
  return (
    <Section>
      <VSControlledForm id="campCockpitManageFrom" value={props.value}>
        <VSFormLayout key="0" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['name']}
            label="名称"
            valueType="text"
            required
            readonly={
              props?.isReadOnly ||
              props?.value?.name === '本月订单' ||
              props?.value?.name === '本周订单' ||
              props?.value?.name === '订单总数' ||
              props?.value?.name === '今日订单' ||
              props?.value?.name === '预约营位数' ||
              props?.value?.name === '可用营位'
            }
            fieldProps={{
              maxLength: 5,
            }}
          />

          <VSFormItem
            name={['monthNum']}
            label="数值"
            required
            rules={[{ required: true, message: '请输入数值' }]}
            valueType="text"
            fieldProps={{
              maxLength: 5,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(CampCockpitManagerFrom);
