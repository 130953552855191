import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useRef } from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const ShopListForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="24168"
        id="ShopListForm"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormLayout columnCount={1} labelWidth={100}>
          <VSFormItem
            name={['commodityNum']}
            label="商品编号"
            valueType="text"
            readonly={props?.value?.id}
            rules={[{ required: true, message: '请输入商品编号' }]}
            fieldProps={{
              maxLength: 5,
            }}
          />

          <VSFormItem
            name={['commodityName']}
            label="商品名称"
            valueType="text"
            rules={[{ required: true, message: '请输入商品名称' }]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['commodityImg']}
            label="商品封面"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传商品封面' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  commodityImg: v,
                };
              }
              return {
                commodityImg: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                // maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize" style={{ marginLeft: '100px' }}>
            建议尺寸：400 * 400
          </div>
          {props?.value?.id && props?.value?.publishStatus !== 'SELL_OUT' ? (
            <>
              <VSFormItem
                name={['publishStatus']}
                label="商品状态"
                style={{ marginLeft: 20 }}
                valueType="select"
                rules={[{ required: true, message: '请选择商品状态' }]}
                dataSource={[
                  { label: '上架', value: 'PUBLISH_ON' },
                  { label: '下架', value: 'PUBLISH_DOWN' },
                ]}
                fieldProps={{
                  style: {
                    width: 500,
                  },
                }}
              />
            </>
          ) : null}

          <VSFormItem
            name={['commodityKind']}
            label="商品种类"
            valueType="select"
            rules={[{ required: true, message: '请选择商品种类' }]}
            dataSource={[
              { label: '租赁', value: 'LEASE' },
              { label: '购买', value: 'PURCHASE' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['commodityModel']}
            label="商品型号"
            valueType="text"
            rules={[
              {
                message: '商品型号长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
          />

          <VSFormItem
            name={['commoditySpecifications']}
            label="商品规格"
            valueType="text"
            rules={[
              {
                message: '商品规格长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['commodityColor']}
            label="商品颜色"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['commodityType']}
            label="商品类型"
            valueType="select"
            rules={[{ required: true, message: '请选择商品类型' }]}
            dataSource={[
              { label: '露营装备', value: 'OUTDOOR' },
              { label: '餐饮食材', value: 'RESTAURANT' },
              { label: '名优特产', value: 'SPECIALITY' },
              { label: '联农优品', value: 'FARM' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['salesPrice']}
            label="价格"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入价格',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{
              maxLength: 5,
              min: 0,
            }}
          />

          <VSFormItem
            name={['commodityStock']}
            label="库存量"
            valueType="digit"
            rules={[
              {
                required: true,
                message: '请输入库存量',
              },
            ]}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
            fieldProps={{}}
          />

          {/* <VSFormItem
            name={['commodityIntroduction']}
            label="商品介绍"
            valueType="fileUpload"
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  commodityIntroduction: v,
                };
              }
              return {
                commodityIntroduction: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              // limit: 500 * 1024,
              // onLimitOverflow: (fileSize, limit) => {
              //   vsf.showToast('图片内存大小不可超过500kb', 'error');
              // },
              uploadProps: {
                // maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
          <div className="suggessSize">建议尺寸：700*900</div> */}

          <VSFormItem
            name="commodityIntroduction"
            label="商品介绍"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写商品介绍' }]}
          />
          <VSFormItem
            name={['notice']}
            label="购买须知"
            valueType="textarea"
            rules={[
              {
                message: '注意事项长度不合法',
                type: 'string',
                min: 0,
                max: 250,
              },
            ]}
            fieldProps={{
              maxLength: 250,
              autoSize: {
                minRows: 3,
                maxRows: 4,
              },
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(ShopListForm);
