import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserAccountController_getAllByUserProfileQto_adf45b: {
    method: 'post',
    url: '/api/user-account/get-all-by-user-profile-qto',
    parameterFomatter: (data?: {
      qto: UserProfileQtoComVsUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto[],
    ) => data,
  },
  UserAccountController_updatePassword_9a5392: {
    method: 'post',
    url: '/api/user-account/update-password',
    parameterFomatter: (data?: {
      btoParam: UpdatePasswordBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  UserAccountController_deletePassword_e23731: {
    method: 'post',
    url: '/api/user-account/delete-password',
    parameterFomatter: (data?: {
      btoParam: DeletePasswordBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  UserAccountController_getAllByUserProfileUserQto_56c8c0: {
    method: 'post',
    url: '/api/user-account/get-all-by-user-profile-user-qto',
    parameterFomatter: (data?: {
      qto: UserProfileUserQtoComVsUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto[],
    ) => data,
  },
});
