import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontPageController_queryPagefallByFrontInfoQto_f9fdf0: {
    method: 'post',
    url: '/api/front-page/query-pagefall-by-front-info-qto',
    parameterFomatter: (data?: {
      qto: FrontInfoQtoFrontPagePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: FrontPageVoFrontPageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FrontActivityController_queryAllByActivityListQto_8efa1d: {
    method: 'post',
    url: '/api/front-activity/query-all-by-activity-list-qto',
    parameterFomatter: (data?: {
      qto: ActivityListQtoSimpleFrontActivityPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoVoFrontActivityEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FrontActivityController_createInformation_b8cd60: {
    method: 'post',
    url: '/api/front-activity/create-information',
    parameterFomatter: (data?: {
      btoParam: CreateInformationBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InformationVoFrontActivityEntranceWebVo,
    ) => data,
  },
  FrontActivityController_updateInformation_79aee8: {
    method: 'post',
    url: '/api/front-activity/update-information',
    parameterFomatter: (data?: {
      btoParam: UpdateInformationBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InformationVoFrontActivityEntranceWebVo,
    ) => data,
  },
  FrontActivityController_deleteInformation_5c1873: {
    method: 'post',
    url: '/api/front-activity/delete-information',
    parameterFomatter: (data?: {
      btoParam: DeleteInformationBtoFrontActivityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InformationVoFrontActivityEntranceWebVo,
    ) => data,
  },
  FrontActivityController_queryPagedByInformationInfoQto_51b021: {
    method: 'post',
    url: '/api/front-activity/query-paged-by-information-info-qto',
    parameterFomatter: (data?: {
      qto: InformationInfoQtoFrontActivityPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InformationVoFrontActivityEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FrontActivityController_queryPagefallByInformationFallQto_8aee34: {
    method: 'post',
    url: '/api/front-activity/query-pagefall-by-information-fall-qto',
    parameterFomatter: (data?: {
      qto: InformationFallQtoFrontActivityPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InformationVoFrontActivityEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReservePartitionController_getAllByPubPartitionQto_e25a8c: {
    method: 'post',
    url: '/api/reserve-camp-info/get-all-by-pub-partition-qto',
    parameterFomatter: (data?: {
      qto: PubPartitionQtoReserveCampInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PartitionInfoVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveCampInfoController_queryPagefallByCampPartitionQto_9aba74: {
    method: 'post',
    url: '/api/reserve-camp-info/query-pagefall-by-camp-partition-qto',
    parameterFomatter: (data?: {
      qto: CampPartitionQtoReserveCampInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CampPartitionVoReserveCampInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveCampInfoController_getByCampId_48cfae: {
    method: 'post',
    url: '/api/reserve-camp-info/get-by-camp-info-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampProgramVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  ReserveCampPlanController_selectPlanInfoByCampId_6585a6: {
    method: 'post',
    url: '/api/reserve-camp-info/select-plan-info-by-camp-id',
    parameterFomatter: (data?: { campId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlanInfoVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveCampPlanController_selectCampGoodsInfo_1db615: {
    method: 'post',
    url: '/api/reserve-camp-info/select-camp-goods-info',
    parameterFomatter: (data?: { campId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampPlanGoodsVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  CampOrderController_createCampOrder_2bd523: {
    method: 'post',
    url: '/api/reserve-camp-info/create-camp-order',
    parameterFomatter: (data?: {
      campOrderVo: CampOrderVoReserveCampInfoEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: ResultDtoComVsRevenueCashierManagerDto) =>
      data,
  },
  ReserveCampInfoController_getCampAddress_619b61: {
    method: 'post',
    url: '/api/reserve-camp-info/get-camp-address',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: GoodCampAddressVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  CampOrderController_refundItineraryOrder_076e06: {
    method: 'post',
    url: '/api/reserve-camp-info/refund-itinerary-order',
    parameterFomatter: (data?: {
      updateOrderInfoBto: UpdateOrderInfoBtoComVsOrderInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ReserveCampInfoController_getScreenCampInfo_a2c9d2: {
    method: 'post',
    url: '/api/reserve-camp-info/get-screen-camp-info',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: CampScreenVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  CampOrderController_payItineraryOrder_a551e3: {
    method: 'post',
    url: '/api/reserve-camp-info/pay-itinerary-order',
    parameterFomatter: (data?: {
      id: number;
      paymentChannel: PaymentChannelEnum;
      callbackUrl: string;
      paymentMethod: PaymentMethodEnum;
      openId: string;
    }) => data,
    responseFormatter: (_, __, data?: ResultDtoComVsRevenueCashierManagerDto) =>
      data,
  },
  CampOrderController_replaceGuest_b78aa5: {
    method: 'post',
    url: '/api/reserve-camp-info/replace-guest',
    parameterFomatter: (data?: {
      id: number;
      paymentChannel: PaymentChannelEnum;
      callbackUrl: string;
      paymentMethod: PaymentMethodEnum;
      openId: string;
    }) => data,
    responseFormatter: (_, __, data?: ResultDtoComVsRevenueCashierManagerDto) =>
      data,
  },
  CampOrderController_queryPay_8d8662: {
    method: 'post',
    url: '/api/reserve-camp-info/query-pay',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  ReserveDayController_createReservePriceList_e20319: {
    method: 'post',
    url: '/api/reserve-camp-info/create-reserve-price-list',
    parameterFomatter: (data?: {
      btoParamList: CreateReservePriceBtoReserveCampInfoServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVoCampprogramvo[],
    ) => data,
  },
  ReserveDayController_updatePriceList_6c8aef: {
    method: 'post',
    url: '/api/reserve-camp-info/update-price-list',
    parameterFomatter: (data?: {
      priceBtoParam: UpdateReservePriceBtoReserveCampInfoServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveDayVoReserveCampInfoEntranceWebVoCampprogramvo[],
    ) => data,
  },
  ReserveCampInfoController_getCampPercentage_a0d39b: {
    method: 'post',
    url: '/api/reserve-camp-info/get-camp-percentage',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: CampPercentageDayVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  UserAccountController_sendVerifyCode_67447a: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/send-verify-code',
    parameterFomatter: (data?: { phone: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  UserAccountController_phoneAndVerifyLogin_7ea5da: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/phone-and-verify-login',
    parameterFomatter: (data?: {
      phone: string;
      verify: string;
      openIdCode: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_authorizePhoto_c90439: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/authorize-photo',
    parameterFomatter: (data?: { userId: number; isSwitch: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_getLoginUser_e63035: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/get-login-user',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_wechatLoginMini_7319df: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/wechat-login-mini',
    parameterFomatter: (data?: { code: string; openIdCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_queryScreenUserInfo_8c3958: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/query-screen-user-info',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ScreenUserInfoVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_userPasswordLogin_b21bf2: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/user-password-login',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_createUserPasswordForAdmin_f66883: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/create-user-password-for-admin',
    parameterFomatter: (data?: {
      btoParam: CreateUserPasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_selectUserType_2921ea: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/select-user-type',
    parameterFomatter: (data?: { userType: string; tab: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserTypeVoComVsUserAccountEntranceWebVo[],
    ) => data,
  },
  UserAccountController_queryLearningScreen_0fe423: {
    method: 'post',
    url: '/api/camping-area-user-account-entrance-web/query-learning-screen',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ScreenUserInfoVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_updatePassword_9a5392: {
    method: 'post',
    url: '/api/user-account/update-password',
    parameterFomatter: (data?: {
      btoParam: UpdatePasswordBtoComVsUserAccountServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_createRoleManage_35d453: {
    method: 'post',
    url: '/api/user-account/create-role-manage',
    parameterFomatter: (data?: {
      btoParam: CreateRoleManageBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: RoleManageVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_updateRoleManage_cef09c: {
    method: 'post',
    url: '/api/user-account/update-role-manage',
    parameterFomatter: (data?: {
      btoParam: UpdateRoleManageBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: RoleManageVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_updatePersonInfo_bd3b7a: {
    method: 'post',
    url: '/api/user-account/update-person-info',
    parameterFomatter: (data?: {
      btoParam: UpdatePersonInfoBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileIdVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_forgotPassword_1a101b: {
    method: 'post',
    url: '/api/user-account/forgot-password',
    parameterFomatter: (data?: {
      btoParam: ForgotPasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
        passWord: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  UserAccountController_deleteRoleManage_b0cafd: {
    method: 'post',
    url: '/api/user-account/delete-role-manage',
    parameterFomatter: (data?: {
      btoParam: DeleteRoleManageBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: RoleManageVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_deletePassword_e23731: {
    method: 'post',
    url: '/api/user-account/delete-password',
    parameterFomatter: (data?: {
      btoParam: DeletePasswordBtoComVsUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  UserAccountController_createUserPassword_f3a420: {
    method: 'post',
    url: '/api/user-account/create-user-password',
    parameterFomatter: (data?: {
      btoParam: CreateUserPasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
  UserAccountController_getAllByRoleManageNameStatusQto_e55e39: {
    method: 'post',
    url: '/api/user-account/get-all-by-role-manage-name-status-qto',
    parameterFomatter: (data?: {
      qto: RoleManageNameStatusQtoComVsUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: RoleManageVoComVsUserAccountEntranceWebVo[],
    ) => data,
  },
  UserAccountController_getAllByUserProfileQto_adf45b: {
    method: 'post',
    url: '/api/user-account/get-all-by-user-profile-qto',
    parameterFomatter: (data?: {
      qto: UserProfileQtoComVsUserAccountPersistQto;
      tab: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto[],
    ) => data,
  },
  UserAccountController_getAllByUserProfileUserQto_56c8c0: {
    method: 'post',
    url: '/api/user-account/get-all-by-user-profile-user-qto',
    parameterFomatter: (data?: {
      qto: UserProfileUserQtoComVsUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto[],
    ) => data,
  },
  MallCommodityController_queryPagefallByCommodityInfoFrontIdxQto_e95ff9: {
    method: 'post',
    url: '/api/mall-commodity/query-pagefall-by-commodity-info-front-idx-qto',
    parameterFomatter: (data?: {
      qto: CommodityInfoFrontIdxQtoMallCommodityPersistQto;
      ext: {
        userId: number;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CommodityInfoDtoMallCommodityManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  MallCommodityController_placeOrder_38840d: {
    method: 'post',
    url: '/api/mall-commodity/place-order',
    parameterFomatter: (data?: {
      btoParam: CreateOrderInfoBtoComVsOrderInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_getById_962cb6: {
    method: 'post',
    url: '/api/mall-commodity/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CommodityInfoVoMallCommodityEntranceWebVo,
    ) => data,
  },
  MallCommodityController_deleteReceiving_ef5979: {
    method: 'post',
    url: '/api/mall-commodity/delete-receiving',
    parameterFomatter: (data?: {
      deleteReceivingBto: DeleteReceivingBtoComVsOrderInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_quertReceivingList_5ac316: {
    method: 'post',
    url: '/api/mall-commodity/quert-receiving-list',
    parameterFomatter: (data?: {
      qto: ReceivingUserIdQtoComVsOrderInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceivingInfoDtoComVsOrderInfoManagerDto[],
    ) => data,
  },
  MallCommodityController_queryOrderList_2a7740: {
    method: 'post',
    url: '/api/mall-commodity/query-order-list',
    parameterFomatter: (data?: { userId: number; type: string }) => data,
    responseFormatter: (_, __, data?: OrderInfoDtoComVsOrderInfoManagerDto[]) =>
      data,
  },
  MallCommodityController_queryCommodityInfoLike_2011d4: {
    method: 'post',
    url: '/api/mall-commodity/query-commodity-info-like',
    parameterFomatter: (data?: {
      qto: CommodityInfoPublishQtoMallCommodityPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CommodityInfoDtoMallCommodityManagerDto[],
    ) => data,
  },
  MallCommodityController_getAllBySearchHistoryUserIdQto_87a507: {
    method: 'post',
    url: '/api/mall-commodity/get-all-by-search-history-user-id-qto',
    parameterFomatter: (data?: {
      qto: SearchHistoryUserIdQtoMallCommodityPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SearchHistoryDtoMallCommodityManagerDto[],
    ) => data,
  },
  MallCommodityController_deleteSearch_daf45a: {
    method: 'post',
    url: '/api/mall-commodity/delete-search',
    parameterFomatter: (data?: { userId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_queryCampInfoByUserId_0f21f3: {
    method: 'post',
    url: '/api/mall-commodity/query-camp-info-by-user-id',
    parameterFomatter: (data?: { userId: number }) => data,
    responseFormatter: (_, __, data?: CampInfoDtoReserveCampInfoManagerDto) =>
      data,
  },
  OssPolicyController_getOssPolicy_7c7b3a: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
  OssPolicyController_getOssPolicyForObs_740042: {
    method: 'post',
    url: '/api/external-api/get-oss-policy-for-obs',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
  OssPolicyController_obsUploadFile_4b9697: {
    method: 'post',
    url: '/api/external-api/upload',
    parameterFomatter: (data?: { file: MultipartFile }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OssPolicyController_getOssPolicyAuthForObs_ee8790: {
    method: 'post',
    url: '/api/external-api/get-oss-policy-auth-for-obs',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
  OrderInfoController_updateOrderInfo_2311bc: {
    method: 'post',
    url: '/api/order-info/update-order-info',
    parameterFomatter: (data?: {
      btoParam: UpdateOrderInfoBtoComVsOrderInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoAllVoComVsOrderInfoEntranceWebVo,
    ) => data,
  },
  OrderInfoController_deleteReceive_170eed: {
    method: 'post',
    url: '/api/order-info/delete-receive',
    parameterFomatter: (data?: {
      btoParam: DeleteReceivingBtoComVsOrderInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrderInfoController_queryPagedByCampOrderQto_8ad15d: {
    method: 'post',
    url: '/api/order-info/query-paged-by-camp-order-qto',
    parameterFomatter: (data?: {
      qto: CampOrderQtoComVsOrderInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrderSimpleVoComVsOrderInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderInfoController_queryOrderDesc_64e301: {
    method: 'post',
    url: '/api/camping-area-order-info-entrance-web/query-order-desc',
    parameterFomatter: (data?: { orderId: number; userId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoAllVoComVsOrderInfoEntranceWebVo,
    ) => data,
  },
  OrderInfoController_queryOrderScreen_9a40ff: {
    method: 'post',
    url: '/api/camping-area-order-info-entrance-web/query-order-screen',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderScreenVoComVsOrderInfoEntranceWebVo,
    ) => data,
  },
  OrderInfoController_queryOrderMonth_e2db8d: {
    method: 'post',
    url: '/api/camping-area-order-info-entrance-web/query-order-month',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderMonthVoComVsOrderInfoEntranceWebVo,
    ) => data,
  },
  LogsOperationController_queryPagedByOperationLogsIdxQto_93a253: {
    method: 'post',
    url: '/api/logs-operation/query-paged-by-operation-logs-idx-qto',
    parameterFomatter: (data?: {
      qto: OperationLogsIdxQtoLogsOperationPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OperationLogsVoLogsOperationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  AidManagementController_queryAidScreenInfo_71abda: {
    method: 'post',
    url: '/api/aid-management/query-aid-screen-info',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: AidScreenVoAidManagementEntranceWebVo) =>
      data,
  },
  AidManagementController_createAid_6defc4: {
    method: 'post',
    url: '/api/aid-management/create-aid',
    parameterFomatter: (data?: {
      btoParam: CreateAidBtoAidManagementServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AidInfoVoAidManagementEntranceWebVo) =>
      data,
  },
  ReserveMessageController_updateBatchRead_bc08b8: {
    method: 'post',
    url: '/api/reserve-message/update-batch-read',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ReserveTicketsController_createTickets_14e4be: {
    method: 'post',
    url: '/api/reserve-tickets/create-tickets',
    parameterFomatter: (data?: {
      btoParam: CreateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_createDateTickets_ad4141: {
    method: 'post',
    url: '/api/reserve-tickets/create-date-tickets',
    parameterFomatter: (data?: {
      btoParam: CreateDateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_updateDateTickets_9a814b: {
    method: 'post',
    url: '/api/reserve-tickets/update-date-tickets',
    parameterFomatter: (data?: {
      btoParam: UpdateDateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_updateTickets_923e34: {
    method: 'post',
    url: '/api/reserve-tickets/update-tickets',
    parameterFomatter: (data?: {
      btoParam: UpdateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_batchDeleteTickets_759754: {
    method: 'post',
    url: '/api/reserve-tickets/batch-delete-tickets',
    parameterFomatter: (data?: {
      btoParam: DeleteTicketsBtoReserveTicketsServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ReserveTicketsController_deleteDateTickets_46e8bc: {
    method: 'post',
    url: '/api/reserve-tickets/delete-date-tickets',
    parameterFomatter: (data?: {
      btoParam: DeleteDateTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_deleteTickets_ba2f66: {
    method: 'post',
    url: '/api/reserve-tickets/delete-tickets',
    parameterFomatter: (data?: {
      btoParam: DeleteTicketsBtoReserveTicketsServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TicketsVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_getByTicketsId_7e5eaf: {
    method: 'post',
    url: '/api/reserve-tickets/get-by-tickets-id',
    parameterFomatter: (data?: { ticketsId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  ReserveTicketsController_queryPagedByTicketsManageQto_7f3490: {
    method: 'post',
    url: '/api/reserve-tickets/query-paged-by-tickets-manage-qto',
    parameterFomatter: (data?: {
      qto: TicketsManageQtoReserveTicketsPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TicketsVoReserveTicketsEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveTicketsController_queryPagefallByMiniTicketsQto_a5f653: {
    method: 'post',
    url: '/api/reserve-tickets/query-pagefall-by-mini-tickets-qto',
    parameterFomatter: (data?: {
      qto: MiniTicketsQtoReserveTicketsPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TicketsVoReserveTicketsEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ReserveTicketsController_updateTicketPriceList_07231b: {
    method: 'post',
    url: '/api/reserve-tickets/update-price-list',
    parameterFomatter: (data?: {
      priceBtoParam: UpdateDateTicketsBtoReserveTicketsServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  ReserveTicketsController_createTicketPriceList_d4e5b4: {
    method: 'post',
    url: '/api/reserve-tickets/create-reserve-price-list',
    parameterFomatter: (data?: {
      btoParamList: CreateDateTicketsBtoReserveTicketsServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsDateVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  ReserveTicketsController_getByTickets_e885c6: {
    method: 'post',
    url: '/api/reserve-tickets/get-by-tickets',
    parameterFomatter: (data?: { ticketsId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsAllVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_getByTicketsPrice_5529c3: {
    method: 'post',
    url: '/api/reserve-tickets/get-by-tickets-price',
    parameterFomatter: (data?: {
      priceVo: PriceVoReserveTicketsEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: PriceVoReserveTicketsEntranceWebVo) =>
      data,
  },
  ReserveTicketsController_queryTicketsScreen_1d7078: {
    method: 'post',
    url: '/api/reserve-tickets/query-tickets-screen',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsScreenVoReserveTicketsEntranceWebVo,
    ) => data,
  },
  ReserveTicketsController_queryTicketsReal_fadf2a: {
    method: 'post',
    url: '/api/reserve-tickets/query-tickets-real',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: TicketsRealVoReserveTicketsEntranceWebVo[],
    ) => data,
  },
  FrontResearchLearningController_queryAllByFrontResearchLearningQto_2f1b94: {
    method: 'post',
    url: '/api/front-research-learning/query-all-by-front-research-learning-qto',
    parameterFomatter: (data?: {
      qto: FrontResearchLearningQtoSimpleFrontResearchLearningPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: FrontResearchVoFrontResearchLearningEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FrontResearchLearningController_createFrontLearning_320c57: {
    method: 'post',
    url: '/api/front-research-learning/create-front-learning',
    parameterFomatter: (data?: {
      btoParam: CreateFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
  FrontResearchLearningController_deleteFrontLearning_e447a0: {
    method: 'post',
    url: '/api/front-research-learning/delete-front-learning',
    parameterFomatter: (data?: {
      btoParam: DeleteFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
  FrontResearchLearningController_updateFrontLearning_bf7b9f: {
    method: 'post',
    url: '/api/front-research-learning/update-front-learning',
    parameterFomatter: (data?: {
      btoParam: UpdateFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_batchDelete_5fb534: {
    method: 'post',
    url: '/api/reserve-research-learning/batch-delete',
    parameterFomatter: (data?: {
      btos: DeleteResearchLearningBtoReserveResearchLearningServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo[],
    ) => data,
  },
  ReserveResearchLearningController_createResearchLearning_b668e9: {
    method: 'post',
    url: '/api/reserve-research-learning/create-research-learning',
    parameterFomatter: (data?: {
      btoParam: CreateResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_deleteResearchLearning_c2db63: {
    method: 'post',
    url: '/api/reserve-research-learning/delete-research-learning',
    parameterFomatter: (data?: {
      btoParam: DeleteResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_getById_d95e29: {
    method: 'post',
    url: '/api/reserve-research-learning/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningAndStockVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_queryPagedByQueryResearchLearningByManageQto_2f861a:
    {
      method: 'post',
      url: '/api/reserve-research-learning/query-paged-by-query-research-learning-by-manage-qto',
      parameterFomatter: (data?: {
        qto: QueryResearchLearningByManageQtoReserveResearchLearningPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ReserveResearchLearningController_queryPagefallByQueryResearchLearningByFrontQto_d40de8:
    {
      method: 'post',
      url: '/api/reserve-research-learning/query-pagefall-by-query-research-learning-by-front-qto',
      parameterFomatter: (data?: {
        qto: QueryResearchLearningByFrontQtoReserveResearchLearningPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ReserveResearchLearningController_updateResearchLearning_ade80c: {
    method: 'post',
    url: '/api/reserve-research-learning/update-research-learning',
    parameterFomatter: (data?: {
      btoParam: UpdateResearchLearningBtoReserveResearchLearningServiceBto;
      extApiParam: {
        flag: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveResearchLearningVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  ReserveResearchLearningController_queryResearchScreen_d63aea: {
    method: 'post',
    url: '/api/reserve-research-learning/query-research-screen',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ResearchScreenVoReserveResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_createTicketOrder_da0e9b: {
    method: 'post',
    url: '/api/order-research-learning/create-ticket-order',
    parameterFomatter: (data?: {
      btoParam: CreateLearningOrderBtoOrderResearchLearningServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_updateLearningOrder_45fd33: {
    method: 'post',
    url: '/api/order-research-learning/update-learning-order',
    parameterFomatter: (data?: {
      btoParam: UpdateLearningOrderBtoOrderResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_queryPagefallByResearchLearningOrderFrontQto_076d49:
    {
      method: 'post',
      url: '/api/order-research-learning/query-pagefall-by-research-learning-order-front-qto',
      parameterFomatter: (data?: {
        qto: ResearchLearningOrderFrontQtoOrderResearchLearningPersistQto;
        ext: {
          isHistory: string;
        };
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: FrontLearningOrderVoOrderResearchLearningEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrderResearchLearningController_getOrderDesc_1d93f0: {
    method: 'post',
    url: '/api/order-research-learning/get-order-desc',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontLearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  ResearchLearningOrderController_learnSubmitOrder_1078e6: {
    method: 'post',
    url: '/api/order-research-learning/learn-submit-order',
    parameterFomatter: (data?: {
      bto: CreateLearningOrderBtoOrderResearchLearningServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrderResearchLearningController_getById_4012ba: {
    method: 'post',
    url: '/api/order-research-learning/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: BackendLearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_deleteLearningOrder_810d5d: {
    method: 'post',
    url: '/api/order-research-learning/delete-learning-order',
    parameterFomatter: (data?: {
      btoParam: DeleteLearningOrderBtoOrderResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_auditRefundOrder_9168c8: {
    method: 'post',
    url: '/api/order-research-learning/audit-refund-order',
    parameterFomatter: (data?: {
      auditRefundVo: AuditRefundVoOrderResearchLearningEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  OrderResearchLearningController_payTicketsOrder_c7849b: {
    method: 'post',
    url: '/api/order-research-learning/pay-tickets-order',
    parameterFomatter: (data?: {
      id: number;
      paymentChannel: PaymentChannelEnum;
      callbackUrl: string;
      paymentMethod: PaymentMethodEnum;
      openId: string;
    }) => data,
    responseFormatter: (_, __, data?: ResultDtoComVsRevenueCashierManagerDto) =>
      data,
  },
  OrderResearchLearningController_cancelOrder_621984: {
    method: 'post',
    url: '/api/order-research-learning/cancel-order',
    parameterFomatter: (data?: {
      updateOrderInfoBto: UpdateLearningOrderBtoOrderResearchLearningServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderResearchLearningController_refundTicketsOrder_01b677: {
    method: 'post',
    url: '/api/order-research-learning/refund-itinerary-order',
    parameterFomatter: (data?: {
      updateOrderInfoBto: UpdateLearningOrderBtoOrderResearchLearningServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrderResearchLearningController_queryLearningOrderScreen_e773d4: {
    method: 'post',
    url: '/api/order-research-learning/query-learning-order-screen',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderScreenVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_cancelRefund_be40d4: {
    method: 'post',
    url: '/api/order-research-learning/cancel-refund',
    parameterFomatter: (data?: {
      btoParam: UpdateLearningOrderBtoOrderResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_merchantPay_35abf3: {
    method: 'post',
    url: '/api/order-research-learning/merchant-pay',
    parameterFomatter: (data?: {
      merchantId: number;
      paymentChannel: PaymentChannelEnum;
      callbackUrl: string;
      paymentMethod: PaymentMethodEnum;
      openId: string;
      userId: number;
      passWord: string;
      price: string;
    }) => data,
    responseFormatter: (_, __, data?: ResultDtoComVsRevenueCashierManagerDto) =>
      data,
  },
  OrderMerchantController_createMerchant_3413e0: {
    method: 'post',
    url: '/api/order-merchant/create-merchant',
    parameterFomatter: (data?: {
      btoParam: CreateMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_updateMerchant_186dfc: {
    method: 'post',
    url: '/api/order-merchant/update-merchant',
    parameterFomatter: (data?: {
      btoParam: UpdateMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_deleteMerchant_a447e3: {
    method: 'post',
    url: '/api/order-merchant/delete-merchant',
    parameterFomatter: (data?: {
      btoParam: DeleteMerchantBtoOrderMerchantServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_deleteBatchMerchant_709dfc: {
    method: 'post',
    url: '/api/order-merchant/delete-batch-merchant',
    parameterFomatter: (data?: {
      btoParam: DeleteMerchantBtoOrderMerchantServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantController_getPagedByMerchantQto_a88b3f: {
    method: 'post',
    url: '/api/order-merchant/get-paged-by-merchant-qto',
    parameterFomatter: (data?: { qto: MerchantQtoOrderMerchantPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MerchantInfoVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantApprovalController_getPagedByApprovalQto_5db8bb: {
    method: 'post',
    url: '/api/order-merchant/get-by-approval-qto',
    parameterFomatter: (data?: { qto: ApprovalQtoOrderMerchantPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ApprovalInfoVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantApprovalController_approval_8a4284: {
    method: 'post',
    url: '/api/order-merchant/approval',
    parameterFomatter: (data?: {
      btoParam: UpdateApprovalBtoOrderMerchantServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApprovalInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantVoucherController_getPagedByConsumeVoucherQto_d3715a: {
    method: 'post',
    url: '/api/order-merchant/get-paged-by-consume-voucher-qto',
    parameterFomatter: (data?: {
      qto: ConsumeVoucherQtoOrderMerchantPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ConsumeVoucherVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantController_queryAmountManage_725778: {
    method: 'post',
    url: '/api/order-merchant/query-amount-manage',
    parameterFomatter: (data?: { year: number; month: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: AmountManageVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_merchantWorkPay_fb2f9b: {
    method: 'post',
    url: '/api/order-merchant/merchant-work-pay',
    parameterFomatter: (data?: {
      merchantId: number;
      userId: number;
      passWord: string;
      price: string;
      phone: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderMerchantFrontController_setPayPassWord_44412d: {
    method: 'post',
    url: '/api/order-merchant/set-pay-pass-word',
    parameterFomatter: (data?: {
      bto: CreateWorkPasswordBtoOrderMerchantServiceBto;
      ext: {
        verify: string;
      };
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrderMerchantVoucherController_getConsumeVoucherVoByPhone_32f823: {
    method: 'post',
    url: '/api/order-merchant/get-consume-voucher-vo-by-phone',
    parameterFomatter: (data?: { phone: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ConsumeVoucherVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantFrontController_queryTransactionRecord_851d21: {
    method: 'post',
    url: '/api/order-merchant/query-transaction-record',
    parameterFomatter: (data?: {
      qto: TransactionRecordFrontQtoOrderMerchantPersistQto;
      date: string;
      model: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TransactionRecordVoOrderMerchantEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderMerchantController_getMerchantInfoVoByPhone_cb949b: {
    method: 'post',
    url: '/api/order-merchant/get-merchant-info-vo-by-phone',
    parameterFomatter: (data?: { phone: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_getMerchantInfoVoById_a43be6: {
    method: 'post',
    url: '/api/order-merchant/get-merchant-info-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MerchantInfoVoOrderMerchantEntranceWebVo,
    ) => data,
  },
  ReserveGroupBuyController_getGroupBuyManageVoById_805fdc: {
    method: 'post',
    url: '/api/reserve-group-buy/get-group-buy-manage-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: GroupBuyManageVoReserveGroupBuyEntranceWebVo,
    ) => data,
  },
  ReserveGroupBuyController_getPagedByQueryGroupBuyManageQto_8923e6: {
    method: 'post',
    url: '/api/reserve-group-buy/get-paged-by-query-group-buy-manage-qto',
    parameterFomatter: (data?: {
      qto: QueryGroupBuyManageQtoReserveGroupBuyPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: GroupBuyManageVoReserveGroupBuyEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
