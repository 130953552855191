import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReserveCampPlanController_createCampPlan_1cf65e: {
    method: 'post',
    url: '/api/reserve-camp-info/create-camp-plan',
    parameterFomatter: (data?: {
      btoParam: CreateCampPlanBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampPlanVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  ReserveCampPlanController_getAllByCampPlanListQto_71a42e: {
    method: 'post',
    url: '/api/reserve-camp-info/get-all-by-camp-plan-list-qto',
    parameterFomatter: (data?: {
      qto: CampPlanListQtoReserveCampInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CampPlanVoReserveCampInfoEntranceWebVo[],
    ) => data,
  },
  ReserveCampPlanController_updateCampPlan_d8cb5e: {
    method: 'post',
    url: '/api/reserve-camp-info/update-camp-plan',
    parameterFomatter: (data?: {
      btoParam: UpdateCampPlanBtoReserveCampInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CampPlanVoReserveCampInfoEntranceWebVo) =>
      data,
  },
  OssPolicyController_getOssPolicy_7c7b3a: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoExternalApiEntranceWebVo) =>
      data,
  },
  UserAccountController_forgotPassword_1a101b: {
    method: 'post',
    url: '/api/user-account/forgot-password',
    parameterFomatter: (data?: {
      btoParam: ForgotPasswordBtoComVsUserAccountServiceBto;
      extApiParam: {
        verify: string;
        passWord: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: UserProfileDtoComVsUserAccountManagerDto,
    ) => data,
  },
  MallCommodityController_getAllByCommodityInfoPublishQto_10ca09: {
    method: 'post',
    url: '/api/mall-commodity/get-all-by-commodity-info-publish-qto',
    parameterFomatter: (data?: {
      qto: CommodityInfoPublishQtoMallCommodityPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: CommodityInfoDtoMallCommodityManagerDto[],
    ) => data,
  },
});
