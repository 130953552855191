import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
  Breadcrumb,
} from '@vs/vsf-kit';
import React, { useCallback, useState, useEffect } from 'react';
import AccountForm from '@pages/AccountForm';
import storage from '@/utils/storage';
import styled from 'styled-components';

const ManageContainer = styled.div``;
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const AccountManageTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [refresh, setRefresh] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [userTypeList, setUserTypeList] = useState();
  const data = JSON.parse(storage.get('userData') ?? '{}');
  const platform =
    storage.get('platform') === 'LEARNING_ADMIN'
      ? 'learning'
      : storage.get('platform') === 'CAMP_ADMIN'
        ? 'camp'
        : storage.get('platform');
  useEffect(() => {
    vsf.services
      ?.UserAccountController_selectUserType_2921ea?.({
        userType: data?.userType,
        tab: platform,
      })
      .then((res) => {
        if (res && res?.code === 200) {
          setUserTypeList(res?.data);
        }
      });
  }, []);
  const rederIndex = (_, __, index) => {
    return (
      <Container>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </Container>
    );
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.UserAccountController_getAllByUserProfileQto_adf45b?.(
        {
          tab: params?.search?.userTypeIn ? undefined : platform,
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            userTypeIn: params.search.userTypeIn
              ? [params.search.userTypeIn]
              : undefined,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <Section>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>账号管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <VSPromiseTable
        editable={{
          editType: 'single',
          onCanDelete: () => false,
          // deleteConfirmProps: {
          //   title: '账号删除后不可恢复，是否确认删除？',
          //   icon: <></>,
          // },
        }}
        vsid="90361"
        onFetch={onFetch}
        extraParams={{ refresh }}
        pagination={{
          onChange(currentIndex, _pageSize) {
            setPageIndex(currentIndex - 1);
            setPageSize(_pageSize);
          },
        }}
        onRemove={async (params) => {
          const res =
            await vsf.services?.UserAccountController_deletePassword_e23731?.({
              btoParam: params,
              extApiParam: params?.extApiParam,
            });
          if (res && res?.code === 200) {
            message.success('删除成功');
          }
          return res?.code === 200;
        }}
        addLinkPage={{
          linkPage: () => <AccountForm userTypeList={userTypeList} />,
          modalProps: { width: 550, title: '创建账号' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.AccountForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.UserAccountController_createUserPasswordForAdmin_f66883?.(
                  {
                    btoParam: {
                      ...fromParam,
                      accountUserBtoList: [
                        {
                          iBindingAccountBase: {
                            userName: fromParam?.nickname,
                            '@class':
                              'com.vs.user_account.service.bto.CreateUserPasswordBto$UserPasswordBto',
                            userType: fromParam?.userType,
                            password: fromParam?.password,
                          },
                        },
                      ],
                    },
                  },
                );

              if (res && res?.code === 200) {
                message.success('添加成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <AccountForm userTypeList={userTypeList} />,
          modalProps: { width: 500, title: '编辑账号' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.AccountForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.UserAccountController_updatePassword_9a5392?.(
                  {
                    btoParam: {
                      ...fromParam,
                      accountUserBtoList: [
                        {
                          iBindingAccountBase: {
                            userName: fromParam?.nickname,
                            userType: params?.userType,
                            '@class':
                              'com.vs.user_account.service.bto.UpdatePasswordBto$UserPasswordBto',
                          },
                        },
                      ],
                    },
                  },
                );
              if (res && res?.code === 200) {
                message.success('编辑成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['index']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={rederIndex}
        />

        <VSTableColumn
          dataIndex={['nickname']}
          title="用户名"
          valueType="text"
          order={6}
          columnKey={['nicknameLike']}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['phone']}
          title="手机号"
          valueType="text"
          search
          order={7}
          columnKey={['phoneIs']}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['userType']}
          title="角色"
          valueType="select"
          search
          order={5}
          columnKey={['userTypeIn']}
          dataSource={userTypeList}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['status']}
          title="状态"
          valueType="select"
          order={3}
          search
          columnKey={['statusIs']}
          dataSource={[
            { label: '在用', value: 'NORMAL' },
            { label: '注销', value: 'BANNED' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['rolePermission']}
          title="权限"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableToolbar>
          <VSTableAddition children="创建账号" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(AccountManageTable);
