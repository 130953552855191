import vp2023901 from './vp2023901';
import vp2023903 from './vp2023903';
import vp2023904 from './vp2023904';
import vp2023905 from './vp2023905';
import vp2023906 from './vp2023906';
import vp2023908 from './vp2023908';
import vp2023910 from './vp2023910';
import vp2023911 from './vp2023911';
import vp2023912 from './vp2023912';
import vp2023915 from './vp2023915';
import vp2023916 from './vp2023916';
import vp2023917 from './vp2023917';
import vp2023919 from './vp2023919';
import vp2023920 from './vp2023920';
import vp2023921 from './vp2023921';
import vp2023922 from './vp2023922';
import vp2023923 from './vp2023923';
import vp2023925 from './vp2023925';
import vp2024202 from './vp2024202';
import vp2024301 from './vp2024301';
import vp2024501 from './vp2024501';
import vp2024502 from './vp2024502';
import vp2025001 from './vp2025001';
import vp2025401 from './vp2025401';
import vp2025901 from './vp2025901';
import vp2029510 from './vp2029510';
import vp2029514 from './vp2029514';
import vp2029515 from './vp2029515';
import vp2029517 from './vp2029517';
import vp2029519 from './vp2029519';
import vp2029603 from './vp2029603';
import vp2029802 from './vp2029802';
import vp2029903 from './vp2029903';
import vp2029904 from './vp2029904';
import vp2029905 from './vp2029905';
import vp2030503 from './vp2030503';
import vp2035001 from './vp2035001';
import vp2035101 from './vp2035101';
import vp2035202 from './vp2035202';
import vp2035203 from './vp2035203';
import vp2035401 from './vp2035401';
import vp2035402 from './vp2035402';
import vp2035801 from './vp2035801';
import vp2035802 from './vp2035802';
import vp2041005 from './vp2041005';
import vp2041006 from './vp2041006';
import vp2053004 from './vp2053004';
import vp2053005 from './vp2053005';
import vp2053101 from './vp2053101';
import vp2053102 from './vp2053102';
import vp2053103 from './vp2053103';
import vp2053104 from './vp2053104';

export default {
  ...vp2023901,
  ...vp2023903,
  ...vp2023904,
  ...vp2023905,
  ...vp2023906,
  ...vp2023908,
  ...vp2023910,
  ...vp2023911,
  ...vp2023912,
  ...vp2023915,
  ...vp2023916,
  ...vp2023917,
  ...vp2023919,
  ...vp2023920,
  ...vp2023921,
  ...vp2023922,
  ...vp2023923,
  ...vp2023925,
  ...vp2024202,
  ...vp2024301,
  ...vp2024501,
  ...vp2024502,
  ...vp2025001,
  ...vp2025401,
  ...vp2025901,
  ...vp2029510,
  ...vp2029514,
  ...vp2029515,
  ...vp2029517,
  ...vp2029519,
  ...vp2029603,
  ...vp2029802,
  ...vp2029903,
  ...vp2029904,
  ...vp2029905,
  ...vp2030503,
  ...vp2035001,
  ...vp2035101,
  ...vp2035202,
  ...vp2035203,
  ...vp2035401,
  ...vp2035402,
  ...vp2035801,
  ...vp2035802,
  ...vp2041005,
  ...vp2041006,
  ...vp2053004,
  ...vp2053005,
  ...vp2053101,
  ...vp2053102,
  ...vp2053103,
  ...vp2053104,
};
