import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const LearningCockpitManagerFrom = (props) => {
  return (
    <Section>
      <VSControlledForm id="learningCockpitManagerFrom" value={props?.value}>
        <VSFormLayout key="0" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['name']}
            label="名称"
            valueType="text"
            required
            readonly={!props?.isReadOnly}
            fieldProps={{
              maxLength: 15,
            }}
          />

          <VSFormItem
            name={['value']}
            label="数值"
            valueType="text"
            required={!props?.isRequired}
            fieldProps={{
              maxLength: 10,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(LearningCockpitManagerFrom);
