import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Breadcrumb,
  message,
  Switch,
} from '@vs/vsf-kit';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import CampCockpitManagerFrom from '@pages/CampCockpitManagerFrom';
import styled from 'styled-components';
const DiscountContainer = styled.div``;
const CampCockpitManagerList = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState();
  const [orderDataList, setOrderDataList] = useState();

  const getAllByAidStatusQto = async () => {
    const res =
      await vsf?.services?.ReserveDataManagementController_getAllByAidStatusQto_535164?.(
        {
          qto: {},
        },
      );
    if (res && res?.code === 200) {
      setStatus(res?.data?.[0]?.status);
    }
  };

  useEffect(() => {
    getAllByAidStatusQto();
  }, []);

  const handleSwithChange = async (e) => {
    setStatus(e);
    const res =
      await vsf.services?.ReserveDataManagementController_updateAidStatus_d5e027?.(
        {
          btoParam: {
            id: 101,
            status: e,
          },
        },
      );

    if (res && res?.code === 200) {
      message.success('修改成功');
      setTimeout(() => {
        getAllByAidStatusQto();
      }, 1000);
    }
  };

  const monthData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
        {
          qto: {
            modelIs: 'MONTH_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const campRealData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
        {
          qto: {
            modelIs: 'CAMP_REAL_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const userData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
        {
          qto: {
            modelIs: 'USER_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const orderData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
        {
          qto: {
            modelIs: 'ORDER_DATA',
          },
        },
      );

    const excludedNames = ['订单总数', '今日订单', '本周订单', '本月订单'];
    const partitionData = res?.data.filter(
      (item) => !excludedNames.includes(item.name),
    );
    setOrderDataList(partitionData);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const cammpInfoData = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
        {
          qto: {
            modelIs: 'CAMP_INFO_DATA',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderDiscount = (_, record) => {
    try {
      return (
        <DiscountContainer>
          <div>
            {record?.name !== '订单总数' &&
            record?.name !== '今日订单' &&
            record?.name !== '本周订单' &&
            record?.name !== '本月订单'
              ? `${record?.monthNum}%`
              : record?.monthNum}
          </div>
        </DiscountContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>驾驶舱管理</Breadcrumb.Item>
          <Breadcrumb.Item>驾驶舱数据管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section>
        <div className="tableTile">月度信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          extraParams={{ refresh }}
          pagination={false}
          onFetch={monthData}
          updateLinkPage={{
            linkPage: () => <CampCockpitManagerFrom isReadOnly={true} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.campCockpitManageFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateCampingInformation_34b9c9?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'MONTH_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['monthNum']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">营地实时数据</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={campRealData}
          updateLinkPage={{
            linkPage: () => <CampCockpitManagerFrom isReadOnly={false} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.campCockpitManageFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateCampingInformation_34b9c9?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'CAMP_REAL_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['monthNum']}
            title="数值"
            render={renderDiscount}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">用户信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={userData}
          updateLinkPage={{
            linkPage: () => <CampCockpitManagerFrom isReadOnly={true} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.campCockpitManageFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateCampingInformation_34b9c9?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'USER_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['monthNum']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">订单信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={orderData}
          updateLinkPage={{
            linkPage: () => <CampCockpitManagerFrom isReadOnly={false} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.campCockpitManageFrom?.validateFieldsReturnFormatValue();
                const data = orderDataList
                  .filter((obj) => obj.id !== params?.id)
                  .reduce((sum, obj) => sum + Number(obj.monthNum), 0);

                if (data + Number(fromParam?.monthNum) > 100) {
                  message.warning('请填写所有营地百分比相加正确的数据');
                } else {
                  const res =
                    await vsf?.services?.ReserveDataManagementController_updateCampingInformation_34b9c9?.(
                      {
                        btoParam: {
                          ...fromParam,
                          id: params?.id,
                          model: 'ORDER_DATA',
                        },
                      },
                    );
                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      setRefresh(!refresh);
                      orderData();
                    }, 1000);
                  }
                  return res?.data;
                }
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['monthNum']}
            title="数值"
            render={renderDiscount}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div className="tableTile">营位信息</div>
        <VSPromiseTable
          editable={{ editType: 'single', onCanDelete: () => false }}
          id="campCockpitManagerList"
          pagination={false}
          onFetch={cammpInfoData}
          updateLinkPage={{
            linkPage: () => <CampCockpitManagerFrom isReadOnly={false} />,
            onLinkPageValidate: async (params) => {
              try {
                const fromParam =
                  await vsf?.refs?.campCockpitManageFrom?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.ReserveDataManagementController_updateCampingInformation_34b9c9?.(
                    {
                      btoParam: {
                        ...fromParam,
                        id: params?.id,
                        model: 'CAMP_INFO_DATA',
                      },
                    },
                  );
                if (res && res?.code === 200) {
                  message.success('编辑成功');
                  setTimeout(() => {
                    setRefresh(!refresh);
                  }, 1000);
                }
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: {
              title: '编辑',
              width: 500,
              okText: '确认',
              cancelText: '取消',
            },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="名称"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['monthNum']}
            title="数值"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>

      <Section
        style={{
          marginTop: '30px',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div className="tableTile">援助信息</div>
          <div className="tableIsShow">
            是否展示
            <Switch
              style={{
                marginLeft: '5px',
              }}
              checked={status}
              onChange={handleSwithChange}
            ></Switch>
          </div>
        </div>
      </Section>
    </div>
  );
};
export default definePage(CampCockpitManagerList);
