import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Switch } from '@vs/vsf-kit';
import * as echarts from 'echarts';
import React, { useEffect, useState, useRef } from 'react';
import {
  ScrollBoard,
  BorderBox7,
  BorderBox8,
  PercentPond,
  Decoration9,
  BorderBox12,
} from '@jiaminghi/data-view-react';
import titleBar from '@/assets/images/Cockpit/titleBar.png';
import userInfo from '@/assets/images/Cockpit/userInfo.png';
import leftBox from '@/assets/images/Cockpit/leftBox.png';
import leftTitle from '@/assets/images/Cockpit/left.png';
import numBox from '@/assets/images/Cockpit/numBox.png';
import rightBox from '@/assets/images/Cockpit/rightBox.png';
import rightTitle from '@/assets/images/Cockpit/right.png';
import month from '@/assets/images/Cockpit/month.png';
import bottom from '@/assets/images/Cockpit/bottom.png';
import campTitle from '@/assets/images/Cockpit/campTitle.png';
import systemTitle from '@/assets/images/Cockpit/systemTitle.png';
import clock from '@/assets/images/Cockpit/clock.png';
import camping from '@/assets/images/Cockpit/camping.png';
import TableLine from '@/assets/images/Cockpit/TableLine.png';
import { getDaysInCurrentMonth, hidePhoneNumber } from '@/utils';
import dayjs from 'dayjs';

const Cockpit = (props) => {
  const [messageList, setMessageList] = useState([]);
  const [userNum, setUserNum] = useState([]);
  const [orderNum, setOrderNum] = useState([]);
  const [monthNum, setMonthNum] = useState();
  const [monthSpell, setMonthSpell] = useState();
  const [helpDataInfo, setHelpDataInfo] = useState({});
  const [campDataInfo, setCampDataInfo] = useState({});
  const [campPieData, setCampPieData] = useState();
  const [userDataInfo, setUserDataInfo] = useState({});
  const [orderData, setOrderData] = useState({});
  const [change, setIsChange] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [status, setStatus] = useState(false);
  const timer = useRef();

  const loadAllServiceData = () => {
    loadUserInfo();
    loadOrderInfo();
    loadCampInfo();
    loadLogsData();
    loadAidScreenInfo();
    loadOrderMonth();
    loadCampPieData();
  };

  const getAllByAidStatusQto = async () => {
    const res =
      await vsf?.services?.ReserveDataManagementController_getAllByAidStatusQto_535164?.(
        {
          qto: {},
        },
      );
    if (res && res?.code === 200) {
      setStatus(res?.data?.[0]?.status);
    }
  };

  const loadCampPieData = () => {
    if (change) {
      vsf?.services
        ?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
          {
            qto: {
              modelIs: 'CAMP_REAL_DATA',
            },
          },
        )
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            const data = resposne?.data?.map((item) => {
              return {
                partitionName: item?.name,
                percentage: `${item?.monthNum}%`,
              };
            });
            setCampPieData(data);
            setTimeout(() => {
              loadPieEcharts(data);
            }, 1);
          }
        });
    } else {
      vsf?.services
        ?.ReserveCampInfoController_getCampPercentage_a0d39b?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setCampPieData(resposne?.data);
            setTimeout(() => {
              loadPieEcharts(resposne?.data);
            }, 1);
          }
        });
    }
  };

  const loadUserInfo = async () => {
    var currentMonth = new Date().getMonth() + 1;
    var monthAbbr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ][new Date().getMonth()];
    setMonthNum(currentMonth);
    setMonthSpell(monthAbbr);

    if (change) {
      const initialData =
        await vsf?.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
          {
            qto: {
              modelIs: 'USER_DATA',
            },
          },
        );

      if (initialData && initialData?.code === 200) {
        const data = {
          screenUserTotal: initialData?.data.find(
            (item) => item.name === '总数',
          )?.monthNum,
          todayOrderUser: initialData?.data.find(
            (item) => item.name === '今日下单数',
          )?.monthNum,
          addUser: initialData?.data.find((item) => item.name === '新增用户数')
            ?.monthNum,
          userInfo: [
            {
              nickname: '用户c362c',
              gender: 'MAN',
              phone: '15217843525',
            },
            {
              nickname: '用户a392c',
              gender: 'WOMAN',
              phone: '13851561465',
            },
            {
              nickname: '用户z372c',
              gender: 'WOMAN',
              phone: '15846541215',
            },
            {
              nickname: '用户t332c',
              gender: 'MAN',
              phone: '15259846565',
            },
            {
              nickname: '用户y312c',
              gender: 'WOMAN',
              phone: '13755462535',
            },
            {
              nickname: '用户h332c',
              gender: 'WOMAN',
              phone: '13845431258',
            },
            {
              nickname: '用户j322c',
              gender: 'MAN',
              phone: '18654135785',
            },
          ],
        };
        setUserDataInfo(data);
      }

      const res =
        await vsf?.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
          {
            qto: {
              modelIs: 'MONTH_DATA',
            },
          },
        );

      if (res && res?.code === 200) {
        var newArray = [];
        for (let item of res?.data?.[1]?.monthNum?.toString() ?? []) {
          newArray.push(Number(item));
        }

        if (newArray?.length === 4) {
          setUserNum(newArray);
        } else if (newArray?.length === 3) {
          newArray.unshift(0);
          setUserNum(newArray);
        } else if (newArray?.length === 2) {
          newArray.unshift(0);
          newArray.unshift(0);
          setUserNum(newArray);
        } else if (newArray?.length === 1) {
          newArray.unshift(0);
          newArray.unshift(0);
          newArray.unshift(0);
          setUserNum(newArray);
        }
      }
    } else {
      const res = await vsf?.services
        ?.UserAccountController_queryScreenUserInfo_8c3958?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setUserDataInfo(resposne?.data);
            var newArray = [];
            for (let item of resposne?.data?.monthTotal.toString() ?? []) {
              newArray.push(Number(item));
            }
            if (newArray?.length === 4) {
              setUserNum(newArray);
            } else if (newArray?.length === 3) {
              newArray.unshift(0);
              setUserNum(newArray);
            } else if (newArray?.length === 2) {
              newArray.unshift(0);
              newArray.unshift(0);
              setUserNum(newArray);
            } else if (newArray?.length === 1) {
              newArray.unshift(0);
              newArray.unshift(0);
              newArray.unshift(0);
              setUserNum(newArray);
            }
          }
        });
    }
  };

  const loadOrderInfo = async () => {
    if (change) {
      const respose =
        await vsf?.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
          {
            qto: {
              modelIs: 'ORDER_DATA',
            },
          },
        );

      if (respose && respose?.code === 200) {
        const excludedNames = ['订单总数', '今日订单', '本周订单', '本月订单'];
        const partitionData = respose?.data.filter(
          (item) => !excludedNames.includes(item.name),
        );
        const renamedData = partitionData.map((item) => ({
          ...item,
          partitionName: item.name,
          percentage: item.monthNum,
        }));

        const data = {
          partitionInfo: renamedData,
          orderTotal: respose?.data.find((item) => item.name === '订单总数')
            ?.monthNum,
          todayOrder: respose?.data.find((item) => item.name === '今日订单')
            ?.monthNum,
          weekOrder: respose?.data.find((item) => item.name === '本周订单')
            ?.monthNum,
          monthOrder: respose?.data.find((item) => item.name === '本月订单')
            ?.monthNum,
        };

        setOrderData(data);
        setTimeout(() => {
          loadOrderEcharts(data);
        }, 1);
      }

      const res =
        await vsf?.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
          {
            qto: {
              modelIs: 'MONTH_DATA',
            },
          },
        );

      if (res && res?.code === 200) {
        var newArray = [];
        for (let item of res?.data?.[0]?.monthNum?.toString() ?? []) {
          newArray.push(Number(item));
        }

        if (newArray?.length === 4) {
          setOrderNum(newArray);
        } else if (newArray?.length === 3) {
          newArray.unshift(0);
          setOrderNum(newArray);
        } else if (newArray?.length === 2) {
          newArray.unshift(0);
          newArray.unshift(0);
          setOrderNum(newArray);
        } else if (newArray?.length === 1) {
          newArray.unshift(0);
          newArray.unshift(0);
          newArray.unshift(0);
          setOrderNum(newArray);
        }
      }
    } else {
      const res = await vsf?.services
        ?.OrderInfoController_queryOrderScreen_9a40ff?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setOrderData(resposne?.data);
            setTimeout(() => {
              loadOrderEcharts(resposne?.data);
            }, 1);
            var newArray = [];
            for (let item of resposne?.data?.allMonthOrder.toString() ?? []) {
              newArray.push(Number(item));
            }
            if (newArray?.length === 4) {
              setOrderNum(newArray);
            } else if (newArray?.length === 3) {
              newArray.unshift(0);
              setOrderNum(newArray);
            } else if (newArray?.length === 2) {
              newArray.unshift(0);
              newArray.unshift(0);
              setOrderNum(newArray);
            } else if (newArray?.length === 1) {
              newArray.unshift(0);
              newArray.unshift(0);
              newArray.unshift(0);
              setOrderNum(newArray);
            }
          }
        });
    }
  };

  const loadCampInfo = async () => {
    if (change) {
      const respose =
        await vsf?.services?.ReserveDataManagementController_getAllByCampingInformationQto_fb9e72?.(
          {
            qto: {
              modelIs: 'CAMP_INFO_DATA',
            },
          },
        );

      if (respose && respose?.code === 200) {
        const excludedNames = ['预约营位数', '可用营位'];
        const partitionData = respose?.data.filter(
          (item) => !excludedNames.includes(item.name),
        );
        const renamedData = partitionData.map((item) => ({
          ...item,
          campNo: item.name,
          campNum: item.monthNum,
        }));

        const data = {
          partitionInfo: renamedData,
          usedCampNum: respose?.data.find((item) => item.name === '可用营位')
            ?.monthNum,
          reserveCampNum: respose?.data.find(
            (item) => item.name === '预约营位数',
          )?.monthNum,
        };

        setCampDataInfo(data);
        setTimeout(() => {
          loadCampEcharts(data?.partitionInfo);
        }, 1);
      }
    } else {
      const res = await vsf?.services
        ?.ReserveCampInfoController_getScreenCampInfo_a2c9d2?.({})
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setCampDataInfo(resposne?.data);
            loadCampEcharts(resposne?.data?.reserveCampCount);
          }
        });
    }
  };

  const loadOrderMonth = async () => {
    const res = await vsf?.services
      ?.OrderInfoController_queryOrderMonth_e2db8d?.({})
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          loadInformationEcharts(resposne?.data, getDaysInCurrentMonth());
        }
      });
  };

  useEffect(() => {
    loadAllServiceData();
    getAllByAidStatusQto();
    timer.current = setInterval(
      () => {
        loadAllServiceData();
      },
      24 * 60 * 60 * 1000,
    );
    return () => {
      clearInterval(timer.current);
    };
  }, [change]);
  const loadInformationEcharts = (orderMonth, monthDate) => {
    const monthData = [];
    monthDate?.forEach((item, index) => {
      monthData.push([
        item.split('-')[2],
        orderMonth?.orderMonthInfo?.find((v) => v?.day === item)?.orderNum ??
          '',
      ]);
    });
    const chartDom = document.getElementById('information_data_echarts');
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption({
        tooltip: {
          axisPointer: {
            type: 'axis',
            backgroundColor: '#007186',
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '30%'],
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#013A3F',
            },
          },
          interval: 100,
        },
        visualMap: {
          type: 'piecewise',
          show: false,
          dimension: 0,
          seriesIndex: 0,
          pieces: [
            {
              gt: 1,
              lt: 3,
              color: '#004051',
            },
            {
              gt: 5,
              lt: 7,
              color: '#002832',
            },
          ],
        },
        series: [
          {
            type: 'line',
            showSymbol: true,
            symbol: 'circle',
            smooth: true,
            symbolSize: 8,
            lineStyle: {
              color: '#33F2FF',
              width: 4,
            },
            itemStyle: {
              normal: {
                borderColor: '#33F2FF', // 拐点边框颜色
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0.4,
                  color: '#003d4e',
                },
                {
                  offset: 1,
                  color: '#002833',
                },
              ]),
            },

            data: monthData,
          },
        ],
      });
    }
  };

  const loadOrderEcharts = (orderData) => {
    const chartDom = document.getElementById('order_data_echarts');
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption({
        series: [
          {
            type: 'pie',
            clockwise: false,
            radius: ['50%', '70%'],
            data: orderData?.partitionInfo?.map((item) => {
              return { name: item?.partitionName, value: item?.percentage };
            }),
            itemStyle: {
              normal: {
                label: {
                  show: true, // 显示标签
                  formatter: function (params) {
                    return `{name|${params?.data?.name}}\n{time|${params?.data?.value} %}`;
                  },
                  rich: {
                    name: {
                      fontSize: 14,
                      color: '#D6D7D9',
                    },
                    time: {
                      fontSize: 16,
                      color: '#33F2FF',
                      padding: [10, 4, 5, 6],
                    },
                  },
                },
              },
            },
            labelLine: {
              normal: {
                length: 15, // 指示线长度
                lineStyle: {
                  color: '#0DBFDE', // 指示线颜色
                },
              },
            },

            label: {
              normal: {
                textStyle: {
                  color: '#D6D7D9', // 提示文字颜色
                  fontSize: 14, // 提示文字大小
                },
              },
            },

            color: [
              '#00f0fd',
              '#0048f9',
              '#3997f1',
              '#00ffc5',
              '#ffc16d',
              '#e4f080',
            ],
          },
        ],
      });
    }
  };

  const loadCampEcharts = (campData) => {
    const sortedData = campData.sort((a, b) => a.campNum - b.campNum);
    const chartDom = document.getElementById('camp_data_echarts');
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          itemGap: 20,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '2%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          show: false,
          order: 'desc',
        },
        yAxis: {
          type: 'category',
          data: sortedData.map((item) => item.campNo),
          axisLabel: {
            interval: 0,
            // rotate: 45,
          },
        },
        series: [
          {
            type: 'bar',
            data: sortedData.map((item) => item.campNum),
            barMaxWidth: 30,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: '#0099eb',
                },
                {
                  offset: 1,
                  color: '#2bf5fc',
                },
              ]),
            },
          },
        ],
      });
    }
  };

  const loadLogsData = () => {
    if (change) {
      const data = [
        {
          content: '修改了用户信息',
          userAccount: '用户183fbc',
          createdAt: dayjs().format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '新增了商品信息',
          userAccount: '用户123fbc',
          createdAt: dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '编辑了首页Banner',
          userAccount: '用户44fbc',
          createdAt: dayjs().subtract(2, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '新增了活动',
          userAccount: '用户6fbc',
          createdAt: dayjs().subtract(3, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '新增了首页Banner',
          userAccount: '用户555fbc',
          createdAt: dayjs().subtract(5, 'hour').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '批量删除了商品',
          userAccount: '用户22fbc',
          createdAt: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '变成了首页Banner',
          userAccount: '用户36fbc',
          createdAt: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm'),
        },
        {
          content: '修改了商品',
          userAccount: '用户1fbc',
          createdAt: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm'),
        },
      ];
      setMessageList(data);
    } else {
      const res = vsf?.services
        ?.LogsOperationController_queryPagedByOperationLogsIdxQto_93a253?.({
          qto: {
            from: 0,
            size: 1000,
            projectTypeIs: 'camp',
          },
          ext: {},
        })
        .then((resposne) => {
          if (resposne && resposne?.code === 200) {
            setMessageList(resposne?.data?.result);
          }
        });
    }
  };

  const loadAidScreenInfo = () => {
    const res = vsf?.services
      ?.AidManagementController_queryAidScreenInfo_71abda?.({})
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setHelpDataInfo(resposne?.data);
        }
      });
  };

  const loadPieEcharts = (pieData) => {
    pieData?.forEach((item, index) => {
      const Id = 'information_item_echarts' + index;
      const chartDom = document.getElementById(Id);
      if (chartDom) {
        const myChart = echarts.init(chartDom);
        myChart.setOption({
          series: [
            {
              type: 'pie',
              radius: ['40', '50'],
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: Number(item?.percentage.replace(/%/g, '')),
                  selected: false,
                  itemStyle: {
                    color: '#33F2FF',
                    borderWidth: 2,
                    borderRadius: 25,
                  },
                  label: {
                    // 是显示标签
                    show: true,
                    position: 'center',
                    fontSize: 22,
                    formatter: function (params) {
                      return `{name|${item?.partitionName}\n${item.percentage}}`;
                    },
                    rich: {
                      name: {
                        fontSize: 18,
                        color: '#33F2FF',
                        lineHeight: 22,
                      },
                      time: {
                        fontSize: 14,
                        color: '#33F2FF',
                        lineHeight: 22,
                      },
                    },
                  },
                },
                {
                  value: 100 - Number(item?.percentage.replace(/%/g, '')),
                  name: '',
                  itemStyle: {
                    color: '#003845',
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
          ],
        });
      }
    });
  };

  return (
    <div className="CockpitContainer">
      <div className="CockpitContainer_title">
        <img src={titleBar} className="CockpitContainer_title__img" alt="" />
        <div
          className="setIcon"
          onClick={() => {
            setIsShow(!isShow);
          }}
        >
          设置
        </div>
        {isShow && (
          <>
            <div className="setIsChange">
              <div className="changeName">切换为测试数据</div>
              <div className="changeDio">
                <Switch
                  defaultValue={change}
                  onChange={(checked) => {
                    setIsChange(checked);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="CockpitContainer_content">
        <div className="CockpitContainer_content__left">
          <div className="CockpitContainer_content__left__title">
            <img
              src={userInfo}
              alt=""
              className="CockpitContainer_content__img"
            />

            <div className="CockpitContainer_content__text">用户信息</div>
          </div>

          <div className="CockpitContainer_content__left__box">
            <BorderBox7 color={['#0DBFDE']} style={{ boxShadow: 'none' }}>
              <div className="CockpitContainer_content__boxTitle">
                <div className="CockpitContainer_content__boxTitle__Box">
                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">总数</div>
                        <div className="content_titleBox__value">
                          {userDataInfo?.screenUserTotal ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>

                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">今日下单数</div>
                        <div className="content_titleBox__value">
                          {userDataInfo?.todayOrderUser ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>

                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">新增用户数</div>
                        <div className="content_titleBox__value">
                          {userDataInfo?.addUser ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>
                </div>
              </div>
              <div className="CockpitContainer_content__list">
                <ScrollBoard
                  config={{
                    header: [' 昵称', '性别', '手机号码'],
                    data: (userDataInfo?.userInfo ?? [])?.map((item) => [
                      item?.nickname ?? '',
                      item?.gender === 'MAN'
                        ? '男'
                        : item?.gender === 'WOMAN'
                          ? '女'
                          : '未知',
                      hidePhoneNumber(item?.phone),
                    ]),
                    rowNum: 6,
                    oddRowBGC: 'transparent',
                    headerBGC: '#006377',
                    headerHeight: 30,
                    align: ['center', 'center', 'center'],
                    hoverPause: false,
                  }}
                  style={{ width: '100%', height: '25vh' }}
                />
              </div>
            </BorderBox7>
          </div>
        </div>

        <div className="CockpitContainer_content__center">
          <div className="content_center__title">
            <div className="center_title__left">
              <div className="center_title__top"></div>

              <div className="center_title__bottom">
                <div className="center_title__numBox">{userNum[0] ?? 0}</div>
                <div className="center_title__numBox">{userNum[1] ?? 0}</div>
                <div className="center_title__numBox">{userNum[2] ?? 0}</div>
                <div className="center_title__numBox">{userNum[3] ?? 0}</div>
              </div>
            </div>

            <div className="center_title__center">
              <div className="center_title__center__box">
                <div className="center_title__center__num">
                  {monthNum >= 10 ? monthNum : '0' + monthNum ?? ''}
                </div>
                <div className="center_title__center__egl">
                  {monthSpell ?? ''}.
                </div>
              </div>
            </div>

            <div className="center_title__right">
              <div className="center_title__top"></div>

              <div className="center_title__bottom">
                <div className="center_title__numBox">{orderNum[0] ?? 0}</div>
                <div className="center_title__numBox">{orderNum[1] ?? 0}</div>
                <div className="center_title__numBox">{orderNum[2] ?? 0}</div>
                <div className="center_title__numBox">{orderNum[3] ?? 0}</div>
              </div>
            </div>
          </div>

          <div className="CockpitContainer_content__center__echarts">
            <div className="CockpitContainer_content__center__title">
              <div className="CockpitContainer_content__center__img"></div>
            </div>

            <div className="CockpitContainer_content__center__box">
              {(campPieData ?? []).map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      id={'information_item_echarts' + index}
                      style={{
                        height: '14vh',
                      }}
                      className="information_item_echarts"
                    ></div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="CockpitContainer_content__right">
          <div className="CockpitContainer_content__right__title">
            <img
              src={userInfo}
              alt=""
              className="CockpitContainer_content__img"
            />
            <div className="right_content__text">订单信息</div>
          </div>

          <div className="CockpitContainer_content__left__box">
            <BorderBox7 color={['#0DBFDE']} style={{ boxShadow: 'none' }}>
              <div className="CockpitContainer_content__boxTitle">
                <div className="CockpitContainer_content__boxTitle__Box">
                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">订单总数</div>
                        <div className="content_titleBox__value">
                          {orderData?.orderTotal ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>

                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">今日订单</div>
                        <div className="content_titleBox__value">
                          {orderData?.todayOrder ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>

                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">本周订单</div>
                        <div className="content_titleBox__value">
                          {orderData?.weekOrder ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>

                  <div className="CockpitContainer_content__boxTitle__item">
                    <BorderBox8 color={['#0286A2']}>
                      <div className="content_titleBox">
                        <div className="content_titleBox__text">本月订单</div>
                        <div className="content_titleBox__value">
                          {orderData?.monthOrder ?? 0}
                        </div>
                      </div>
                    </BorderBox8>
                  </div>
                </div>
              </div>
              <div className="CockpitContainer_content__right__echarts">
                <div id="order_data_echarts" style={{ height: '15rem' }} />
              </div>
            </BorderBox7>
          </div>
        </div>
      </div>

      <div className="CockpitContainer_bottom">
        <div className="CockpitContainer_bottom__left">
          <div className="CockpitContainer_content__left__title">
            <img
              src={userInfo}
              alt=""
              className="CockpitContainer_content__img"
            />
            <div className="CockpitContainer_content__text">营地信息</div>
          </div>
          <div className="CockpitContainer_bottom__left__box">
            <BorderBox7 color={['#0DBFDE']} style={{ boxShadow: 'none' }}>
              <div className="bottom_left_boxTitle">
                <div className="bottom_left_boxTitle__camp">
                  <div style={{ display: 'flex' }}>
                    <div>
                      <img src={camping} alt="" className="camping" />
                      <img src={bottom} alt="" className="iconBottom" />
                    </div>
                    <div className="bottom_left_boxTitle__word">
                      <div className="bottom_left_boxTitle__value">
                        {campDataInfo?.usedCampNum ?? 0}
                      </div>
                      <div className="bottom_left_boxTitle__text">可用营位</div>
                    </div>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <div className="bottom_left_boxTitle__right">
                      <img src={clock} alt="" className="camping" />
                      <img src={bottom} alt="" className="iconBottom" />
                    </div>
                    <div className="bottom_left_boxTitle__word">
                      <div className="bottom_left_boxTitle__value">
                        {campDataInfo?.reserveCampNum ?? 0}
                      </div>
                      <div className="bottom_left_boxTitle__text">
                        预约营位数
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottom_left_boxTitle__echarts">
                  <img src={campTitle} alt="" className="campTitle" />
                  <div className="campTitle_text">营位预约总数排名</div>

                  <div className="CockpitContainer_bottom__left__echarts">
                    <div id="camp_data_echarts" style={{ height: '15rem' }} />
                  </div>
                </div>
              </div>
            </BorderBox7>
          </div>
        </div>
        <div className="CockpitContainer_bottom__center">
          <BorderBox7 color={['#0DBFDE']} style={{ boxShadow: 'none' }}>
            <div className="lyd_bottom__center__title">
              <div className="systemTitle__box">
                <div className="systemTitle__text">总数：</div>
                <div className="systemTitle__value">
                  {!status ? 0 : helpDataInfo?.aidTotal ?? 0}
                </div>
              </div>
              <div className="systemTitle__box">
                <div className="systemTitle__text">待处理：</div>
                <div className="systemTitle__value">
                  {!status ? 0 : helpDataInfo?.noSolveNum ?? 0}
                </div>
              </div>
              <div className="systemTitle__box">
                <div className="systemTitle__text">今日新增：</div>
                <div className="systemTitle__value">
                  {!status ? 0 : helpDataInfo?.aidTodayNum ?? 0}
                </div>
              </div>
            </div>

            <div className="CockpitContainer_bottom__center__list_Title">
              <div className="bottom_center__tableBox">
                <ScrollBoard
                  config={{
                    header: [' 昵称', '求救时间', '手机号码', '状态'],
                    data: !status
                      ? []
                      : (helpDataInfo?.aidUserInfo ?? [])?.map((item) => [
                          item?.nickname ?? '',
                          item?.helpTime,
                          hidePhoneNumber(item?.phone),
                          item?.solveStatus === 'NO_SOLVE'
                            ? '未处理'
                            : item?.solveStatus === 'SOLVE'
                              ? '已处理'
                              : '',
                        ]),
                    rowNum: 6,
                    oddRowBGC: 'transparent',
                    headerBGC: '#006377',
                    headerHeight: 30,
                    align: ['center', 'center', 'center', 'center'],
                    hoverPause: false,
                  }}
                  style={{ width: '100%', height: '25vh' }}
                />
              </div>
            </div>
          </BorderBox7>
        </div>
        <div className="CockpitContainer_bottom__right">
          <div className="CockpitContainer_content__left__title">
            <img
              src={userInfo}
              alt=""
              className="CockpitContainer_content__img"
            />
            <div className="CockpitContainer_content__text">系统日志</div>
          </div>

          <div
            className="CockpitContainer_content__left__box"
            style={{
              height: '37vh',
            }}
          >
            <BorderBox7 color={['#0DBFDE']} style={{ boxShadow: 'none' }}>
              <div className="CockpitContainer_content__list_bottom">
                <ScrollBoard
                  config={{
                    header: ['事件明细', '操作人员', '日期和时间'],
                    data: (messageList ?? [])?.map((item) => [
                      item?.content ?? '',
                      item?.userAccount ?? '',
                      item?.createdAt ?? '',
                    ]),
                    rowNum: 7,
                    oddRowBGC: 'transparent',
                    evenRowBGC: 'transparent',
                    headerBGC: '#006377',
                    headerHeight: 35,
                    align: ['center', 'center', 'center'],
                    hoverPause: false,
                  }}
                  style={{ width: '100%', height: '32vh' }}
                />
              </div>
            </BorderBox7>
          </div>
        </div>
      </div>
    </div>
  );
};
export default definePage(Cockpit);
