import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableAction,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  Button,
  message,
  Modal,
  Image,
  Breadcrumb,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import { UsedStatusEnum, ReserveStatusEnum } from '@/config';
import CampPositionManageForm from '@pages/CampPositionManageForm';
import CampPositionPriceForm from '@pages/CampPositionMangePriceForm';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { timeFormat, timeMap } from '@/utils';
import { QRCodeCanvas } from 'qrcode.react';

const ManageContainer = styled.div`
  overflow: hidden;
  width: 70px;
  height: 70px;
`;

const TestContainer = styled.div``;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ManageImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  margin-right: 8px;
`;

const CampPositionManageTable = (props) => {
  const selectedRows = useRef([]);
  const [refresh, setRefresh] = useState();
  const [id, setId] = useState();
  const [campNo, setCampNo] = useState();
  const [rows, setRows] = useState([]);
  const [richText, SetRichText] = useState(String);

  const handelSelectRowChange = (cur) => {
    setRows(cur?.filter((item) => item));
  };

  const handleDelete = async () => {
    try {
      if (rows?.length <= 0) {
        return message.error('请先选择要删除的营位');
      }
      Modal.confirm({
        content: '营位删除后不可恢复，是否确认删除？',
        icon: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          vsf.services
            .ReserveCampInfoController_deleteListCamp_457182({
              btoParam: rows?.map((item) => ({
                id: item?.id,
                usedStatus: UsedStatusEnum?.DISABLE,
                partitionId: item?.partitionId,
              })),
            })
            .then((res) => {
              if (res && res.code === 200) {
                message.success('删除成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 500);
              }
            });
        },
      });
    } catch (error) {}
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.ReserveCampInfoController_queryPagedByCampInfoQto_64069a?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    setRows([]);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderCampImg = (_, record) => {
    try {
      const _imgs = JSON.parse(record?.campImg ?? '[]');
      return (
        <ManageContainer>
          <Image.PreviewGroup items={_imgs}>
            {_imgs?.map((item, _idx) => (
              <Image
                width={70}
                height={70}
                style={{ borderRadius: '2px', marginRight: '5px' }}
                key={_idx}
                src={item}
              />
            ))}
          </Image.PreviewGroup>
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  const renderIntroductionImg = (_, record) => {
    try {
      return (
        <Container>
          <div
            className="watchBtn"
            onClick={() => {
              vsf.openModal('preview');
              SetRichText(record?.introduction);
            }}
          >
            预览内容
          </div>
        </Container>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const rederCampPlan = (_, record) => {
    try {
      return (
        <TestContainer>
          {record?.campPlan?.map((item, _idx) => (
            <div>{item?.planName ?? ''}</div>
          ))}
        </TestContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const fromParam =
        await vsf?.refs?.CampPositionManageForm?.validateFieldsReturnFormatValue();
      const res =
        await vsf.services?.ReserveCampInfoController_updateCamp_ce8a0d?.({
          btoParam: {
            ...fromParam,
            campPlanInfoBtoList: fromParam?.campPlan?.map((item) => ({
              planId: item.id,
              id: id,
            })),
            partitionId:
              fromParam?.partitionName?.id ?? fromParam?.partitionId?.id,
            id: id,
          },
          extApiParam: {},
        });

      if (res && res?.code === 200) {
        message.success('编辑成功');
        setTimeout(() => {
          setRefresh(!refresh);
        }, 1000);
      }
      return res?.data;
    } catch (error) {
      return false;
    }
  };

  const handleSubmitPrice = () => {};
  return (
    <Section>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>营地管理</Breadcrumb.Item>
          <Breadcrumb.Item>营位管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '营位删除后不可恢复，是否确认删除？',
            icon: <></>,
          },
          columnProps: { hideInTable: true },
        }}
        vsid="56813"
        extraParams={{ refresh }}
        rowSelection={{
          selectedRows: rows,
          selectType: 'multiple',
          onSelectedRowsChange: handelSelectRowChange,
        }}
        onFetch={onFetch}
        addLinkPage={{
          linkPage: () => <CampPositionManageForm />,
          modalProps: { width: 650, title: '新增营位' },
          onLinkPageValidate: async (params) => {
            try {
              const fromParam =
                await vsf?.refs?.CampPositionManageForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf.services?.ReserveCampInfoController_createCamp_9d1756?.(
                  {
                    btoParam: {
                      ...fromParam,
                      campPlanInfoBtoList: fromParam?.campPlan?.map((item) => ({
                        planId: item,
                      })),
                      partitionId: fromParam?.partitionName?.id,
                    },
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('添加成功');
                setTimeout(() => {
                  setRefresh(!refresh);
                }, 1000);
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
        }}
      >
        <VSTableColumn
          dataIndex={['campNo']}
          title="营位编号"
          valueType="text"
          search
          order={-13}
          columnKey={'campNoIs'}
          formItemProps={{
            rules: [
              {
                message: '营位编号长度不合法',
                type: 'string',
                min: 0,
                max: 520,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['campImg']}
          title="营位封面"
          valueType="text"
          render={renderCampImg}
        />
        <VSTableColumn
          dataIndex={['partitionName']}
          title="所属分区"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['campPlan']}
          title="可选套餐"
          valueType="text"
          fieldProps={{}}
          render={rederCampPlan}
        />
        <VSTableColumn
          dataIndex={['campPrice']}
          title="价格"
          valueType="digit"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['introduction']}
          title="营位介绍"
          render={renderIntroductionImg}
        />
        <VSTableColumn
          dataIndex={['reserveNotice']}
          title="预定须知"
          valueType="text"
          ellipsis
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['withdrawalRules']}
          title="退改规则"
          valueType="text"
          ellipsis
          fieldProps={{}}
        />
        {/* <VSTableColumn
          dataIndex={['campStatus']}
          title="预约状态"
          valueType="select"
          dataSource={[
            { label: '已预约', value: 'BOOKED' },
            { label: '未预约', value: 'NO_BOOKED' },
            { label: '已取消', value: 'CANCELED' },
          ]}
          fieldProps={{}}
        /> */}
        <VSTableColumn
          dataIndex={['usedStatus']}
          title="状态"
          valueType="select"
          fieldProps={{}}
          dataSource={[
            { label: '在用', value: 'ENABLE' },
            { label: '停用', value: 'DISABLE' },
          ]}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          width={230}
          dataIndex={['action']}
          title="操作"
          valueType="option"
        >
          <VSTableAction
            children="生成二维码"
            // show={(data) => data.usedStatus === 'ENABLE'}
            onClick={(e, data) => {
              Modal.confirm({
                title: '营位二维码',
                content: (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <QRCodeCanvas id="qrcode" value={`id=${data.id}`} />
                  </div>
                ),
                okText: '下载图片',
                cancelText: '取消',
                icon: <></>,
                closable: true,
                onOk: () => {
                  const canvas = document.getElementById('qrcode');
                  const url = canvas.toDataURL('image/png');
                  const link = document.createElement('a');
                  link.download = '营位二维码.png';
                  link.href = url;
                  link.click();
                },
              });
            }}
          />
          <VSTableAction
            onClick={(v, vv, params) => {
              vsf.openModal('setPriceModal');
              setId(vv?.id);
              setCampNo(vv?.campNo);
            }}
          >
            设置价格
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              setId(vv?.id);
              // vsf.openModal('addModal');
              // setTimeout(() => {
              //   vsf.refs.CampPositionManageForm?.setFieldsValue(vv);
              // }, 1);
            }}
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: {
                width: 650,
                title: '编辑营位',
              },
              linkPage: () => <CampPositionManageForm />,
              onLinkPageValidate: async (_, __, record) => {
                try {
                  const fromParam =
                    await vsf?.refs?.CampPositionManageForm?.validateFieldsReturnFormatValue();
                  const res =
                    await vsf.services?.ReserveCampInfoController_updateCamp_ce8a0d?.(
                      {
                        btoParam: {
                          ...fromParam,
                          campPlanInfoBtoList: fromParam?.campPlan?.map(
                            (item) => ({
                              planId: item,
                              id: record.id,
                            }),
                          ),
                          partitionId:
                            fromParam?.partitionName?.id ??
                            fromParam?.partitionId?.id,
                          id: record.id,
                        },
                        extApiParam: {},
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                    setTimeout(() => {
                      setRefresh(!refresh);
                    }, 1000);
                  }
                  return res?.data;
                } catch (error) {
                  return false;
                }
              },
            }}
          >
            编辑
          </VSTableAction>
          <VSTableAction
            onClick={(v, vv, params) => {
              Modal.confirm({
                content: '营位删除后不可恢复，是否确认删除？',
                icon: '',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  vsf.services
                    .ReserveCampInfoController_deleteCamp_bf3276({
                      btoParam: {
                        id: vv?.id,
                        partitionId: vv?.partitionId,
                        usedStatus: UsedStatusEnum?.DISABLE,
                      },
                    })
                    .then((res) => {
                      if (res?.code === 200) {
                        message.success(`删除营位成功`);
                        setTimeout(() => {
                          setRefresh(!refresh);
                        }, 500);
                      }
                    });
                },
              });
            }}
          >
            删除
          </VSTableAction>
        </VSTableColumn>

        <VSTableToolbar title="">
          <Button
            children="批量删除"
            style={{
              backgroundColor: '#37927b',
              color: 'white',
              height: '38px',
              borderRadius: '6px',
              fontWeight: 'bold',
            }}
            disabled={rows?.length <= 0}
            onClick={() => {
              handleDelete();
            }}
          />
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>

      <Modal
        title="价格列表"
        id="setPriceModal"
        width={700}
        onOk={handleSubmitPrice}
        okText="确定"
        cancelText="取消"
      >
        <div className="setPriceTable">
          <div style={{ fontSize: 14 }}>
            营位编号 （仅支持设置即日起90天内的价格）
          </div>
          <VSPromiseTable
            editable={{
              editType: 'single',
              deleteConfirmProps: {
                title: '是否确认删除？',
                icon: <></>,
              },
            }}
            pagination={false}
            extraParams={{ refresh }}
            onFetch={async (params) => {
              const res =
                await vsf.services?.ReserveDayController_getByCampId_42c039?.({
                  campId: id,
                });
              return {
                data: res?.data ?? res?.data ?? [],
              };
            }}
            onRemove={async (params) => {
              const res =
                await vsf.services?.ReserveDayController_deletePriceList_c035d4?.(
                  {
                    deleteReserveDayBto: [
                      {
                        id: params?.id,
                      },
                    ],
                    extApiParam: params?.extApiParam,
                  },
                );
              if (res && res?.code === 200) {
                message.success('删除成功');
              }
              return res?.code === 200;
            }}
            addLinkPage={{
              linkPage: () => <CampPositionPriceForm campNo={campNo} />,
              modalProps: { width: 500, title: '设置价格' },
              onLinkPageValidate: async (params) => {
                try {
                  const fromParam =
                    await vsf?.refs?.CampPositionPriceForm?.validateFieldsReturnFormatValue();
                  const dateList = timeMap(
                    fromParam?.time?.begin,
                    fromParam?.time?.end,
                  );
                  const formList = [];
                  dateList?.map((item) => {
                    formList.push({
                      time: timeFormat(item),
                      timeFrame: fromParam?.timeFrame,
                      price: fromParam?.price,
                      campId: id,
                    });
                  });
                  const res =
                    await vsf.services?.ReserveDayController_createReservePriceList_e20319?.(
                      {
                        btoParamList: formList,
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('设置成功');
                  }
                  return res?.data;
                } catch (e) {
                  return false;
                }
              },
            }}
            updateLinkPage={{
              linkPage: () => <CampPositionPriceForm campNo={campNo} />,
              modalProps: { width: 500, title: '设置价格' },
              onLinkPageValidate: async (params) => {
                try {
                  const fromParam =
                    await vsf?.refs?.CampPositionPriceForm?.validateFieldsReturnFormatValue();
                  const res =
                    await vsf.services?.ReserveDayController_updateReservePrice_4a3794?.(
                      {
                        btoParam: {
                          ...fromParam,
                          campId: params?.campId,
                          id: params?.id,
                        },
                        extApiParam: params?.extApiParam,
                      },
                    );

                  if (res && res?.code === 200) {
                    message.success('编辑成功');
                  }
                  return res?.data;
                } catch (error) {
                  return false;
                }
              },
            }}
          >
            <VSTableColumn
              dataIndex={['time']}
              title="日期"
              valueType="date"
              order={-13}
              fieldProps={{
                format: 'YYYY-MM-DD',
                valueType: 'string',
              }}
            />
            <VSTableColumn
              dataIndex={['timeFrame']}
              title="时段"
              valueType="select"
              dataSource={[
                { label: '过夜', value: 'OVERNIGHT' },
                { label: '不过夜', value: 'NO_OVERNIGHT' },
              ]}
            />
            <VSTableColumn
              dataIndex={['price']}
              title="价格"
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableToolbar title="">
              <VSTableAddition children="新增" position="top" />
            </VSTableToolbar>
          </VSPromiseTable>
        </div>
      </Modal>

      <Modal
        title="设置价格"
        id="setPriceFormModal"
        width={500}
        onOk={handleSubmitPrice}
        okText="确定"
        cancelText="取消"
      >
        <CampPositionPriceForm campNo={campNo} />
      </Modal>

      <Modal id="preview" width={600} okText="" cancelText="" footer={null}>
        {richText && (
          <>
            <div className="modalWatch_container__text">
              <div
                dangerouslySetInnerHTML={{ __html: richText }}
                className="bannerText"
              ></div>
            </div>
          </>
        )}
      </Modal>
    </Section>
  );
};
export default definePage(CampPositionManageTable);
