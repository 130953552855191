import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  message,
  Image,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import defaultPhoto from '@/assets/images/Cockpit/defaultPhoto.png';

const ManageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const UserListTable = (props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const rederIndex = (_, __, index) => {
    return (
      <ManageContainer>
        <div style={{ marginLeft: '10px' }}>
          {pageIndex * pageSize + (index + 1)}
        </div>
      </ManageContainer>
    );
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.UserAccountController_getPageByUserProfileUserQto_ea5f70?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            userTypeIn: ['LEARNING_ADMIN'],
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const renderManageImg = (_, record) => {
    try {
      return (
        <ManageContainer>
          <Image
            width={70}
            height={70}
            style={{ borderRadius: '2px', marginRight: '5px' }}
            src={record.avatar ? record.avatar : defaultPhoto}
          />
        </ManageContainer>
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Section title="用户管理">
      <div className="userManage">
        <VSPromiseTable
          editable={{
            editType: 'single',
            deleteConfirmProps: {
              title: '用户删除后不可恢复，是否确认删除？',
              icon: <></>,
            },
          }}
          vsid="37189"
          onFetch={onFetch}
          pagination={{
            onChange(currentIndex, _pageSize) {
              setPageIndex(currentIndex - 1);
              setPageSize(_pageSize);
            },
          }}
          onUpdate={async (params) => {
            const res =
              await vsf.services?.UserAccountController_updatePassword_9a5392?.(
                {
                  btoParam: params,
                  extApiParam: params?.extApiParam,
                },
              );
            if (res && res?.code === 200) {
              message.success('编辑成功');
            }
            return res?.data;
          }}
          onRemove={async (params) => {
            const res =
              await vsf.services?.UserAccountController_deletePassword_e23731?.(
                {
                  btoParam: params,
                  extApiParam: params?.extApiParam,
                },
              );
            if (res && res?.code === 200) {
              message.success('删除成功');
            }
            return res?.code === 200;
          }}
        >
          <VSTableColumn
            dataIndex={['index']}
            title="序号"
            valueType="digit"
            fieldProps={{}}
            editable={false}
            render={rederIndex}
          />

          <VSTableColumn
            dataIndex={['avatar']}
            title="用户头像"
            render={renderManageImg}
            editable={false}
          />

          <VSTableColumn
            dataIndex={['nickname']}
            title="用户名称"
            valueType="text"
            fieldProps={{
              placeholder: '请输入',
            }}
          />

          <VSTableColumn
            dataIndex={['phone']}
            search
            title="手机号"
            valueType="text"
            columnKey={['phoneIs']}
            order={-20}
            fieldProps={{
              maxLength: 11,
              placeholder: '请输入',
            }}
          />

          <VSTableColumn
            dataIndex={['gender']}
            title="性别"
            search
            order={-21}
            valueType="select"
            columnKey={['genderIs']}
            dataSource={[
              { label: '男', value: 'MAN' },
              { label: '女', value: 'WOMAN' },
            ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          />

          <VSTableColumn
            dataIndex={['birthdayDate']}
            title="生日"
            valueType="date"
            fieldProps={{
              format: 'YYYY-MM-DD',
              valueType: 'string',
              placeholder: '请选择',
            }}
          />

          <VSTableColumn
            dataIndex={['status']}
            title="状态"
            search
            order={-22}
            valueType="select"
            columnKey={['statusIs']}
            dataSource={[
              { label: '正常', value: 'NORMAL' },
              { label: '注销', value: 'BANNED' },
            ]}
            fieldProps={{
              placeholder: '请选择',
            }}
          />

          <VSTableColumn
            dataIndex={['createdAt']}
            title="注册日期"
            valueType="date"
            editable={false}
            fieldProps={{
              format: 'YYYY-MM-DD',
            }}
          />
        </VSPromiseTable>
      </div>
    </Section>
  );
};
export default definePage(UserListTable);
