import './MessageManageDetail.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Button } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

const MessageManageDetail = (props) => {
  const [details, setDetails] = useState({});
  useEffect(() => {
    const res = vsf?.services
      ?.ReserveMessageController_getById_febd5b?.({
        id: props?.routes?.params?.id,
      })
      .then((resposne) => {
        if (resposne && resposne?.code === 200) {
          setDetails(resposne?.data);
        }
      });
  }, []);
  return (
    <Section title="消息详情">
      <div className="messageContainer">
        <div className="messageContainer_title">
          {details?.title},请及时查看
        </div>

        {details?.model === 'RESERVE' ? (
          <>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 预约姓名：</span>
              {details?.reserveName ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 预约手机号：</span>
              {details?.reservePhone ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 预约时间：</span>
              {details?.reserveTime ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 预约营地：</span>
              {details?.reserveCampName ?? '-'}
            </div>
          </>
        ) : details?.model === 'REFUND' ? (
          <>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 用户姓名：</span>
              {details?.reserveName ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 用户手机号：</span>
              {details?.reservePhone ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 订单编号：</span>
              {details?.orderNo ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 订单内容：</span>
              {details?.orderName ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 退款原因：</span>
              {details?.orderRefund === 'CHANGE'
                ? '计划有变'
                : details?.orderRefund === 'MAKE_MISTAKE'
                ? '错拍多拍'
                : details?.orderRefund === 'NOT_NEED'
                ? '不要了'
                : details?.orderRefund === 'OTHER'
                ? '其他'
                : '-' ?? '-'}
            </div>
          </>
        ) : details?.model === 'AID' ? (
          <>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 用户姓名：</span>
              {details?.reserveName ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 用户手机号：</span>
              {details?.reservePhone ?? '-'}
            </div>
            <div className="messageContainer_item">
              <span className="messageContainer_text"> 定位：</span>
              {details?.orderName ?? '-'}
            </div>
          </>
        ) : (
          ''
        )}

        <Button
          children="返回"
          style={{
            backgroundColor: '#37927b',
            color: 'white',
            height: '38px',
            borderRadius: '6px',
            fontWeight: 'bold',
          }}
          onClick={() => {
            vsf.navigateTo(`/messageManage`);
          }}
        />
      </div>
    </Section>
  );
};

export default definePage(MessageManageDetail);
