import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderResearchLearningController_queryPagedByLearningOrderQto_482a08: {
    method: 'post',
    url: '/api/order-research-learning/query-paged-by-learning-order-qto',
    parameterFomatter: (data?: {
      qto: LearningOrderQtoOrderResearchLearningPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: LearningOrderVoOrderResearchLearningEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrderResearchLearningController_deleteLearningOrder_810d5d: {
    method: 'post',
    url: '/api/order-research-learning/delete-learning-order',
    parameterFomatter: (data?: {
      btoParam: DeleteLearningOrderBtoOrderResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_auditRefundOrder_9168c8: {
    method: 'post',
    url: '/api/order-research-learning/audit-refund-order',
    parameterFomatter: (data?: {
      auditRefundVo: AuditRefundVoOrderResearchLearningEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  OrderResearchLearningController_updateLearningOrder_45fd33: {
    method: 'post',
    url: '/api/order-research-learning/update-learning-order',
    parameterFomatter: (data?: {
      btoParam: UpdateLearningOrderBtoOrderResearchLearningServiceBto;
      extApiParam: any;
      type: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderResearchLearningController_queryLearningOrderStatistics_15ef87: {
    method: 'post',
    url: '/api/order-research-learning/query-learning-order-statistics',
    parameterFomatter: (data?: {
      qto: LearningOrderInfoQtoOrderResearchLearningPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LearningOrderStatisticsVoOrderResearchLearningEntranceWebVo,
    ) => data,
  },
  OrderInfoController_queryCampStatistics_a249ba: {
    method: 'post',
    url: '/api/order-info/query-camp-statistics',
    parameterFomatter: (data?: { qto: CampOrderQtoComVsOrderInfoPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoStatisticsVoComVsOrderInfoEntranceWebVo,
    ) => data,
  },
  OrderInfoController_updateOrderInfo_2311bc: {
    method: 'post',
    url: '/api/order-info/update-order-info',
    parameterFomatter: (data?: {
      btoParam: UpdateOrderInfoBtoComVsOrderInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoAllVoComVsOrderInfoEntranceWebVo,
    ) => data,
  },
  OrderInfoController_updateCampOrderInfo_017e9c: {
    method: 'post',
    url: '/api/order-info/update-camp-order-info',
    parameterFomatter: (data?: { orderNo: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserReserveController_queryReserveOrder_7c6c95: {
    method: 'post',
    url: '/api/reserve-camp-info/query-reserve-order',
    parameterFomatter: (data?: {
      qto: UserReserveOrderQtoReserveCampInfoPersistQto;
      ext: {
        startTime: string;
        endTime: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReserveOrderStatisticsVoReserveCampInfoEntranceWebVo,
    ) => data,
  },
  OrderMerchantController_queryTransactionRecordStatistics_09f967: {
    method: 'post',
    url: '/api/order-merchant/query-transaction-record-statistics',
    parameterFomatter: (data?: {
      qto: TransactionRecordStatisticsQtoOrderMerchantPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AmountManageVoOrderMerchantEntranceWebVo,
    ) => data,
  },
});
