import { createServices } from '@vs/vsf-boot';
export default createServices({
  FrontResearchLearningController_queryAllByFrontResearchLearningQto_2f1b94: {
    method: 'post',
    url: '/api/front-research-learning/query-all-by-front-research-learning-qto',
    parameterFomatter: (data?: {
      qto: FrontResearchLearningQtoSimpleFrontResearchLearningPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: FrontResearchVoFrontResearchLearningEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FrontResearchLearningController_createFrontLearning_320c57: {
    method: 'post',
    url: '/api/front-research-learning/create-front-learning',
    parameterFomatter: (data?: {
      btoParam: CreateFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
  FrontResearchLearningController_updateFrontLearning_bf7b9f: {
    method: 'post',
    url: '/api/front-research-learning/update-front-learning',
    parameterFomatter: (data?: {
      btoParam: UpdateFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
  FrontResearchLearningController_deleteFrontLearning_e447a0: {
    method: 'post',
    url: '/api/front-research-learning/delete-front-learning',
    parameterFomatter: (data?: {
      btoParam: DeleteFrontLearningBtoFrontResearchLearningServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FrontResearchVoFrontResearchLearningEntranceWebVo,
    ) => data,
  },
});
