import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSFormItem, VSForm, VSFormLayout, Todo } from '@vs/vsf-kit';
import React from 'react';
const GroupBuyManagerForm = (props) => {
  console.log(props?.value, 'props');
  return (
    <Section>
      <VSForm
        vsid="57817"
        id="groupBuyManageForm"
        // onSubmit={async (params) => {
        //   // Tips: 函数入参为表单数据，格式和RO一致
        //   // Tips: 以下是你选择的请求函数
        //   console.log(params);
        //   const res =
        //     await vsf.services?.ReserveGroupBuyController_createGroupBuyManage_039406?.(
        //       {
        //         // TODO: 请检查以下这部分传参是否符合需求
        //         btoParam: params,
        //         extApiParam: params?.extApiParam,
        //       },
        //     ); // Tips: 需要返回提交的完整数据，和表单RO一致
        //   return params;
        // }}
        onFetch={async (params) => {
          // Tips: 函数入参包含通过表单params属性传入的额外参数(平铺)
          // Tips: 以下是你选择的请求函数
          if (props?.value) {
            const res =
              await vsf.services?.ReserveGroupBuyController_getGroupBuyManageVoById_805fdc?.(
                {
                  // TODO: 请检查以下这部分传参是否符合需求
                  id: props?.value?.id,
                },
              ); // Tips: 需要返回内容数据，结构和表单RO一致
            return res?.data;
          }
        }}
      >
        <VSFormLayout key="0" columnCount={1} labelWidth={90}>
          <VSFormItem
            name={['activityName']}
            label="活动名称"
            valueType="text"
            rules={[
              { required: true, message: '请输入活动名称' },
              {
                message: '活动名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['personName']}
            label="联系人姓名"
            valueType="text"
            rules={[
              { required: true, message: '请输入联系人名称' },
              {
                message: '联系人名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['phone']}
            label="手机号"
            valueType="text"
            rules={[
              { required: true, message: '请输入手机号' },
              {
                message: '手机号长度不合法',
                type: 'string',
                min: 11,
                max: 11,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['personNum']}
            label="人数"
            valueType="digit"
            rules={[
              { required: true, message: '请输入人数' },
              {
                message: '人数的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['reserveDate']}
            label="预约时间"
            valueType="date"
            fieldProps={{}}
            rules={[{ required: true, message: '请选择预约时间' }]}
          />

          <VSFormItem
            name={['activityFrame']}
            label="预约时段"
            valueType="select"
            dataSource={[
              { label: '上午', value: 'MORNING' },
              { label: '下午', value: 'AFTERNOON' },
            ]}
            rules={[{ required: true, message: '请选择活动时段' }]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSForm>
      {/* <Todo type="block" /> */}
    </Section>
  );
};
export default definePage(GroupBuyManagerForm);
