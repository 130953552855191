import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useRef } from 'react';
import getOssPolicy from '@/utils/getOssPolicy';
import BraftEditor from 'braft-editor';
const BannerMangementForm = (props) => {
  return (
    <Section>
      <VSControlledForm
        vsid="90486"
        id="BannerMangementForm"
        layout="inline"
        value={props.value}
        style={{ marginTop: 20 }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout key="1" columnCount={1} labelWidth={80}>
          <VSFormItem
            name={['title']}
            label="标题"
            valueType="text"
            required
            rules={[{ required: true, message: '请输入标题（20字以内）' }]}
            fieldProps={{
              maxLength: 20,
            }}
          />

          <VSFormItem
            name={['img']}
            label="图片"
            required
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传图片' }]}
            convertValue={(v) => {
              try {
                if (!v) {
                  return v;
                }
                if (Array.isArray(v)) {
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                }

                const _v = JSON.parse(v);
                return _v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              } catch (error) {
                return [];
              }
            }}
            transform={(v) => {
              if (typeof v === 'string' && v?.includes('[')) {
                return {
                  img: v,
                };
              }
              return {
                img: JSON.stringify(
                  v?.map((vv) => {
                    return vv.url;
                  }),
                ),
              };
            }}
            fieldProps={{
              type: 'picture',
              getOssPolicy: getOssPolicy,
              limit: 700 * 900,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片尺寸过大', 'error');
              },
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />

          <div className="suggessSize">建议尺寸：700 * 300</div>

          {/* <VSFormItem
            name={['link']}
            label="跳至页面"
            valueType="text"
            fieldProps={{
              placeholder: '请上传链接',
            }}
          /> */}
          <VSFormItem
            name="richText"
            label="内容"
            valueType="editor"
            required
            rules={[{ required: true, message: '请填写内容' }]}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(BannerMangementForm);
