import { createServices } from '@vs/vsf-boot';
export default createServices({
  MallCommodityController_createCommodityInfo_150bf5: {
    method: 'post',
    url: '/api/mall-commodity/create-commodity-info',
    parameterFomatter: (data?: {
      btoParam: CreateCommodityInfoBtoMallCommodityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MallCommodityController_updateCommodityInfo_47f011: {
    method: 'post',
    url: '/api/mall-commodity/update-commodity-info',
    parameterFomatter: (data?: {
      btoParam: UpdateCommodityInfoBtoMallCommodityServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
